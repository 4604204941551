export const SET_USER = 'SET_USER';

export type AuthState = Readonly<{
  user: DTO.Nullable<DTO.User>;
}>;

export type SetUserAction = Store.PayloadedAction<DTO.User>;

export type RemoveUserAction = Store.PayloadedAction<null>;

export type AuthActionTypes = SetUserAction;
