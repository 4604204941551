import React from 'react';
import { HUMAN_MANAGEMENT_PATH } from 'features/Routes/helpers';
import flowhrLogo from 'shared/images/flowhr_logo.svg';
import { FIRST_ELEMENT } from 'shared/constants/common.const';
import { CameraOutlined, UserOutlined } from '@ant-design/icons';
import { LayoutConfig, MenuItem } from 'features/Layouts/helpers';
import { Permissions } from 'features/Routes/GuardedRoute/helpers';

export const HUMAN_MANAGEMENT_LAYOUT: LayoutConfig = {
  title: 'timetracker.title',
  logo: flowhrLogo,
  menuItems: [
    {
      key: 1,
      name: 'timetracker.menu.people',
      icon: <UserOutlined />,
      path: HUMAN_MANAGEMENT_PATH.PEOPLE,
      nestedPaths: [
        {
          name: 'timetracker.people.createPerson',
          path: HUMAN_MANAGEMENT_PATH.PEOPLE_CREATE,
        },
        {
          name: 'timetracker.people.editPerson',
          path: HUMAN_MANAGEMENT_PATH.PEOPLE_EDIT,
        },
        {
          name: 'timetracker.people.viewPerson',
          path: HUMAN_MANAGEMENT_PATH.PEOPLE_VIEW,
        },
        {
          name: 'timetracker.people.billing',
          path: HUMAN_MANAGEMENT_PATH.BILLING,
        },
      ],
    },
    {
      key: 2,
      name: 'timetracker.menu.photos',
      icon: <CameraOutlined />,
      path: HUMAN_MANAGEMENT_PATH.PHOTO,
    },
  ],
};

export const getInitialRoute = (permissions: Permissions[]): string => {
  const conf: Permissions[] = permissions.filter(
    (permission) => permission.sectionPath === HUMAN_MANAGEMENT_PATH.ROOT
  );
  const confgOrder: string = conf.reduce((lowest, current) => {
    return current.order < lowest.order ? current : lowest;
  }, conf[FIRST_ELEMENT]).optionPath;

  return confgOrder;
};

export const getAllLayout = (permissions: Permissions[]): LayoutConfig => {
  const layout: LayoutConfig = {
    title: 'timetracker.title',
    logo: flowhrLogo,
    menuItems: [],
  };
  const menuFiltered = HUMAN_MANAGEMENT_LAYOUT.menuItems.filter(
    (menu: MenuItem) => {
      return permissions.some((permission: Permissions) => {
        return permission.optionPath === menu.path;
      });
    }
  );

  layout.menuItems = menuFiltered;
  return layout;
};
