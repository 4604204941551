import React, { useState } from 'react';
import {
  CustomerServiceOutlined,
  FileDoneOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Drawer, Tooltip } from 'antd';
import {
  StyledHelperWrapper,
  StyledSupportWrapper,
  StyledSearch,
  StyledTitle,
  StyledTitleTag,
  StyledTitleWrapper,
} from './styles';
import ManualList from './ManualList';
import FilteredManualList from './filteredManualList';

const SystemManual = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const [searchInput, setSearchInput] = useState('');
  const [selectSearch, setSelectSearch] = useState(false);
  const { t } = useTranslation();

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setSearchInput('');
    setSelectSearch(false);
    setOpenDrawer(false);
  };

  const openHelpDesk = () => {
    window.open(
      'https://apps.powerapps.com/play/e/default-19eee545-4131-45c6-9a60-1a17e5cc507d/a/62039f10-0924-407a-896f-7a988ed007ad?tenantId=19eee545-4131-45c6-9a60-1a17e5cc507d',
      '_blank'
    );
  };

  const onSearch = () => setSelectSearch(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectSearch(false);
    setSearchInput(e.target.value);
  };

  return (
    <>
      <Tooltip title={t('common.help')} placement="left">
        <StyledHelperWrapper onClick={showDrawer}>
          <QuestionOutlined />
        </StyledHelperWrapper>
      </Tooltip>
      <Tooltip title={t('common.helpDesk')} placement="left">
        <StyledSupportWrapper onClick={openHelpDesk}>
          <CustomerServiceOutlined />
        </StyledSupportWrapper>
      </Tooltip>
      <Drawer
        placement="right"
        onClose={onClose}
        open={openDrawer}
        destroyOnClose
        closable
        headerStyle={{ justifyContent: 'flex-end' }}
      >
        <>
          <StyledTitleWrapper>
            <FileDoneOutlined />
            <StyledTitle>{t('timetracker.manualList.title')}</StyledTitle>
          </StyledTitleWrapper>
          <StyledTitleTag>
            {t('timetracker.manualList.subtitle')}
          </StyledTitleTag>
          <StyledSearch
            type="text"
            onSearch={onSearch}
            value={searchInput}
            onChange={handleChange}
            allowClear
          />
          {searchInput.length > 2 || selectSearch ? (
            <FilteredManualList searchInput={searchInput} />
          ) : (
            <ManualList />
          )}
        </>
      </Drawer>
    </>
  );
};

export default SystemManual;
