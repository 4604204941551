export enum CountryCode {
  // #region America:

  Argentina = 54,
  Aruba = 297,
  Bahamas = 1242,
  Belarus = 375,
  Belize = 501,
  Bolivia = 591,
  Brazil = 55,
  Canada = 1,
  Chile = 56,
  Colombia = 57,
  CostaRica = 506,
  Cuba = 53,
  Curacao = 599,
  Ecuador = 593,
  ElSalvador = 503,
  FrenchGuiana = 594,
  Guadeloupe = 590,
  Guatemala = 502,
  Guyana = 592,
  Haiti = 509,
  Honduras = 504,
  Martinique = 596,
  Mexico = 52,
  Nicaragua = 505,
  Panama = 507,
  Paraguay = 595,
  Peru = 51,
  Suriname = 597,
  UnitedStates = 1,
  Uruguay = 598,
  Venezuela = 58,

  // #endregion

  // #region Europe:

  Albania = 355,
  Andorra = 376,
  Austria = 43,
  Azerbaijan = 994,
  Belgium = 32,
  BosniaAndHerzegovina = 387,
  Botzwana = 267,
  Bulgaria = 359,
  CzechRepublic = 420,
  Denmark = 45,
  Estonia = 372,
  Finland = 358,
  France = 33,
  Georgia = 995,
  Greece = 30,
  Germany = 49,
  Hungary = 36,
  Iceland = 354,
  Ireland = 353,
  Italy = 39,
  Kosovo = 383,
  Latvia = 371,
  Liechtenstein = 423,
  Lithuania = 370,
  Luxembourg = 352,
  Macedonia = 389,
  Moldova = 373,
  Monaco = 377,
  Montenegro = 382,
  Malta = 356,
  Netherlands = 31,
  Norway = 47,
  Poland = 48,
  Portugal = 351,
  Romania = 40,
  Russia = 7,
  UnitedKingdom = 44,
  SanMarino = 378,
  Serbia = 381,
  Slovakia = 421,
  Slovenia = 386,
  Spain = 34,
  Sweden = 46,
  Switzerland = 41,
  Ukraine = 380,

  // #endregion

  // #region Asia:

  Afghanistan = 93,
  Armenia = 374,
  Bangladesh = 880,
  Bhutan = 975,
  BritishIndianOceanTerritory = 246,
  Brunei = 673,
  Cambodia = 855,
  China = 86,
  HongKong = 852,
  India = 91,
  Indonesia = 62,
  Japan = 81,
  Kyrgyzstan = 996,
  Laos = 856,
  Macau = 853,
  Malaysia = 60,
  Maldives = 960,
  Mongolia = 976,
  Myanmar = 95,
  Nepal = 977,
  NorthKorea = 850,
  Pakistan = 92,
  Philippines = 63,
  SaudiArabia = 966,
  Singapore = 65,
  SouthCorea = 82,
  SriLanka = 94,
  Taiwan = 886,
  Tajikistan = 992,
  Thailand = 66,
  TimorLeste = 670,
  Turkey = 90,
  Turkmenistan = 993,
  Uzbekistan = 998,
  Vietnam = 84,

  // #endregion

  // #region Africa:

  Algeria = 213,
  Angola = 244,
  Benin = 229,
  BurkinaFaso = 226,
  Burundi = 257,
  Cameroon = 237,
  CapeVerde = 238,
  Djibouti = 253,
  Eritrea = 291,
  Ethiopia = 251,
  Egypt = 20,
  Gabon = 241,
  Gambia = 220,
  Ghana = 233,
  Guinea = 224,
  GuineaBissau = 245,
  GuineaEquatorial = 240,
  IvoryCoast = 225,
  Kenya = 254,
  Lesotho = 266,
  Liberia = 231,
  Libya = 218,
  Madagascar = 261,
  Malawi = 265,
  Mali = 223,
  Mauritania = 222,
  Mauritius = 230,
  Morocco = 212,
  Mozambique = 258,
  Namibia = 264,
  Niger = 227,
  Nigeria = 234,
  Reunion = 262,
  Rwanda = 250,
  SaoTomeAndPrincipe = 239,
  Senegal = 221,
  Seychelles = 248,
  SierraLeone = 232,
  SouthAfrica = 27,
  Somalia = 252,
  SouthSudan = 211,
  Sudan = 249,
  Swaziland = 268,
  Tanzania = 255,
  Togo = 228,
  Tunisia = 216,
  Uganda = 256,
  Zambia = 260,
  Zimbabwe = 263,

  // #endregion

  // #region Oceania:

  Australia = 61,
  Fiji = 679,
  FrenchPolynesia = 689,
  Kiribati = 686,
  MarshallIslands = 692,
  Micronesia = 691,
  Nauru = 674,
  NewZealand = 64,
  NewCaledonia = 687,
  PapuaNewGuinea = 675,
  Palau = 680,
  Samoa = 685,
  SolomonIslands = 677,
  Tonga = 676,
  Tuvalu = 688,
  Vanuatu = 678,

  // #endregion

  // #region Middle East:

  Bahrain = 973,
  Cyprus = 357,
  Iran = 98,
  Iraq = 964,
  Israel = 972,
  Jordan = 962,
  Kuwait = 965,
  Lebanon = 961,
  Oman = 968,
  Palestine = 970,
  Qatar = 974,
  Syria = 963,
  UnitedArabEmirates = 971,
  Yemen = 967,

  // #endregion
}
