import { LinkOutlined } from '@ant-design/icons';
import { Rate } from 'antd';
import Autocomplete from 'components/Autocomplete';
import { Button } from 'components/Button';
import { useForm } from 'components/Form';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RatingName } from '../../EditPopover/EditContent/styles';
import { getPlaceholder, MAX_NAME_LENGTH, getCompanyId } from './helpers';
import {
  DefaultButton,
  Footer,
  FormItemStyled,
  FormStyled,
  PopoverContainer,
  RatingComponent,
  SkillName,
} from './styles';

interface Props {
  setAddData: Function;
  skills: DTO.PositionSkills[];
  onSaveClick: Function;
  skillType: number;
  options: Array<{ value: string }>;
  skillLevels: DTO.PositionSkillLevel[];
  skillCategory: DTO.PositionSkillCategory;
}

const AddContent: FC<Props> = ({
  setAddData,
  skills,
  onSaveClick,
  skillType,
  options,
  skillLevels,
  skillCategory,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [addSkillForm] = useForm();
  const [rating, setRating] = useState<number>(0);
  const [companyId, setCompanyId] = useState<number>(0);
  const [skillName, setSkillName] = useState<string>('');
  const [uniqSkill, setUniqSkill] = useState<boolean>(true);
  const [isSkillRepeated, setSkillRepeated] = useState<boolean>(false);

  useEffect(() => {
    getCompanyId().then((compId: number) => {
      setCompanyId(compId);
    });
  }, []);

  const createAddObject = useCallback(() => {
    const randomId = Math.floor(Math.random() * 100000);

    const dataToSave: DTO.PositionSkills = {
      id: randomId,
      companyId,
      isActive: true,
      isDeleted: false,
      name: skillName,
      skillTypeId: skillType,
      skillLevelId: skillLevels[rating - 1].id,
    } as DTO.PositionSkills;
    setAddData(dataToSave);
  }, [companyId, skillName, skillType, skillLevels, rating, setAddData]);

  const onSaveHandler = () => {
    onSaveClick(false);
    createAddObject();
    setRating(0);
    addSkillForm.setFieldsValue({
      skillName: '',
      rating: 0,
    });
    setSkillName('');
  };

  const onSkillNameChange = (data: string) => {
    const text = data ? data.trimStart() : '';
    setSkillName(text);
    const getSkill = skills.find((sk) => sk.name === data);
    setUniqSkill(getSkill === undefined);
  };

  const onFilterName = (inputValue: string, option: { value: string }) => {
    return (
      inputValue.length >= 1 &&
      option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    );
  };

  const disable =
    !rating ||
    !skillName ||
    skillName.length > MAX_NAME_LENGTH ||
    !uniqSkill ||
    isSkillRepeated;

  return (
    <PopoverContainer>
      <FormStyled form={addSkillForm} name="addSkillForm" layout="vertical">
        <SkillName>
          <FormItemStyled
            key="form-key-name"
            name="skillName"
            {...(!skillName && {
              validateStatus: 'error',
              help: t('common.fieldIsRequired'),
            })}
            rules={[
              {
                max: MAX_NAME_LENGTH,
                message: t('common.fieldRangeValue90'),
              },
              () => ({
                validator(_, value = '') {
                  if (skills.some((skill) => skill.name === value)) {
                    setSkillRepeated(true);
                    return Promise.reject(
                      new Error(
                        t('humanCapital.skills.allTheSkillsMustBeDifferent')
                      )
                    );
                  }
                  setSkillRepeated(false);
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Autocomplete
              value={skillName}
              options={options.map((el, index) => ({
                value: el.value,
                key: index,
              }))}
              onKeyPress={(event: any) => {
                const inputValue = event.target.value;
                const skillNameValidatedValue = inputValue
                  ? inputValue.trimStart()
                  : '';
                setSkillName(skillNameValidatedValue);
                addSkillForm.setFieldValue(
                  'skillName',
                  skillNameValidatedValue
                );
              }}
              onChange={onSkillNameChange}
              placeholder={getPlaceholder(skillCategory.name, t)}
              notFoundContent={
                skillName.length < 1 ? (
                  ''
                ) : (
                  <>
                    <LinkOutlined /> {t('humanCapital.skills.noData')}
                  </>
                )
              }
              filterOption={onFilterName}
            />
          </FormItemStyled>
        </SkillName>
        <FormItemStyled key="form-key-name" name="skillRating">
          <RatingComponent>
            <FormItemStyled
              key="form-key-name"
              name="rating"
              {...(!rating && {
                validateStatus: 'error',
                help: t('common.fieldIsRequired'),
              })}
              rules={[
                {
                  required: true,
                  message: t('common.fieldIsRequired'),
                },
              ]}
            >
              <Rate
                count={skillLevels.length}
                tooltips={[]}
                onChange={(rate) => {
                  setRating(rate);
                }}
                value={rating}
              />
            </FormItemStyled>
            <RatingName>
              {!rating
                ? t('humanCapital.skills.level')
                : skillLevels[rating - 1].name}
            </RatingName>
          </RatingComponent>
        </FormItemStyled>
        <Footer>
          <Button type="link" onClick={onSaveHandler} disabled={disable}>
            <DefaultButton>{t('common.save')}</DefaultButton>
          </Button>
        </Footer>
      </FormStyled>
    </PopoverContainer>
  );
};

export default memo(AddContent);
