import React, { ComponentType } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { theme } from 'theme';
import { UploadStyled, ButtonStyled } from './styles';

const DEFAULT_HEIGHT = '32px';

interface Props extends UploadProps<any> {
  label: string;
  width: string;
  margin?: string;
  height?: string;
  size?: SizeType;
}

const Upload = ({ height, ...restProps }: Props) => {
  return (
    <UploadStyled<ComponentType<any>> {...restProps}>
      <ButtonStyled
        width={restProps.width}
        height={height || DEFAULT_HEIGHT}
        disabled={restProps.disabled ? restProps.disabled : false}
        icon={<UploadOutlined />}
      >
        {restProps.label}
      </ButtonStyled>
    </UploadStyled>
  );
};

export default Upload;

Upload.defaultProps = {
  margin: '',
  height: DEFAULT_HEIGHT,
  size: theme.selectSizeType.size,
};
