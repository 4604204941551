import styled from 'styled-components';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

export const BreadcrumbStyled = styled(Breadcrumb)`
  margin-left: 2vh;
`;
export const BreadcrumbItemStyled = styled(Breadcrumb.Item)`
  color: ${({ theme }) => theme.colors.mainColor};
  font-weight: bold;
`;
export const LinkStyled = styled(Link)`
  color: ${({ theme }) => theme.colors.mainColor}!important;
`;
