import styled from 'styled-components';
import { Select } from 'antd';

export const SelectStyled = styled(Select)<{
  width: string;
  margin?: string;
}>`
  width: ${({ width }) => width};
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}

  div.ant-select-selector {
    border-radius: ${({ theme }) => theme.shape.generalBorderRadius} !important;
  }
`;

export const OptionStyled = styled(Select.Option)``;
