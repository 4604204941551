import React from 'react';
import { FilePdfOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { manualObject, manuals, manualCategories } from './helper';
import {
  StyledManualAnchor,
  StyledManualHeader,
  StyledManualLink,
  StyledUnorderedList,
} from './styles';

const ManualList = () => {
  const { t } = useTranslation();
  return (
    <>
      {manualCategories.map((category) => (
        <div key={`manual-${category}`}>
          <StyledManualHeader>
            {t(`timetracker.manualList.${category}`)}
          </StyledManualHeader>
          <StyledUnorderedList>
            {manuals
              .filter((manual: manualObject) => manual.category === category)
              .map((manual: manualObject) => (
                <StyledManualLink
                  key={`manual-${category}-${manual.manualLink}`}
                >
                  <StyledManualAnchor
                    href={manual.manualLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FilePdfOutlined /> {manual.caption}
                  </StyledManualAnchor>
                </StyledManualLink>
              ))}
          </StyledUnorderedList>
        </div>
      ))}
    </>
  );
};

export default ManualList;
