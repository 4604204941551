import { Reducer } from 'redux';
import { DEFAULT_LOCALE } from 'shared/constants/common.const';
import {
  LocalesActionTypes,
  LocaleState,
  SET_LOCALES,
  SET_CURRENT_LOCAL,
  SetLocalesAction,
  SetCurrentLocalAction,
} from './locale.types';

const initialState: LocaleState = {
  currentLocal: DEFAULT_LOCALE,
  locales: null,
};

const localeReducer: Reducer<LocaleState, LocalesActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_LOCALES: {
      return {
        ...state,
        locales: (action as SetLocalesAction).payload,
      };
    }

    case SET_CURRENT_LOCAL: {
      return {
        ...state,
        currentLocal: (action as SetCurrentLocalAction).payload,
      };
    }

    default:
      return state;
  }
};

export default localeReducer;
