import { Options } from 'components/Form/helpers';
import moment from 'moment';

export const enum FormFields {
  hidden = '',
  basicInfo = 'basicInfo',
  email = 'personalEmailAddress',
  identificationType = 'identificationTypeId',
  identification = 'identification',
  issuedIn = 'placeOfIssuance',
  names = 'name',
  firstLastName = 'firstLastName',
  secondLastName = 'secondLastName',
  dateOfBirth = 'birthDate',
  nationality = 'placeOfBirthId',
  gender = 'genderId',
  contactInfo = 'contactInfo',
  residentCountry = 'countryId',
  state = 'stateId',
  city = 'cityId',
  phoneNumber = 'phoneNumber',
  zipCode = 'zipCode',
  address = 'address',
  recruiter = 'recruiter',
  recruiterName = 'recruiterId',
  isReferred = 'isReferred',
  nameOfReferral = 'referredId',
  role = 'roleName',
  status = 'candidateStatusId',
}

export const enum FormTitles {
  hidden = '',
  basicInfo = 'timetracker.people.personalInformation',
  email = 'humanCapital.personalInformation.personalEmail',
  identificationType = 'common.identificationType',
  identification = 'timetracker.clients.identification',
  issuedIn = 'humanCapital.personalInformation.issuedIn',
  names = 'common.names',
  firstLastName = 'timetracker.people.firstLastName',
  secondLastName = 'timetracker.people.secondLastName',
  dateOfBirth = 'timetracker.people.birthDay',
  nationality = 'positions.candidate.nationalityCountry',
  gender = 'humanCapital.personalInformation.gender',
  contactInfo = 'timetracker.people.contactInformation',
  residentCountry = 'positions.candidate.residentCountry',
  state = 'common.state',
  city = 'common.city',
  phoneNumber = 'common.phoneNumber',
  zipCode = 'common.zipCode',
  address = 'common.address',
  recruiter = 'positions.candidate.recruiter',
  recruiterName = 'positions.candidate.recruiter',
  isReferred = 'positions.candidate.isReferred',
  nameOfReferral = 'positions.candidate.nameOfReferral',
  role = 'humanCapital.employmentData.role',
  status = 'common.status',
}

export const enum FormPlaceholders {
  hidden = '',
  basicInfo = 'timetracker.people.personalInformation',
  email = 'timetracker.people.typeEmailAddress',
  identificationType = 'timetracker.projects.selectType',
  identification = 'timetracker.people.typeIdentification',
  issuedIn = 'humanCapital.personalInformation.selectIssuedIn',
  names = 'common.name',
  firstLastName = 'positions.candidate.lastName',
  secondLastName = 'timetracker.people.secondLastName',
  dateOfBirth = 'timetracker.people.selectBirthDay',
  nationality = 'common.selectCountry',
  gender = 'humanCapital.personalInformation.selectGender',
  contactInfo = 'timetracker.people.contactInformation',
  residentCountry = 'common.selectCountry',
  state = 'common.selectState',
  city = 'common.selectCity',
  phoneNumber = 'common.phoneNumber',
  zipCode = 'common.zipCode',
  address = 'common.typeAddress',
  recruiter = '',
  recruiterName = 'common.name',
  isReferred = '',
  nameOfReferral = 'positions.candidate.nameOfReferral',
  role = 'humanCapital.employmentData.role',
  status = 'common.status',
}

export const CC_IDENTIFICATION_TYPE = 5;

export const MIN_AGE = 16;

export const DATE_FORMAT = 'MM/DD/YYYY';

export const formatCandidateToFormField = (
  candidate: DTO.Candidate,
  colombianCities: Options[],
  states: Options[],
  genders: Options[],
  identificationTypes: Options[],
  recruiter: DTO.Person,
  referred: number | undefined,
  statusList: Options[],
  roles: Options[],
  selectedCities: Options[]
) => {
  return {
    [FormFields.email]: candidate.personalEmailAddress,
    [FormFields.dateOfBirth]: moment(candidate.birthdate),
    [FormFields.city]: selectedCities.find(
      (city) => city.value.toString() === candidate.cityId.toString()
    )?.value,
    [FormFields.identificationType]: identificationTypes.find(
      (type) =>
        type.value.toString() === candidate.identificationTypeId.toString()
    )?.value,
    [FormFields.identification]: candidate.identification,
    [FormFields.names]: candidate.name,
    [FormFields.firstLastName]: candidate.firstLastName,
    [FormFields.secondLastName]: candidate.secondLastName,
    [FormFields.nationality]: candidate.placeOfBirthId,
    [FormFields.gender]: genders.find(
      (gender) => gender.value.toString() === candidate.genderId.toString()
    )?.value,
    [FormFields.residentCountry]: candidate.countryId,
    [FormFields.state]: states.find(
      (state) => state.value.toString() === candidate.stateId.toString()
    )?.value,
    [FormFields.phoneNumber]: candidate.phoneNumber,
    [FormFields.zipCode]: candidate.zipCode,
    [FormFields.address]: candidate.address,
    [FormFields.recruiterName]: recruiter.personId,
    [FormFields.isReferred]: candidate.isReferred,
    [FormFields.nameOfReferral]: referred,
    [FormFields.role]: roles.find(
      (role) => role.value.toString() === candidate.roleId.toString()
    )?.name,
    [FormFields.status]: statusList.find(
      (status) =>
        status.value.toString() === candidate.candidateStatusId.toString()
    )?.value,
    [FormFields.issuedIn]: colombianCities.find(
      (city) => city.value.toString() === candidate.placeOfIssuance?.toString()
    )?.value,
  };
};

export const formatCandidateToEdit = (
  candidate: DTO.AddCandidateRequest,
  isReferred: boolean
) => ({
  personalEmailAddress: candidate.personalEmailAddress,
  identificationTypeId: candidate.identificationTypeId,
  identification: candidate.identification,
  placeOfIssuance: candidate.placeOfIssuance,
  name: candidate.name,
  firstLastName: candidate.firstLastName,
  secondLastName: candidate.secondLastName,
  birthDate: candidate.birthDate,
  placeOfBirthId: candidate.placeOfBirthId,
  genderId: candidate.genderId,
  countryId: candidate.countryId,
  stateId: candidate.stateId,
  cityId: candidate.cityId,
  phoneNumber: candidate.phoneNumber,
  zipCode: candidate.zipCode,
  address: candidate.address,
  recruiterId: candidate.recruiterId,
  isReferred,
  referredId: candidate.referredId,
  roleName: candidate.roleName,
  candidateStatusId: candidate.candidateStatusId,
});
