import { smartFetch } from 'shared/utils/async.utils';

export interface FetchParams {
  token?: string;
  apiUrl?: string;
  url?: string;
  options?: RequestInit | Record<string, unknown>;
  /** New headers will be added to default headers, if these has the  */
  addHeaders?: Record<string, unknown>;
}

class ApiService {
  private token = '';

  private companyId = '';

  private languageId = '';

  request<T = void>({
    url,
    apiUrl = window.APP_CONFIG.API_BASE_URI ?? '',
    options = {},
    addHeaders = {},
  }: FetchParams): Promise<T> {
    return smartFetch.fetch<T>(apiUrl + url, {
      headers: {
        Authorization: `Bearer ${this.token}`,
        'Content-Type': 'application/json',
        CompanyId: this.companyId,
        LanguageId: this.languageId,
        ...addHeaders,
      },
      ...options,
    }) as Promise<T>;
  }

  requestHTML<T = void>({
    url,
    apiUrl = window.APP_CONFIG.API_BASE_URI ?? '',
    options = {},
  }: FetchParams): Promise<T> {
    return smartFetch.fetch<T>(
      apiUrl + url,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': '*',
          CompanyId: this.companyId,
          LanguageId: this.languageId,
        },
        ...options,
      },
      true
    ) as Promise<T>;
  }

  requestTimesheetReport<T = any>({
    url,
    apiUrl = window.APP_CONFIG.API_BASE_URI ?? '',
    options = {},
  }: FetchParams): Promise<T> {
    return smartFetch.fetch<T>(
      apiUrl + url,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/xlsx',
          CompanyId: this.companyId,
          LanguageId: this.languageId,
        },
        ...options,
      },
      true
    ) as Promise<T>;
  }

  requestImportTimesheet<T = void>({
    url,
    apiUrl = window.APP_CONFIG.API_BASE_URI ?? '',
    options = {},
  }: FetchParams): Promise<T> {
    return smartFetch.fetch<T>(
      apiUrl + url,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          CompanyId: this.companyId,
          LanguageId: this.languageId,
        },
        ...options,
      },
      true
    ) as Promise<T>;
  }

  setToken(token: string): void {
    this.token = token;
  }

  setCompanyId(companyId: string): void {
    this.companyId = companyId;
  }

  getCompanyId(): any {
    return this.companyId;
  }

  setLanguageId(languageId: string): void {
    this.languageId = languageId;
  }

  hasCompanyId(): boolean {
    return this.companyId !== '';
  }
}

export const apiService = new ApiService();
