import { Button, Rate } from 'antd';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';

import {
  RequiredText,
  CancelFooter,
  Description,
  InfoCircleStyled,
  LevelRow,
  RatingComponent,
  RatingName,
  TooltipStyled,
} from './styles';
import { SKILL_TYPES } from '../helpers';

interface Props {
  skillType: string;
  skillTypeId: number;
  ratingNames: DTO.PositionSkillLevel[];
}

const InfoIcon = ({
  skillType,
  skillTypeId,
  ratingNames,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const ratingNamesFiltered = useCallback(() => {
    return ratingNames?.filter((level) => {
      return level.skillTypeId === skillTypeId;
    });
  }, [skillTypeId, ratingNames]);

  const getTitle = useCallback(() => {
    switch (skillType) {
      case SKILL_TYPES.professional:
        return t('positions.skillCategories.infoProfessionalTitle');
      case SKILL_TYPES.soft:
        return t('positions.skillCategories.infoSoftTitle');
      case SKILL_TYPES.language:
        return t('positions.skillCategories.infoLanguagesTitle');
      case SKILL_TYPES.niceToHave:
        return t('positions.skillCategories.infoNiceToHaveTitle');
      default:
        return '';
    }
  }, [skillType, t]);

  return (
    <>
      {skillType !== SKILL_TYPES.niceToHave && <RequiredText>*</RequiredText>}
      <span>{t(skillType)}</span>
      <TooltipStyled
        title={t('humanCapital.skillLevels.additionalInformation')}
      >
        <Button
          type="link"
          disabled={ratingNames.length === 0}
          onClick={() => {
            setOpen(true);
          }}
          icon={<InfoCircleStyled />}
        />
      </TooltipStyled>
      <Modal
        title={getTitle()}
        centered
        open={open}
        width="60%"
        onCancel={() => setOpen(false)}
        footer={[
          <CancelFooter key="close">
            <Button type="primary" onClick={() => setOpen(false)}>
              {t('common.close')}
            </Button>
          </CancelFooter>,
        ]}
      >
        {ratingNamesFiltered().map((level) => (
          <LevelRow key={level.id}>
            <RatingComponent>
              <Rate
                count={ratingNamesFiltered().length}
                value={level.score}
                disabled
              />
              <RatingName>{t(level.name)}</RatingName>
            </RatingComponent>
            <Description>{level.description}</Description>
          </LevelRow>
        ))}
      </Modal>
    </>
  );
};

export default memo(InfoIcon);
