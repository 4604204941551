import { matchPath } from 'react-router-dom';
import { findPathInMenuItem } from 'shared/utils/common.utils';

export const BREADCRUMB_INITIAL_STATE: {
  key: number;
  name: string;
  path: string;
}[] = [];

const getItemIfNested = (currentPath: string, item: any) => {
  let nestedPath;

  if (item.nestedPaths) {
    nestedPath = item.nestedPaths.find((p: { path: string; name: string }) => {
      return matchPath(currentPath, { path: p.path, exact: false }) !== null;
    });
  }

  if (nestedPath) {
    return {
      key: `${item.key}-${nestedPath.name}`,
      name: nestedPath.name,
      path: nestedPath.path,
    };
  }

  return item;
};

export const getMatchingPaths = (currentPath: string, menuItems: any) => {
  let evaluatedPath = '';
  const matchedPaths: { key: number; name: string; path: string }[] = [];
  const splitedPaths = currentPath.split('/');

  for (let i = 1; i < splitedPaths.length; i += 1) {
    const p = splitedPaths[i];
    evaluatedPath += `/${p}`;
    const item = findPathInMenuItem(evaluatedPath, menuItems);

    if (item) {
      matchedPaths.push(getItemIfNested(evaluatedPath, item));
    }
  }

  return matchedPaths;
};
