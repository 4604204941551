import { SaveSummary } from 'features/Modules/Timetracker/People/Person/Summary/helpers';
import {
  ChangeActiveSummary,
  EditSummary,
} from 'features/Modules/Timetracker/People/Person/Summary/ListSummary/helpers';
import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class SummaryService {
  getSummaryById = (personId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getSummaryById}/${personId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getSummaryByOwner = (personId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getSummaryByOwner}/${personId}`,
      options: {
        method: 'GET',
      },
    });
  };

  deleteSummary = (summaryId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.deleteSummary}/${summaryId}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  addSummary = (summary: SaveSummary): Promise<any> => {
    return apiService.request({
      url: Endpoints.addSummary,
      options: {
        method: 'POST',
        body: JSON.stringify(summary),
      },
    });
  };

  editSummary = (summary: EditSummary): Promise<any> => {
    return apiService.request({
      url: Endpoints.editSummary,
      options: {
        method: 'PUT',
        body: JSON.stringify(summary),
      },
    });
  };

  deleteSummaryByOwner = (summaryId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.deleteSummaryByOwner}/`,
      options: {
        method: 'DELETE',
        body: JSON.stringify({ summaryId }),
      },
    });
  };

  addSummaryByOwner = (summary: SaveSummary): Promise<any> => {
    return apiService.request({
      url: Endpoints.addSummaryByOwner,
      options: {
        method: 'POST',
        body: JSON.stringify(summary),
      },
    });
  };

  editSummaryByOwner = (summary: EditSummary): Promise<any> => {
    return apiService.request({
      url: Endpoints.editSummaryByOwner,
      options: {
        method: 'PUT',
        body: JSON.stringify(summary),
      },
    });
  };

  changeActiveSummary = (summary: ChangeActiveSummary): Promise<any> => {
    const endpoint = summary.isActive
      ? Endpoints.activeSummary
      : Endpoints.inactiveSummary;

    return apiService.request({
      url: endpoint,
      options: {
        method: 'PATCH',
        body: JSON.stringify({
          id: summary.id,
          personId: summary.personId,
        }),
      },
    });
  };

  changeActiveSummaryByOwner = (summary: ChangeActiveSummary): Promise<any> => {
    const endpoint = summary.isActive
      ? Endpoints.activeSummaryByOwner
      : Endpoints.inactiveSummaryByOwner;

    return apiService.request({
      url: endpoint,
      options: {
        method: 'PATCH',
        body: JSON.stringify({
          id: summary.id,
          personId: summary.personId,
        }),
      },
    });
  };
}

export const summaryService = new SummaryService();
