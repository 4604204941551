import styled from 'styled-components';

const DivContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 20px;
  align-items: baseline;
`;

const DivButton = styled.div`
  margin-left: auto;
  margin-right: 2vh;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

export { DivContainer, DivButton, Title };
