import React from 'react';
import { CONFIGURATIONS_PATH } from 'features/Routes/helpers';
import flowhrLogo from 'shared/images/flowhr_logo.svg';
import { FIRST_ELEMENT } from 'shared/constants/common.const';
import {
  UserOutlined,
  FileOutlined,
  TeamOutlined,
  IdcardOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import { LayoutConfig, MenuItem } from 'features/Layouts/helpers';
import { Permissions } from '../GuardedRoute/helpers';

export const CONFIGURATION_LAYOUT: LayoutConfig = {
  title: 'timetracker.title',
  logo: flowhrLogo,
  menuItems: [
    {
      key: 1,
      name: 'configurations.users',
      icon: <UserOutlined />,
      path: CONFIGURATIONS_PATH.USERS,
      nestedPaths: [
        {
          name: 'timetracker.people.assignProfile',
          path: CONFIGURATIONS_PATH.USERS_PROFILES,
        },
        {
          name: 'timetracker.areas.assignArea',
          path: CONFIGURATIONS_PATH.USERS_PROFILES_AREAS,
        },
      ],
    },
    {
      key: 2,
      name: 'configurations.profiles',
      icon: <ProfileOutlined />,
      path: CONFIGURATIONS_PATH.PROFILES,
      nestedPaths: [
        {
          name: 'configurations.profiles',
          path: CONFIGURATIONS_PATH.PROFILE_TREE,
        },
      ],
    },
    {
      key: 3,
      name: 'configurations.companies',
      icon: <IdcardOutlined />,
      path: CONFIGURATIONS_PATH.COMPANIES,
      nestedPaths: [
        {
          name: 'timetracker.companies.create',
          path: CONFIGURATIONS_PATH.COMPANIES_CREATE,
        },
        {
          name: 'timetracker.companies.edit',
          path: CONFIGURATIONS_PATH.COMPANIES_EDIT,
        },
        {
          name: 'timetracker.companies.view',
          path: CONFIGURATIONS_PATH.COMPANIES_VIEW,
        },
      ],
    },
    {
      key: 4,
      name: 'configurations.companyGroups',
      icon: <IdcardOutlined />,
      path: CONFIGURATIONS_PATH.COMPANY_GROUP,
    },
    {
      key: 5,
      name: 'timetracker.menu.customers',
      icon: <TeamOutlined />,
      path: CONFIGURATIONS_PATH.CUSTOMERS,
      nestedPaths: [
        {
          name: 'timetracker.menu.clientProjects',
          path: CONFIGURATIONS_PATH.CUSTOMER_PROJECTS,
        },
        {
          name: 'timetracker.role.roles',
          path: CONFIGURATIONS_PATH.BILLABLE_ROLES,
        },
      ],
    },
    {
      key: 6,
      name: 'timetracker.menu.projects',
      icon: <FileOutlined />,
      path: CONFIGURATIONS_PATH.PROJECTS,
      nestedPaths: [
        {
          name: 'timetracker.menu.projectDetails',
          path: CONFIGURATIONS_PATH.PROJECT_DETAILS,
        },
        {
          name: 'timetracker.projects.externalProjectId',
          path: CONFIGURATIONS_PATH.PROJECT_EXTERNAL_ID,
        },
        {
          name: 'timetracker.projects.projectsList',
          path: CONFIGURATIONS_PATH.ASSIGNMENT_LIST,
        },
        {
          name: 'configuration.projects.approver_other',
          path: CONFIGURATIONS_PATH.PROJECT_APPROVERS,
        },
      ],
    },
  ],
};

export const getInitialRoute = (permissions: Permissions[]): string => {
  const conf: Permissions[] = permissions.filter(
    (permission) => permission.sectionPath === CONFIGURATIONS_PATH.ROOT
  );
  const confgOrder: string = conf.reduce((lowest, current) => {
    return current.order < lowest.order ? current : lowest;
  }, conf[FIRST_ELEMENT]).optionPath;

  return confgOrder;
};

export const getAllLayout = (permissions: Permissions[]): LayoutConfig => {
  const layout: LayoutConfig = {
    title: 'timetracker.title',
    logo: flowhrLogo,
    menuItems: [],
  };
  const menuFiltered = CONFIGURATION_LAYOUT.menuItems.filter(
    (menu: MenuItem) => {
      return permissions.some((permission: Permissions) => {
        return permission.optionPath === menu.path;
      });
    }
  );

  layout.menuItems = menuFiltered;
  return layout;
};
