import styled from 'styled-components';
import { Form } from 'antd';

export const FormStyled = styled(Form)`
  .ant-col-23 {
    max-width: 98%;
  }
`;

export const FormItemStyled = styled(Form.Item)``;
