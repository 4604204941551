import { createContext } from 'react';
import { AddPositionContext } from './helpers';

export default createContext<AddPositionContext>({
  currentPosition: undefined,
  hasChanged: false,
  setHasChanged: () => {
    throw new Error('setHasChanged function must be overridden');
  },
});
