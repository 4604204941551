import { combineReducers, AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import locale from './locale/locale.reducer';
import auth from './auth/auth.reducer';

export const rootReducer = combineReducers({ auth, locale });

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = ThunkDispatch<RootState, void, AnyAction> & Dispatch;
