import React, { FC, memo, PropsWithChildren } from 'react';
import { DropDownProps } from 'antd';
import { DropdownStyled, MenuStyled } from './styles';

const Dropdown: FC<PropsWithChildren<DropDownProps>> = ({
  children,
  ...restProps
}): JSX.Element => {
  return <DropdownStyled {...restProps}>{children}</DropdownStyled>;
};

export const DropdownMenu = MenuStyled;
export default memo(Dropdown);
