import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class SettingService {
  getSettingByName = (settingName: string): Promise<DTO.Settings> => {
    return apiService.request({
      url: `${Endpoints.getSettingByName}${settingName}`,
      options: {
        method: 'GET',
      },
    });
  };
}

export const settingService = new SettingService();
