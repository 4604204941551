import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class TimeEntryHistoryService {
  GetAllByPeriod = (
    page: number,
    pageSize: number,
    timePeriodId: string,
    projectId: DTO.Nullable<string>,
    personId: string,
    filter: Object
  ): Promise<DTO.TimeEntryHistoryInfo> => {
    return apiService.request({
      url: `${Endpoints.getAllTimeEntriesHistory}${personId}/${projectId}/${timePeriodId}`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          page,
          pageSize,
          sortDirection: 'desc',
          filter,
        }),
      },
    });
  };
}

export const timeEntryHistoryService = new TimeEntryHistoryService();
