import styled from 'styled-components';

export const DivContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
`;

export const DivWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  cursor: pointer;

  .ant-btn {
    width: 100%;
  }
`;

export const DivWrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
`;

export const Divider = styled.hr`
  border: none;
  border-left: 1px solid #dfe0eb;
  height: 32px;
  width: 1px;
  margin: 0 10px;
`;
