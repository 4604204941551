import React from 'react';
import { PhoneInputProps } from 'react-phone-input-2';
import { PhoneInputStyled } from './styles';

const PhoneInput = (Props: PhoneInputProps) => {
  return (
    <>
      <PhoneInputStyled {...Props} />
    </>
  );
};

export default PhoneInput;
