import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class NotificationService {
  getAllFilter = (
    page: number,
    pageSize: number,
    filter?: any
  ): Promise<any> => {
    return apiService.request({
      url: Endpoints.getFilterAllNotifications,
      options: {
        method: 'POST',
        body: JSON.stringify({
          page,
          pageSize,
          filter,
          valueId: '',
          sortDirection: 'desc',
          sortColumn: 'createdAt',
        }),
      },
    });
  };

  getAll = (): Promise<any> => {
    return apiService.request({
      url: Endpoints.getAllNotificationsByPersonId,
      options: {
        method: 'GET',
      },
    });
  };

  hideNotification = (notificationId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.hideNotification}/${notificationId}`,
      options: {
        method: 'PUT',
      },
    });
  };

  readNotification = (notificationId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.readNotification}/${notificationId}`,
      options: {
        method: 'PUT',
      },
    });
  };

  clearNotifications = (arrayIds: number[]): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.clearNotifications}`,
      options: {
        method: 'POST',
        body: `[${arrayIds}]`,
      },
    });
  };
}

export const notificationService = new NotificationService();
