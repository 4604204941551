import styled from 'styled-components';
import { Typography } from 'antd';

export const SkillTitleSection = styled(Typography)`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.links};
  margin-bottom: 25px;
  margin-top: 25px;
`;
export const ButtonsWrapper = styled.section`
  margin: 10px 50px 50px auto;
  text-align: center;
  display: flex;
  justify-content: end;
  flex-basis: 100%;
  column-gap: 30px;
`;

export const TitleStyled = styled(Typography)`
  margin-bottom: 25px;
  font-size: 15px;
  font-weight: bold;
  .ant-tooltip-inner {
    font-size: 14px;
    font-weight: normal;
  }
`;

export const CommentsWrapper = styled.div`
  box-sizing: border-box;
  padding-right: 65px;
`;
