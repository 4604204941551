import { getFromSessionStorage } from 'shared/utils/storage.utils';
import { COMPANY_ID } from 'shared/constants/common.const';

const MAX_NAME_LENGTH = 90;

const getPlaceholder = (skillName: string, t: Function) => {
  switch (skillName) {
    case 'positions.skillCategories.professionalSkills':
      return t('humanCapital.skills.profPlaceholder');
    case 'positions.skillCategories.softSkills':
      return t('humanCapital.skills.softPlaceholder');
    case 'positions.skillCategories.languages':
      return t('humanCapital.skills.langPlaceholder');
    case 'positions.skillCategories.niceToHave':
      return t('humanCapital.skills.profPlaceholder');
    default:
      return '';
  }
};

const getCompanyId: Function = async () => {
  return getFromSessionStorage(COMPANY_ID);
};

export { MAX_NAME_LENGTH, getPlaceholder, getCompanyId };
