import { Typography } from 'antd';
import Form from 'components/Form';
import styled from 'styled-components';

export const PopoverContainer = styled.div``;

export const SkillName = styled.div`
  width: auto;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  .ant-btn {
    width: 50px;
    height: 30px;
  }
`;

export const DeleteButton = styled(Typography)`
  text-decoration: underline;
  color: red;
`;

export const DefaultButton = styled(Typography)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.mainColor};
`;

export const RatingComponent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const FormItemStyled = styled(Form.Item)`
  margin-bottom: 12px;
`;
export const FormStyled = styled(Form)`
  flex-direction: column;
`;
