import styled from 'styled-components';

export const DivContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin: 0 0 40px 0;
  width: 100%;
  h1 {
    margin: 0;
  }
`;

export const DivWrapper = styled.div`
  width: auto;
  display: flex;
  margin: 15px 0;
`;

export const ButtonWrapper = styled.div`
  width: auto;
  display: flex;
  margin-right: 24px;
  margin-bottom: 24px;
  justify-content: flex-end;

  button {
    margin: 50px 4px 0 4px;
  }
`;

export const SpanStyled = styled.span`
  color: red;
`;

export const FormField = styled.div`
  width: 100%;
`;

export const RequiredMessage = styled.h4`
  position: absolute;
  right: calc(33.33333333% - 100px);
  top: 25px;
  margin: auto;
  max-width: 1500px;
  text-align: right;
  color: red;
`;

export const FormWrapper = styled.div`
  padding-top: 12px;
`;
