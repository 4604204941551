import React from 'react';

const DeleleSVGIcon = () => (
  <svg width={22} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#a)">
      <circle cx={10.833} cy={8.278} r={7.278} fill="#fff" />
      <circle cx={10.833} cy={8.278} r={6.778} stroke="#F9FAFF" />
    </g>
    <path
      d="m12.29 10.405-1.527-1.527-1.505 1.505-.659-.659 1.505-1.505-1.49-1.49.681-.68 1.49 1.49 1.497-1.498.659.659-1.497 1.497 1.527 1.527-.681.681Z"
      fill="#000"
      fillOpacity={0.85}
    />
    <defs>
      <filter
        id="a"
        x={0.555}
        y={0}
        width={20.555}
        height={20.557}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5763_12289"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_5763_12289"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default DeleleSVGIcon;
