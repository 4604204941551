import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LayoutConfig, NestedPath } from 'features/Layouts/helpers';
import flowHrLogo from 'shared/images/flowhr_logo.svg';
import { hasPermission } from 'shared/utils/common.utils';
import { PLACEMENT_PATH } from 'features/Routes/helpers';
import { UsergroupAddOutlined } from '@ant-design/icons';
import { generatePath } from 'react-router';
import { useTranslation } from 'react-i18next';

type OpenPositionProviderProps = {
  children: React.ReactNode;
};

type OpenPositionProvider = {
  availableRoutes: LayoutConfig;
  viewAllOpenPositionPermission: boolean;
  viewAllCandidatesPermissions: boolean;
};

const defaultConfig = {
  title: 'positions.title',
  logo: flowHrLogo,
  menuItems: [],
};

const OpenPositionContext = createContext<OpenPositionProvider>({
  availableRoutes: defaultConfig,
  viewAllOpenPositionPermission: false,
  viewAllCandidatesPermissions: false,
});

const OpenPositionProvider: FC<OpenPositionProviderProps> = (props) => {
  const { t } = useTranslation();
  const { children } = props;
  const [availableRoutes, setAvailableRoutes] =
    useState<LayoutConfig>(defaultConfig);

  const checkAddOpenPositionPermission = useMemo(() => {
    return hasPermission(
      'placement.openPositions.openPositions_add',
      PLACEMENT_PATH.POSITIONS
    );
  }, []);

  const checkEditOpenPositionPermission = useMemo(() => {
    return hasPermission(
      'placement.openPositions.openPositions_edit',
      PLACEMENT_PATH.POSITIONS
    );
  }, []);
  const isAbleToViewInterviewers = useMemo(() => {
    const path = generatePath(PLACEMENT_PATH.INTERVIEWERS);
    return (
      hasPermission('placement.interviewer.interviewer_viewAll', path) ||
      hasPermission('placement.interviewer.interviewer_add', path)
    );
  }, []);

  const nestedCreateOpenPosition: NestedPath[] = useMemo(() => {
    if (checkAddOpenPositionPermission) {
      return [
        {
          name: 'positions.createPosition',
          path: PLACEMENT_PATH.ADD_POSITION,
        },
      ];
    }
    return [];
  }, [checkAddOpenPositionPermission]);

  const nestedEditOpenPosition: NestedPath[] = useMemo(() => {
    if (checkEditOpenPositionPermission) {
      return [
        {
          name: 'positions.editPosition',
          path: PLACEMENT_PATH.EDIT,
        },
      ];
    }
    return [];
  }, [checkEditOpenPositionPermission]);

  const checkViewAllOpenPositionPermission = useMemo(() => {
    return hasPermission(
      'placement.openPositions.openPositions_viewAll',
      PLACEMENT_PATH.POSITIONS
    );
  }, []);

  const checkViewAllCandidatesPermissions = useMemo(() => {
    return hasPermission(
      'placement.candidate.candidate_viewAll',
      PLACEMENT_PATH.CANDIDATES
    );
  }, []);

  const checkPositionsPermissions = useCallback(() => {
    let routes: LayoutConfig = defaultConfig;
    if (checkViewAllOpenPositionPermission) {
      routes = {
        ...routes,
        menuItems: [
          ...routes.menuItems,
          {
            key: routes.menuItems.length + 1,
            name: 'positions.positionsList',
            icon: <UsergroupAddOutlined />,
            path: PLACEMENT_PATH.POSITIONS,
            nestedPaths: [
              ...nestedCreateOpenPosition,
              {
                name: '',
                path: PLACEMENT_PATH.VIEW,
              },
              ...nestedEditOpenPosition,
            ],
          },
        ],
      };
    }
    if (checkViewAllCandidatesPermissions) {
      routes = {
        ...routes,
        menuItems: [
          ...routes.menuItems,
          {
            key: routes.menuItems.length + 1,
            name: 'positions.candidate.candidate',
            icon: <UsergroupAddOutlined />,
            path: PLACEMENT_PATH.CANDIDATES,
          },
        ],
      };
    }
    if (isAbleToViewInterviewers) {
      routes = {
        ...routes,
        menuItems: [
          ...routes.menuItems,
          {
            key: routes.menuItems.length + 1,
            name: t('positions.interviewers.interviewer', { count: 0 }),
            icon: <UsergroupAddOutlined />,
            path: generatePath(PLACEMENT_PATH.INTERVIEWERS),
          },
        ],
      };
    }
    setAvailableRoutes(routes);
  }, [
    checkViewAllOpenPositionPermission,
    checkViewAllCandidatesPermissions,
    isAbleToViewInterviewers,
    nestedCreateOpenPosition,
    nestedEditOpenPosition,
    t,
  ]);

  useEffect(() => {
    checkPositionsPermissions();
  }, [checkPositionsPermissions]);

  return (
    <OpenPositionContext.Provider
      value={{
        availableRoutes,
        viewAllOpenPositionPermission: checkViewAllOpenPositionPermission,
        viewAllCandidatesPermissions: checkViewAllCandidatesPermissions,
      }}
    >
      {children}
    </OpenPositionContext.Provider>
  );
};

export default OpenPositionProvider;

export const usePositions = () => useContext(OpenPositionContext);
