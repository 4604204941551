export const SET_LOCALES = 'SET_LOCALES';
export const SET_CURRENT_LOCAL = 'SET_CURRENT_LOCAL';

export type LocaleState = Readonly<{
  currentLocal: DTO.Nullable<DTO.Locale>;
  locales: DTO.Nullable<DTO.Locale[]>;
}>;

export type SetLocalesAction = Store.PayloadedAction<DTO.Locale[]>;
export type SetCurrentLocalAction = Store.PayloadedAction<DTO.Locale>;

export type LocalesActionTypes = SetLocalesAction | SetCurrentLocalAction;
