import styled from 'styled-components';
import { Col } from 'antd';
import Form from 'components/Form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export const ColStyled = styled(Col)``;

export const PhoneInputStyled = styled(PhoneInput)`
  .form-control {
    width: 100% !important;
    height: 31px !important;
  }
`;

export const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0;
`;

export const DivWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const HrStyled = styled.hr`
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 10px;
`;

export const ButtonWrapper = styled.div`
  margin: 10px 0px 0px auto;
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

export const DivInput = styled.div`
  width: 100%;
  margin: 1rem 0;
`;

export const FormWrapperStyled = styled.div<{ compress?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: none;

  div#personalData_emailAddress_help
    .ant-form-item-explain-error:not(:last-child) {
    display: none;
  }

  @media (max-width: 1220px) {
    input,
    div {
      ${({ compress }) => (compress ? 'width: 170px;' : '')}
    }
  }
`;

export const FormSection = styled.h2`
  color: ${({ theme }) => theme.colors.secondaryText};
  margin: 30px 0 30px 0;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
`;
