import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export const PhoneInputStyled = styled(PhoneInput)`
  ${({ disabled, theme }) =>
    `.form-control {
    width: 100% !important;
    height: 31px !important;
    border: 1px solid ${theme.colors.inputBorder} !important;
    background: ${
      disabled
        ? `${theme.colors.backgroundDisabled} !important`
        : theme.colors.white
    };}
    color: ${
      disabled ? `${theme.colors.textDisabled} !important` : theme.colors.black
    };}
  ::placeholder {
    color: ${theme.colors.outlines};
    font-size: 12px;
  }`}
`;
