import React, { FC, useState, useMemo, useCallback } from 'react';
import Menu from 'components/Menu/index';
import UserProfile from 'components/UserProfile/index';
import SystemManual from 'components/SystemManual';
import { MenuOutlined } from '@ant-design/icons';
import { APP_PATH } from 'features/Routes/helpers';
import Breadcrumb from 'components/Breadcrumb';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LayoutConfig } from './helpers';
import {
  LayoutStyled,
  LayoutHeader,
  LayoutSider,
  LayoutContent,
  ChildrenWrapper,
  LogoContainer,
  DivWrapper,
  DivHomeLogo,
  Footer,
  PolicyTextStyled,
  PolicyWraperStyled,
  EmptySpace,
} from './styles';

interface Props {
  config: LayoutConfig;
  isHome?: boolean;
}

const BaseLayout: FC<Props> = ({ children, config, isHome }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation();
  const { logo, menuItems } = config;
  const items = useMemo(() => menuItems, [menuItems]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_selectedItem, setSelectedItem] = useState(
    items && items.length > 0 ? items[0].name : ''
  );

  const toggle = () => {
    setCollapsed((prevState) => !prevState);
  };

  const handleSelected = useCallback(
    (key: string) => {
      if (!items) {
        return;
      }

      const menuItem = items.find((item) => item.key === parseInt(key, 10));
      if (menuItem) {
        setSelectedItem(menuItem.name);
      }
    },
    [items]
  );

  return (
    <LayoutStyled>
      {!isHome && (
        <LayoutSider trigger={null} collapsible collapsed={collapsed}>
          <LogoContainer to={APP_PATH.HOME}>
            <img src={logo} alt="Logo" />
          </LogoContainer>
          <Menu
            menuItems={items && items.length > 0 ? items : undefined}
            handleSelected={
              items && items.length > 0 ? handleSelected : undefined
            }
          />
        </LayoutSider>
      )}
      <LayoutStyled className="site-layout">
        <LayoutHeader>
          <DivWrapper>
            {isHome ? (
              <DivHomeLogo>
                <img src={logo} alt="Logo" />
              </DivHomeLogo>
            ) : (
              <MenuOutlined onClick={toggle} />
            )}

            <Breadcrumb
              menuItems={items && items.length > 0 ? items : undefined}
            />
          </DivWrapper>

          <UserProfile />
        </LayoutHeader>
        <LayoutContent>
          <SystemManual />
          {isHome && <EmptySpace />}
          <ChildrenWrapper
            className={`site-layout-background ${isHome ? 'home' : ''}`}
          >
            {children}
          </ChildrenWrapper>
        </LayoutContent>
        <Footer>
          <PolicyTextStyled>{t('loginPage.rightsReserved')}</PolicyTextStyled>
          <PolicyWraperStyled>
            <Link to={APP_PATH.POLICY}>
              <PolicyTextStyled>
                {t('loginPage.privacyPolicy')}
              </PolicyTextStyled>
            </Link>
            {' | '}
            <a href="https://forms.office.com/r/mBq9a9UTK0">
              <PolicyTextStyled>{t('loginPage.support')}</PolicyTextStyled>
            </a>
            {' | '}
            <Link to={APP_PATH.TERMS}>
              <PolicyTextStyled>{t('loginPage.terms')}</PolicyTextStyled>
            </Link>
          </PolicyWraperStyled>
        </Footer>
      </LayoutStyled>
    </LayoutStyled>
  );
};

BaseLayout.defaultProps = {
  isHome: false,
};

export default BaseLayout;
