export enum NOTIFICATION_STATE {
  new = 'timetracker.status.pending',
  read = 'timetracker.status.accepted',
  hidden = 'null',
}

export enum NOTIFICATION_STATE_ID {
  new = 3,
  read = 1,
  hidden = 5,
}

export type SignalNotificationType = {
  Id: number;
  AuthorFullName: string;
  CreatedAt: string;
  Description: string;
  PersonId: number;
  StatusId: number;
  StatusName: string;
  Url: string;
  TeamsLeaderUserId: string;
};

export const enum UserMenuKeys {
  manageProfile,
}

export const formatSignal = (
  signal: SignalNotificationType
): DTO.Notification => {
  return {
    id: signal.Id,
    authorFullName: signal.AuthorFullName,
    createdAt: signal.CreatedAt,
    description: signal.Description,
    personId: signal.PersonId,
    statusId: signal.StatusId,
    statusName: signal.StatusName,
    url: signal.Url,
  };
};
