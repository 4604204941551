import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class UserService {
  getUsers = (
    valueId: string,
    page: number,
    pageSize: number,
    filter: {}
  ): Promise<DTO.membersInfo> => {
    return apiService.request({
      url: Endpoints.getUsers,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId,
          page,
          pageSize,
          sortColumn: 'FirstName',
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: filter || {},
        }),
      },
    });
  };

  addUser = (newUser: DTO.User): Promise<any> => {
    return apiService.request({
      url: Endpoints.addUser,
      options: {
        method: 'POST',
        body: JSON.stringify(newUser),
      },
    });
  };

  getUserById = (userId: string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getUserById}${userId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getLoggedUser = (): Promise<DTO.User> => {
    return apiService.request({
      url: Endpoints.getLoggedUser,
      options: {
        method: 'GET',
      },
    });
  };

  changeUserPassword = (
    passwordUpdate: DTO.ChangeUserPassword
  ): Promise<any> => {
    return apiService.request({
      url: Endpoints.changeUserPassword,
      options: {
        method: 'PUT',
        body: JSON.stringify(passwordUpdate),
      },
    });
  };

  updateUserIsActive = (userId: string, isActive: boolean): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.updateUserIsActive}${userId}?isActive=${isActive}`,
      options: {
        method: 'PUT',
      },
    });
  };

  updateUserIsLocked = (userId: string, isLocked: boolean): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.updateUserIsLocked}${userId}?isLocked=${isLocked}`,
      options: {
        method: 'PUT',
      },
    });
  };
}

export const userService = new UserService();
