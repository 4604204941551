import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class ClientService {
  saveClient = (client: DTO.Client): Promise<any> => {
    return apiService.request({
      url: Endpoints.saveClient,
      options: {
        method: 'POST',
        body: JSON.stringify(client),
      },
    });
  };

  editClient = (client: DTO.Client): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.editClient}${client.clientId}`,
      options: {
        method: 'PUT',
        body: JSON.stringify(client),
      },
    });
  };

  deleteClient = (clientId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.deleteClient}${clientId}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  getClient = (name: string): Promise<DTO.Clients> => {
    return apiService.request({
      url: `${Endpoints.getClientsInfo}${name}`,
      options: {
        method: 'GET',
      },
    });
  };

  getClientById = (clientId: number): Promise<DTO.Clients> => {
    return apiService.request({
      url: `${Endpoints.getClientById}${clientId}`,
      options: {
        method: 'GET',
      },
    });
  };

  fetchClientsByCompany = (
    companyId: string,
    page: number,
    pageSize: number,
    searchTerm?: string,
    filter?: {}
  ): Promise<DTO.Clients> => {
    return apiService.request({
      url: Endpoints.getClients,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: companyId,
          page,
          pageSize,
          searchTerm,
          sortColumn: 'Name',
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: filter || {},
        }),
      },
    });
  };

  fetchActiveClientsByCompany = (companyId: string): Promise<DTO.Clients> => {
    return apiService.request({
      url: `${Endpoints.getActiveClients}?companyId=${companyId}`,
      options: {
        method: 'GET',
      },
    });
  };

  fetchCountries = (): Promise<DTO.Country[]> => {
    return apiService.request({
      url: Endpoints.getCountries,
      options: {
        method: 'GET',
      },
    });
  };
}

export const clientService = new ClientService();
