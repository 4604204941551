import moment from 'moment/moment';
import {
  CalendarOutlined,
  CompassOutlined,
  FlagOutlined,
  MessageOutlined,
  UserOutlined,
} from '@ant-design/icons';
import React from 'react';
import { DATE_FORMAT } from 'features/Modules/OpenPositions/Positions/AddPositions/NewPosition/helper';

const iconStyle = { color: '#1890ff' };

export const getPositionsDetails = (
  position: DTO.Position,
  countries: Array<string>
) => {
  return [
    {
      label: 'positions.viewPosition.createdBy',
      value: position.createdBy || '-',
      icon: <UserOutlined style={iconStyle} />,
    },
    {
      label: 'positions.viewPosition.creationDate',
      value: moment(position.createdAt).format(DATE_FORMAT),
      icon: <CalendarOutlined style={iconStyle} />,
    },
    {
      label: 'positions.viewPosition.startDate',
      value: moment(position.positionStartDate).format(DATE_FORMAT),
      icon: <CalendarOutlined style={iconStyle} />,
    },
    {
      label: 'positions.viewPosition.locations',
      value: countries?.join(', '),
      icon: <FlagOutlined style={iconStyle} />,
    },
    {
      label: 'positions.viewPosition.updatedBy',
      value: position.modifiedBy || '-',
      icon: <UserOutlined style={iconStyle} />,
    },
    {
      label: 'positions.viewPosition.updateDate',
      value: moment(position.modifiedAt).format(DATE_FORMAT) || '-',
      icon: <CalendarOutlined style={iconStyle} />,
    },
    {
      label: 'positions.viewPosition.status',
      value: position.isActive ? 'common.statusOpen' : 'common.statusClose',
      icon: <CompassOutlined style={iconStyle} />,
    },
    {
      label: 'positions.viewPosition.closeReason',
      value: position.reasonStatus ? position.reasonStatus.reason : '',
      icon: <MessageOutlined style={iconStyle} />,
    },
    {
      label: 'positions.viewPosition.closeDate',
      value: position.positionCloseDate
        ? moment(position.positionCloseDate).format(DATE_FORMAT)
        : '',
      icon: <CalendarOutlined style={iconStyle} />,
    },
  ];
};
