import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const DivWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const StyledText = styled.div`
  margin-top: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const DivPagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
`;

export const DivButton = styled.div`
  margin-left: auto;
  margin-right: 2vh;
`;

export const DivStatus = styled.div`
  margin-left: 2vh;
  margin-right: 3vh;
`;

export const StyledLink = styled(Link)`
  color: inherit;
`;

export const RadioButtonGroupStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const SearchOutlinedStyled = styled(SearchOutlined)<{
  filtered?: string;
}>`
  color: ${({ filtered }) =>
    filtered ? ({ theme }) => theme.colors.links : undefined};
`;

export const TableWrapper = styled.div`
  -webkit-box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
  border-radius: ${({ theme }) => theme.shape.generalBorderRadius};
  background: #ffffff;
  padding: 2vh 2vh 2vh 2vh;
  min-width: fit-content;

  div#edit-form_emailAddress_help
    .ant-form-item-explain-error:not(:last-child) {
    display: none;
  }

  table {
    min-width: 100%;
    width: max-content;
  }
`;
