import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { getWindow } from '../shared/utils/common.utils';
import { rootReducer } from './root.reducer';

const composeEnhancers =
  getWindow().__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
