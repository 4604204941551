import React from 'react';
import { TabsProps } from 'antd';
import { TabsStyled, TabPaneStyled } from './styles';

const Tabs = ({ children, ...restProps }: TabsProps) => {
  return (
    <>
      <TabsStyled {...restProps}>{children}</TabsStyled>
    </>
  );
};

Tabs.TabPane = TabPaneStyled;

export default Tabs;
