import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class ProjectService {
  getProjectsFromLeader = (userRol: string): Promise<DTO.ProjectsInfo> => {
    return apiService.request({
      url: `${Endpoints.getProjectsFromLeader}${userRol}`,
      options: {
        method: 'GET',
      },
    });
  };

  getProjectByProjectIdClientId = (
    projectId: number,
    clientId: number
  ): Promise<DTO.ProjectDetail> => {
    return apiService.request({
      url: `${Endpoints.getProjectByProjectIdClientId}${projectId}/Client/${clientId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getProjectsByClient = (clientId: number): Promise<DTO.ProjectDetail> => {
    return apiService.request({
      url: `${Endpoints.getProjectsByClient}${clientId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getProjectById = (projectId: number): Promise<DTO.ProjectsInfo> => {
    return apiService.request({
      url: `${Endpoints.getProjectById}${projectId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getProjectInfo = (projectId: number): Promise<DTO.ProjectInfo> => {
    return apiService.request({
      url: `${Endpoints.getProjectInfo}${projectId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getProjectsWhereLeader = (): Promise<DTO.ProjectsInfo> => {
    return apiService.request({
      url: Endpoints.getProjectsWhereLeader,
      options: {
        method: 'GET',
      },
    });
  };

  addProjectExternalId = (data: any): Promise<any> => {
    return apiService.request({
      url: Endpoints.addProjectExternalId,
      options: {
        method: 'POST',
        body: JSON.stringify(data),
      },
    });
  };

  editProjectExternalId = (data: any): Promise<any> => {
    return apiService.request({
      url: Endpoints.editProjectExternalId,
      options: {
        method: 'POST',
        body: JSON.stringify(data),
      },
    });
  };

  activateProject = (id: number): Promise<DTO.ProjectsInfo> => {
    return apiService.request({
      url: `${Endpoints.activateProject}/${id}`,
      options: {
        method: 'PUT',
      },
    });
  };

  inactivateProject = (id: number): Promise<DTO.ProjectsInfo> => {
    return apiService.request({
      url: `${Endpoints.inactivateProject}/${id}`,
      options: {
        method: 'PUT',
      },
    });
  };

  deleteProjectExternalId = (id: number): Promise<DTO.ProjectsInfo> => {
    return apiService.request({
      url: `${Endpoints.deleteProjectExternalId}/${id}`,
      options: {
        method: 'GET',
      },
    });
  };

  getProjectExternalIds = (
    page: number,
    pageSize: number,
    valueId: string,
    filter: {}
  ): Promise<any> => {
    return apiService.request({
      url: Endpoints.getProjectExternalIds,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId,
          page,
          pageSize,
          sortColumn: 'projectIdExternal',
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: filter || {},
        }),
      },
    });
  };

  changeActiveProjectExternalId = (
    projectExternalId: string | number,
    isActive: boolean
  ): Promise<any> => {
    const endpoint = isActive
      ? Endpoints.activeProjectExternalId
      : Endpoints.inactiveProjectExternalId;

    return apiService.request({
      url: `${endpoint}/${projectExternalId}`,
      options: {
        method: 'PATCH',
      },
    });
  };

  fetchProjectsByCompany = (
    page: number,
    pageSize: number,
    clientId: string,
    searchTerm: string,
    filter: {}
  ): Promise<DTO.Projects> => {
    return apiService.request({
      url: Endpoints.getProjects,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: clientId,
          page,
          pageSize,
          searchTerm,
          sortColumn: 'Name',
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: filter || {},
        }),
      },
    });
  };

  saveProject = (project: DTO.ProjectSave): Promise<any> => {
    return apiService.request({
      url: Endpoints.saveProject,
      options: {
        method: 'POST',
        body: JSON.stringify(project),
      },
    });
  };

  editProject = (project: DTO.Project): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.editProject}${project.projectId}`,
      options: {
        method: 'PUT',
        body: JSON.stringify(project),
      },
    });
  };

  deleteProject = (projectId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.deleteProject}${projectId}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  getAllProjectsByCompanyId = (): Promise<any> => {
    return apiService.request({
      url: Endpoints.getAllProjectsByCompanyId,
      options: {
        method: 'GET',
      },
    });
  };

  getAllProjectByAdminCompany = (): Promise<any> => {
    return apiService.request({
      url: Endpoints.getAllProjectByAdminCompany,
      options: {
        method: 'GET',
      },
    });
  };

  getAllProjectByReviewer = (): Promise<any> => {
    return apiService.request({
      url: Endpoints.getAllProjectByReviewer,
      options: {
        method: 'GET',
      },
    });
  };

  getProjectArea = (): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getProjectArea}`,
      options: {
        method: 'GET',
      },
    });
  };

  enableMainProjectFromProjects = (data: DTO.MainProject): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.enableMainProjectPersonFromProject}`,
      options: {
        method: 'POST',
        body: JSON.stringify(data),
      },
    });
  };

  enableMainProjectFromPeople = (data: DTO.MainProject): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.enableMainProjectPersonFromPeople}`,
      options: {
        method: 'POST',
        body: JSON.stringify(data),
      },
    });
  };

  disableMainProjectFromProjects = (data: DTO.MainProject): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.disableMainProjectPersonFromProject}`,
      options: {
        method: 'POST',
        body: JSON.stringify(data),
      },
    });
  };

  disableMainProjectFromPeople = (data: DTO.MainProject): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.disableMainProjectPersonFromPeople}`,
      options: {
        method: 'POST',
        body: JSON.stringify(data),
      },
    });
  };

  isLastAssignation = (projectId: string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.isProjectEditable}${projectId}`,
      options: {
        method: 'GET',
      },
    });
  };
}

export const projectService = new ProjectService();
