import styled from 'styled-components';

export const DivContainer = styled.div``;

export const TableStyled = styled.table<{ timesheet?: boolean }>`
  width: 100%;

  tr {
    height: ${({ timesheet }) => (timesheet ? '60px' : '50px')};
  }
`;

export const TheadStyled = styled.thead`
  border-bottom: 1px solid ${({ theme }) => theme.colors.outlines} !important;
  background-color: ${({ theme }) => theme.colors.headerBackground};
`;

export const TrStyled = styled.tr<{ border?: boolean }>`
  ${({ border }) => (border ? `border-top: 1px solid #f0f0f0;` : '')}
`;

export const ThStyled = styled.th<{
  width: string;
  alignment?: string;
  padding?: boolean;
}>`
  ${({ padding }) => (padding ? 'padding: 15px 20px;' : '')}
  text-align: ${({ alignment }) => alignment || 'center'};
  color: ${({ theme }) => theme.colors.secondaryText};
  width: ${({ width }) => width};
`;

export const TdStyled = styled.td<{
  alignment?: string;
  padding?: boolean;
}>`
  ${({ padding }) => (padding ? 'padding: 15px 20px;' : '')}
  text-align: ${({ alignment }) => alignment || 'center'};

  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const IconWrapper = styled.div`
  margin-left: 8px;
  display: inline-block;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.links};
  }
`;

export const TitleMultiline = styled.div`
  white-space: pre;
  color: black;
`;

export const TableWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  border-radius: ${({ theme }) => theme.shape.generalBorderRadius};
  background: #ffffff;
`;
