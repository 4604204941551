import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class CompanyService {
  getCompaniesByUser = (userId: string): Promise<DTO.UserCompanies> => {
    return apiService.request({
      url: `${Endpoints.getCompaniesByUser}/${userId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getCompanies = (
    page: number,
    pageSize: number,
    searchTerm: string,
    filter: {}
  ): Promise<DTO.Companies> => {
    return apiService.request({
      url: Endpoints.getCompanies,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: '',
          page,
          pageSize,
          searchTerm,
          sortColumn: 'Name',
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: filter || {},
        }),
      },
    });
  };

  addCompany = (company: DTO.Company): Promise<DTO.Company> => {
    return apiService.request({
      url: Endpoints.AddCompany,
      options: {
        method: 'POST',
        body: JSON.stringify(company),
      },
    });
  };

  updateCompany = (company: DTO.Company): Promise<DTO.Company> => {
    return apiService.request({
      url: Endpoints.UpdateCompany,
      options: {
        method: 'POST',
        body: JSON.stringify(company),
      },
    });
  };

  addCompanySettings = (company: DTO.Company): Promise<any> => {
    return apiService.request({
      url: Endpoints.UpdateCompanySettings,
      options: {
        method: 'POST',
        body: JSON.stringify(company),
      },
    });
  };

  updateCompanySettings = (company: DTO.Company): Promise<any> => {
    return apiService.request({
      url: Endpoints.UpdateCompanySettings,
      options: {
        method: 'PUT',
        body: JSON.stringify(company),
      },
    });
  };

  getCompanySettings = (companyId: string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getCompanySettings}/${companyId}`,
      options: {
        method: 'GET',
      },
    });
  };

  addCompanySetting = (addSetting: {
    key: string;
    value: string;
    companyId: number;
  }): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.addCompanySetting}`,
      options: {
        method: 'POST',
        body: JSON.stringify(addSetting),
      },
    });
  };

  editCompanySetting = (updateSetting: {
    id: number;
    key: string;
    value: string;
    companyId: number;
    isActive: boolean;
    isDeleted: boolean;
  }): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.updateCompanySetting}`,
      options: {
        method: 'PUT',
        body: JSON.stringify(updateSetting),
      },
    });
  };

  updateCompanyIsActive = (
    companyId: number,
    isActive: boolean
  ): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.updateCompanyIsActive}${companyId}?isActive=${isActive}`,
      options: {
        method: 'PUT',
      },
    });
  };

  getCompanySetting = (
    settingName: string
  ): Promise<DTO.ActionResult<DTO.CompanySetting>> => {
    return apiService.request({
      url: `${Endpoints.getCompanySetting}/${settingName}`,
      options: {
        method: 'GET',
      },
    });
  };

  deleteCompany = (companyId: string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.deleteCompany}/${companyId}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  getCompanyById = (companyId: string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getCompanyById}/${companyId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getAllCompanySettings = (
    companyId: number,
    page: number,
    pageSize: number,
    searchTerm: string,
    filter: any
  ): Promise<DTO.GridResults<any>> => {
    const finalFilter = filter || {};
    finalFilter.companyId = companyId.toString();
    return apiService.request({
      url: Endpoints.getAllCompanySettings,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: '',
          page,
          pageSize,
          searchTerm,
          sortColumn: 'Name',
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: finalFilter,
        }),
      },
    });
  };

  deleteCompanySetting = (
    companySettingId: number,
    companyId: number
  ): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.deleteCompanySetting}?companySettingId=${companySettingId}&companyId=${companyId}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  updateCompanySettingIsActive = (
    companySettingId: number,
    isActive: boolean
  ): Promise<any> => {
    return apiService.request({
      url: isActive
        ? Endpoints.activeCompanySetting
        : Endpoints.inactiveCompanySetting,
      options: {
        method: 'PATCH',
        body: companySettingId?.toString(),
      },
    });
  };

  setCompanySecurityKey = (companyId: number, value: string): Promise<void> => {
    return apiService.request({
      url: `${Endpoints.companySecurityKey}`,
      options: {
        method: 'POST',
        body: JSON.stringify({ companyId, value }),
      },
    });
  };

  getCompanySecurityKey = (
    companyId: number
  ): Promise<DTO.ActionResult<boolean>> => {
    return apiService.request({
      url: `${Endpoints.companySecurityKey}/${companyId}`,
      options: {
        method: 'GET',
      },
    });
  };
}

export const companyService = new CompanyService();
