import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class AbsenceService {
  getAllAbsences = (
    page: number,
    pageSize: number,
    startDate: string,
    endDate: string,
    statusId: DTO.Nullable<number>,
    filter: Object
  ): Promise<DTO.Absences> => {
    const idPerson = 0;
    return apiService.request({
      url: `${Endpoints.getAllAbsences}/${idPerson}/${startDate}/${endDate}${
        statusId ? `/${statusId}` : ''
      }`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          page,
          pageSize,
          sortDirection: 'desc',
          filter,
        }),
      },
    });
  };

  addAbsence = (newAbsence: DTO.AddAbsence): Promise<any> => {
    return apiService.request({
      url: Endpoints.addAbsence,
      options: {
        method: 'POST',
        body: JSON.stringify(newAbsence),
      },
    });
  };

  getSingleAbsence = (absenceId: number): Promise<DTO.SingleAbsence> => {
    return apiService.request({
      url: `${Endpoints.getSingleAbsence}/${absenceId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getAttachment = (path: string): Promise<string> => {
    return apiService.request({
      url: `${Endpoints.getAttachment}`,
      options: {
        method: 'POST',
        body: `"${path}"`,
      },
    });
  };

  updateComments = (
    absenceId: number,
    comments: string
  ): Promise<DTO.SingleAbsence> => {
    return apiService.request({
      url: Endpoints.updateAbsenceComments,
      options: {
        method: 'POST',
        body: JSON.stringify({
          absenceId,
          comments,
        }),
      },
    });
  };

  editAbsence = (
    updatedAbsence: DTO.EditAbsence
  ): Promise<DTO.SingleAbsence> => {
    return apiService.request({
      url: Endpoints.editAbsence,
      options: {
        method: 'PUT',
        body: JSON.stringify(updatedAbsence),
      },
    });
  };

  cancelAbsence = (idAbsence: number | string): Promise<DTO.Absences> => {
    return apiService.request({
      url: `${Endpoints.cancelAbsence}?idAbsence=${idAbsence}`,
      options: {
        method: 'PUT',
      },
    });
  };

  editAbsenceAsReviewer = (
    updatedAbsence: DTO.EditAbsence
  ): Promise<DTO.SingleAbsence> => {
    return apiService.request({
      url: Endpoints.editAbsenceAsReviewer,
      options: {
        method: 'PUT',
        body: JSON.stringify(updatedAbsence),
      },
    });
  };

  cancelAbsenceAsReviewer = (props: {
    id: number | string;
    comments: string;
  }): Promise<DTO.Absences> => {
    return apiService.request({
      url: `${Endpoints.cancelAbsenceAsReviewer}`,
      options: {
        method: 'PUT',
        body: JSON.stringify(props),
      },
    });
  };

  getAllAbsensesAsLeader = (
    page: number,
    pageSize: number,
    startDate: string,
    endDate: string,
    projectId: DTO.Nullable<string>,
    statusId: DTO.Nullable<number>,
    filter: Object
  ): Promise<DTO.AbsenceDetail> => {
    return apiService.request({
      url: `${
        Endpoints.getAbsensesByStatusProjectAndDates
      }${startDate}/${endDate}${statusId ? `/${statusId}` : ''}`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: `${projectId ? `${projectId}` : ''}`,
          page,
          pageSize,
          sortDirection: 'desc',
          filter,
        }),
      },
    });
  };

  getAllAbsensesAsReviewer = (
    page: number,
    pageSize: number,
    startDate: string,
    endDate: string,
    projectId: DTO.Nullable<string>,
    statusId: DTO.Nullable<number>,
    filter: Object
  ): Promise<DTO.AbsenceDetail> => {
    return apiService.request({
      url: `${Endpoints.getAllAbsensesAsReviewer}${startDate}/${endDate}${
        statusId ? `/${statusId}` : ''
      }`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: `${projectId ? `${projectId}` : ''}`,
          page,
          pageSize,
          sortDirection: 'desc',
          filter,
        }),
      },
    });
  };
}

export const absenceService = new AbsenceService();
