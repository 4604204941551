import { FormModes } from 'shared/constants/common.const';

export const enum TabKey {
  personalInformation = 'personal-information',
}

export const DEFAULT_TAB = TabKey.personalInformation;

export interface UrlParams {
  mode: FormModes;
}
