import { i18Service } from 'shared/services';
import { AppDispatch } from 'store/root.reducer';
import {
  SET_LOCALES,
  SET_CURRENT_LOCAL,
  SetLocalesAction,
  SetCurrentLocalAction,
} from './locale.types';
import { sortLocales } from './locale.helpers';

export const setLocalesData = (data: DTO.Locale[]): SetLocalesAction => {
  return { type: SET_LOCALES, payload: data };
};

export const setCurrentLocalData = (
  data: DTO.Locale
): SetCurrentLocalAction => {
  return { type: SET_CURRENT_LOCAL, payload: data };
};

export const loadLocales = () => (dispatch: AppDispatch) => {
  i18Service
    .fetchLocales()
    .then((locales) => {
      dispatch(setLocalesData(sortLocales(locales)));
    })
    .catch(() => {
      console.error('Could not fetch locales');
    });
};
