import { Typography } from 'antd';
import styled from 'styled-components';

export const TitleStyled = styled(Typography)`
  margin-bottom: 25px;
  font-size: 20px;
  color: #252733;
  font-weight: bold;
`;

export const SkillTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.links};
  margin-bottom: 25px;
  margin-top: 25px;
`;

export const TopForm = styled.div`
  margin-top: 25px;
  margin-right: 25px;
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  column-gap: 30px;

  & textarea {
    resize: none;
  }

  & .ant-row {
    flex-direction: column;
  }
  & label {
    display: block;
    margin-right: auto;
    text-align: left;
  }
  & .ant-form-item {
    flex-basis: 30%;
    & * {
      max-width: 100% !important;
    }
  }
  & .ant-picker {
    width: 100%;
  }
`;

export const MiddleForm = styled.div`
  margin-right: 25px;
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  column-gap: 30px;

  & textarea {
    resize: none;
  }

  & .ant-row {
    flex-direction: column;
  }
  & label {
    display: block;
    margin-right: auto;
    text-align: left;
  }
  & .ant-form-item {
    flex-basis: 30%;
    & * {
      max-width: 100% !important;
    }
  }
  & .ant-picker {
    width: 100%;
  }
`;

export const BottomForm = styled.div`
  margin-right: 50px;

  & textarea {
    resize: none;
  }

  & .ant-row {
    flex-direction: column;
  }
  & label {
    display: block;
    margin-right: auto;
    text-align: left;
  }
  & .ant-form-item {
    flex-basis: 30%;
    & * {
      max-width: 100% !important;
    }
  }
  & .ant-picker {
    width: 100%;
  }
`;

export const ButtonsWrapper = styled.section`
  margin: 10px 50px 50px auto;
  text-align: center;
  display: flex;
  justify-content: end;
  flex-basis: 100%;
  column-gap: 30px;
`;

export const OptionButton = styled.button`
  appearance: none;
  background: none;
  border: none;
`;

export const DivContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin: 0 0 40px 0;
  width: 100%;
  h1 {
    margin: 0;
  }
`;

export const DivWrapper = styled.div`
  width: auto;
  display: flex;
  margin: 15px 0;
`;

export const ButtonWrapper = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-end;
  margin: 15px 24px;
  button {
    margin: 50px 4px 0 4px;
  }
`;

export const FormField = styled.div`
  width: 100%;
`;

export const FormSection = styled.h2`
  margin-top: 24px;
  color: ${({ theme }) => theme.colors.blue};
  font-size: 24px;
  font-weight: bold;
`;

export const TitleName = styled(Typography)`
  font-size: 22px;
  margin-left: 20px;
  color: ${({ theme }) => theme.colors.blue};
`;
export const Title = styled(Typography)`
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 20px;
`;
export const Separator = styled(Typography)`
  margin-bottom: 20px;
  margin-left: 20px;
  font-size: 22px;
  color: #808080;
`;
