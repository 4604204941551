import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class BillingService {
  getCurrencies = (): Promise<DTO.ActionResult<DTO.Catalogue>> => {
    return apiService.request({
      url: Endpoints.getCurrencies,
      options: {
        method: 'GET',
      },
    });
  };

  getBillingPeriods = (): Promise<DTO.ActionResult<DTO.Catalogue>> => {
    return apiService.request({
      url: Endpoints.getBillingPeriod,
      options: {
        method: 'GET',
      },
    });
  };

  addBilling = (
    data: DTO.AddBilling
  ): Promise<DTO.ActionResult<DTO.AddBilling>> => {
    return apiService.request({
      url: Endpoints.addBilling,
      options: {
        method: 'POST',
        body: JSON.stringify(data),
      },
    });
  };

  editBilling = (data: DTO.BillingInvoice): Promise<void> => {
    return apiService.request({
      url: Endpoints.editBilling,
      options: {
        method: 'PUT',
        body: JSON.stringify(data),
      },
    });
  };

  deleteInvoice = (id: DTO.BillingInvoice['id']): Promise<void> => {
    return apiService.request({
      url: `${Endpoints.deleteBillingInvoice}/${id}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  getInvoice = (id: number): Promise<DTO.ActionResult<DTO.BillingInvoice>> => {
    return apiService.request({
      url: `${Endpoints.getBillingInvoice}/${id}`,
      options: {
        method: 'GET',
      },
    });
  };

  getPreviousAndNextInvoice = (
    employmentDataId: number,
    invoiceId: number
  ): Promise<DTO.ActionResult<DTO.nextAndPreviousBillingInvoice>> => {
    return apiService.request({
      url: `${Endpoints.getNextAndPreviousBillingInvoice}/${employmentDataId}/${invoiceId}`,
      options: {
        method: 'GET',
      },
    });
  };
}

export const billingService = new BillingService();
