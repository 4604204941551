import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Endpoints } from 'shared/constants/endpoints.const';
import { DEFAULT_LOCALE, CURRENT_LOCALE } from 'shared/constants/common.const';
import { setToLocalStorage } from 'shared/utils/storage.utils';
import enLocale from 'locales/enLocale.json';
import { apiService } from './api.service';

class I18Service {
  init = (initialLanguage: string) => {
    i18n.use(initReactI18next).init({
      resources: {
        en: {
          translation: enLocale,
        },
      },
      lng: initialLanguage,
      fallbackLng: DEFAULT_LOCALE.uiCulture,
      interpolation: {
        escapeValue: false,
      },
      nsSeparator: false,
    });
    return i18n;
  };

  onChangeLocaleBundle = async (bundle: any, localeCode?: string | null) => {
    const locale = localeCode || DEFAULT_LOCALE.uiCulture;
    i18n.addResourceBundle(locale, 'translation', bundle);
    setToLocalStorage(CURRENT_LOCALE, locale);
    await i18n.changeLanguage(locale);
  };

  setDefaultLocale = () => {
    i18n.changeLanguage(DEFAULT_LOCALE.uiCulture);
    setToLocalStorage(CURRENT_LOCALE, DEFAULT_LOCALE.uiCulture);
  };

  fetchLocales = (): Promise<DTO.Locale[]> => {
    return apiService.request({ url: Endpoints.locales });
  };

  fetchLocaleById = (id: number): Promise<DTO.Translation[]> => {
    const url = `${Endpoints.localById}${id}`;
    return apiService.request({ url });
  };
}

export const i18Service = new I18Service();
