import React, { FC, memo, PropsWithChildren } from 'react';
import { ModalProps as AntdModalProps } from 'antd';
import { ModalStyled } from './styles';

const Modal: FC<PropsWithChildren<AntdModalProps>> = ({
  children,
  ...restProps
}): JSX.Element => {
  return <ModalStyled {...restProps}> {children} </ModalStyled>;
};

export default memo(Modal);
