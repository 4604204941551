import React, { FC, memo, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Space, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Button } from 'components/Button';
import { ColumnConfig } from './helpers';
import {
  IconWrapper,
  TableStyled,
  TheadStyled,
  TrStyled,
  ThStyled,
  TdStyled,
  TitleMultiline,
  TableWrapper,
} from './styles';

interface Props {
  timesheet?: boolean;
  colsConfig: ColumnConfig[];
  filterAction?: (
    page: number,
    size: number,
    searchTerm?: string,
    filter?: {}
  ) => void;
}

const Table: FC<PropsWithChildren<Props>> = ({
  children,
  timesheet,
  colsConfig,
  filterAction,
}): JSX.Element => {
  const [showSearch, setShowSearch] = useState({ visible: false, field: '' });
  const [actualSearch, setActualSearch] = useState('');
  const { t } = useTranslation();
  return (
    <TableWrapper>
      {' '}
      <TableStyled timesheet={timesheet}>
        <TheadStyled>
          <TrStyled>
            {colsConfig.map((col) => (
              <ThStyled
                key={col.key}
                width={col.width}
                alignment={col.alignment || undefined}
                padding={col.padding || false}
              >
                <TitleMultiline>
                  {typeof col.title === 'string' ? t(col.title) : col.title}
                </TitleMultiline>
                {col.month && col.date && (
                  <>
                    <br />
                    <Typography.Text>
                      {t(col.month).slice(0, 3)}. {t(col.date)}
                    </Typography.Text>
                  </>
                )}
                {col.search && (
                  <IconWrapper
                    onClick={() =>
                      setShowSearch((prev) => {
                        return { visible: !prev.visible, field: col.key };
                      })
                    }
                  >
                    <SearchOutlined />
                  </IconWrapper>
                )}
                {showSearch.visible && col.key === showSearch.field && (
                  <div style={{ padding: 8 }}>
                    <Input
                      style={{ marginBottom: 8, display: 'block' }}
                      placeholder="search"
                      value={actualSearch}
                      onChange={(e) => {
                        setActualSearch(e.currentTarget.value);
                      }}
                    />
                    <Space>
                      <Button
                        onClick={() => {
                          const obj = { [col.key]: actualSearch };
                          if (filterAction)
                            filterAction(1, 50, actualSearch, obj);
                        }}
                        icon={<SearchOutlined />}
                        type="primary"
                      >
                        Search
                      </Button>
                      <Button
                        type="link"
                        onClick={() => {
                          setActualSearch('');
                          setShowSearch({ visible: false, field: '' });
                          if (filterAction) filterAction(1, 10, '');
                        }}
                      >
                        Reset
                      </Button>
                    </Space>
                  </div>
                )}
              </ThStyled>
            ))}
          </TrStyled>
        </TheadStyled>

        <tbody>{children}</tbody>
      </TableStyled>
    </TableWrapper>
  );
};

export const TableTr = TrStyled;
export const TableTd = TdStyled;

export default memo(Table);

Table.defaultProps = {
  timesheet: false,
  filterAction: undefined,
};
