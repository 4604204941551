import React, { createRef, useCallback, useState } from 'react';
import {
  Editor as EditorComponent,
  EditorState as EditorStateWy,
  RawDraftContentState,
} from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { EditorWrapper, ErrorWrapper, TextAreaBottomText } from './styles';

interface Props {
  editorState?: EditorStateWy;
  label: string;
  id?: string;
  onEditorStateChange?: (editorState: EditorStateWy) => void;
  rules?: any[];
  maxLength: number;
}
const Editor = (props: Props) => {
  const ref = createRef<EditorComponent>();
  const { t, i18n } = useTranslation();
  const [, setContent] = useState<RawDraftContentState>();
  const { rules, maxLength, label, id, editorState, onEditorStateChange } =
    props;

  const getLength = useCallback(() => {
    return editorState?.getCurrentContent().getPlainText().length || 0;
  }, [editorState]);

  const isRequired = useCallback(
    (): { message: string } | undefined => rules?.find((rule) => rule.required),
    [rules]
  );
  const onSetEditorState = useCallback(
    (state: EditorStateWy) => {
      const text = state.getCurrentContent().getPlainText();
      if (text === '' || text.trim() !== '') {
        if (onEditorStateChange) {
          onEditorStateChange(state);
        }
      }
      return false;
    },
    [onEditorStateChange]
  );

  const hasRequiredError = useCallback(
    (required: { message: string }) => {
      const text = editorState?.getCurrentContent().getPlainText();
      if (text !== undefined && !text) {
        return required.message;
      }
      return '';
    },
    [editorState]
  );

  const hasMinError = useCallback(
    (required: { message: string; min: number }) => {
      const text = editorState?.getCurrentContent().getPlainText();
      if (text !== undefined && text.length < required.min) {
        return required.message;
      }
      return null;
    },
    [editorState]
  );

  const hasMaxError = useCallback(
    (required: { message: string; max: number }) => {
      const text = editorState?.getCurrentContent().getPlainText();
      if (text !== undefined && text?.length > required.max) {
        return required.message;
      }
      return null;
    },
    [editorState]
  );

  const validateErrors = useCallback(() => {
    const required = isRequired();
    const min = rules?.find((rule) => rule.min !== undefined);
    const max = rules?.find((rule) => rule.max !== undefined);
    let error: string | null = null;
    if (required) {
      error = hasRequiredError(required);
      if (error) {
        return error;
      }
    }
    if (min) {
      error = hasMinError(min);
      if (error) {
        return error;
      }
    }
    if (max) {
      error = hasMaxError(max);
      if (error) {
        return error;
      }
    }
    return null;
  }, [isRequired, hasMaxError, hasMinError, hasRequiredError, rules]);

  return (
    <>
      <div className="ant-col ant-col-24 ant-form-item-label">
        <label
          htmlFor={id}
          className={isRequired() ? 'ant-form-item-required' : ''}
          title={label}
        >
          {t(label)}
        </label>
      </div>
      <EditorWrapper className={validateErrors() ? 'error' : ''}>
        <EditorComponent
          ref={ref}
          onContentStateChange={setContent}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontFamily',
              'list',
              'textAlign',
              'link',
              'history',
            ],
            inline: { inDropdown: true },
            blockType: { inDropdown: true },
            fontFamily: { inDropdown: true },
            list: { inDropdown: true, options: ['unordered', 'ordered'] },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
          locale={i18n.language.split('-')[0]}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onSetEditorState}
        />
      </EditorWrapper>
      <ErrorWrapper>
        <div
          id={id}
          className="ant-form-item-explain ant-form-item-explain-connected"
          role="alert"
        >
          <TextAreaBottomText>
            <div className="ant-form-item-explain-error">
              {validateErrors()}
            </div>
            <div>
              {getLength()} / {maxLength}
            </div>
          </TextAreaBottomText>
        </div>
      </ErrorWrapper>
    </>
  );
};

export default Editor;

Editor.defaultProps = {
  editorState: undefined,
  onEditorStateChange: undefined,
  id: undefined,
  rules: [],
};
