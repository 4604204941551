import { PublicClientApplication } from '@azure/msal-browser';
import AppSettingsService from './appsettings.service';

export const msalConfig = async (): Promise<DTO.MsalConfig> => {
  const appSettings = new AppSettingsService();
  if (!window.APP_CONFIG) {
    await appSettings.loadResources();
  }
  return {
    auth: {
      clientId: appSettings.GetMsalClientId(),
      authority: appSettings.GetMsalTenantAuthorityUri(),
      redirectUri: appSettings.GetLoginRedirectUri(),
    },
    cache: {
      cacheLocation: appSettings.GetMsalCacheLocation(),
      storeAuthStateInCookie: appSettings.GetMsalStoreAuthInCookie(),
    },
  };
};

export const msalApplication = async (): Promise<PublicClientApplication> => {
  return new PublicClientApplication(await msalConfig());
};
