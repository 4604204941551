import {
  Options,
  FormItemConfig,
  FieldData,
  REGEXP,
  FormItemType,
} from 'components/Form/helpers';

export const SPAN_COL_HALF = 11;

export const SPAN_COL_FULL = 24;

export type PersonFields = {
  firstName: string;
  lastName: string;
  countryId: string;
  userName: string;
};

export const getClientDataFields = (
  countries: Options[],
  t: Function
): FormItemConfig[] => {
  return [
    {
      name: 'emailAddress',
      label: 'common.emailAddress',
      type: FormItemType.email,
      placeholder: 'timetracker.people.typeEmailAddress',
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
        { max: 60, message: t('common.fieldRangeValue60') },
        {
          pattern: new RegExp(REGEXP.EMAIL),
          message: t('common.emailAddressNotValid'),
        },
        {
          pattern: new RegExp(REGEXP.AT_LEAST_ONE_LETTER_EMAIL),
          message: t('common.emailAddressNotValid'),
        },
      ],
    },
    {
      name: 'firstName',
      label: 'timetracker.name',
      type: FormItemType.text,
      disabled: true,
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
        { max: 90, message: t('common.fieldRangeValue30') },
        {
          pattern: new RegExp(REGEXP.ALPHANUMERIC_SPACE),
          message: t('common.fieldInvalidCharacters'),
        },
      ],
    },
    {
      name: 'lastName',
      label: 'timetracker.people.firstLastName',
      type: FormItemType.text,
      disabled: true,
      rules: [
        { required: true, message: t('common.fieldIsRequired') },
        { max: 90, message: t('common.fieldRangeValue90') },
        { type: 'string' },
        {
          whitespace: true,
          message: t('timetracker.people.namesEmpty'),
        },
        {
          pattern: new RegExp(REGEXP.ALPHANUMERIC_SPACE),
          message: t('common.fieldInvalidCharacters'),
        },
      ],
    },
    {
      name: 'countryId',
      label: 'common.country',
      type: FormItemType.select,
      options: countries,
      disabled: true,
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
      ],
    },
    {
      name: 'userName',
      label: 'common.userName',
      type: FormItemType.text,
      disabled: true,
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
        { max: 90, message: t('common.fieldRangeValue30') },
        {
          pattern: new RegExp(REGEXP.EMAIL),
          message: t('common.fieldInvalidCharacters'),
        },
        {
          pattern: new RegExp(REGEXP.AT_LEAST_ONE_LETTER_EMAIL),
          message: t('common.fieldInvalidCharacters'),
        },
      ],
    },
    {
      name: 'password',
      label: 'loginPage.password',
      type: FormItemType.password,
      placeholder: 'timetracker.users.typePasswordPlaceholder',
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
        {
          pattern: new RegExp(REGEXP.PASSWORD),
          message: t('timetracker.users.validateNewPassword'),
        },
      ],
    },
    {
      name: 'confirmPassword',
      label: 'timetracker.users.confirmPassword',
      type: FormItemType.password,
      placeholder: 'timetracker.users.confirmPassword',
      dependencies: ['password'],
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
        ({ getFieldValue }: any) => ({
          validator(_: any, value: any) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error(t('timetracker.users.typePassword'))
            );
          },
        }),
      ],
    },
  ];
};

export const getPersonFields = (person: DTO.Person): PersonFields => {
  const personFields: PersonFields = {
    firstName: '',
    lastName: '',
    countryId: '',
    userName: '',
  };
  personFields.firstName = person.name;
  personFields.lastName = person.firstLastName;
  personFields.countryId = person.countryId;
  personFields.userName = person.emailAdress;
  return personFields;
};

export const getFieldData = (client: DTO.Client): FieldData[] => {
  const fieldData: FieldData[] = [];
  Object.keys(client).forEach((key) => {
    fieldData.push({ name: key, value: client.name });
  });
  return fieldData;
};

export const sortOptions = (options: Options[]): Options[] => {
  return options.sort((optionA, optionB) => {
    const nameA = optionA.name;
    const nameB = optionB.name;

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const getCountryOptions = (
  countries: DTO.Country[],
  t: Function
): Options[] => {
  const countryOptions: Options[] = countries.map((country) => {
    return { value: country.countryId, name: t(country.name) } as Options;
  });
  return sortOptions(countryOptions);
};
