import { Input } from 'antd';
import styled from 'styled-components';

const { Search } = Input;

export const StyledHelperWrapper = styled.div`
  margin-top: 2vh;
  position: absolute;
  right: 0;
  background-color: ${({ theme }) => theme.colors.mainColor};
  padding: 1rem;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  stroke-width: 50;
  stroke: ${({ theme }) => theme.colors.white};
  z-index: 99999;
`;

export const StyledSupportWrapper = styled.div`
  margin-top: 8vh;
  position: absolute;
  right: 0;
  background-color: ${({ theme }) => theme.colors.mainColor};
  padding: 1rem;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  stroke-width: 50;
  stroke: ${({ theme }) => theme.colors.white};
  z-index: 99999;
`;

export const StyledManualHeader = styled.div`
  color: ${({ theme }) => theme.colors.mainColor};
  font-weight: bold;
  padding-bottom: 1rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.horizontalLine}`};
`;

export const StyledHr = styled.hr`
  border-top: ${({ theme }) => `0.01px solid ${theme.colors.inputBorder}`};
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTitle = styled.div`
  margin-left: 0.5rem;
  font-weight: bold;
  font-size: 16px;
`;

export const StyledTitleTag = styled.div`
  font-size: 12px;
  padding: 1rem;
  margin-left: 0.3rem;
`;

export const StyledSearch = styled(Search)`
  border: none;
  margin-bottom: 2rem;
`;

export const StyledUnorderedList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;
export const StyledManualLink = styled.li`
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.horizontalLine}`};
  font-size: 12px;
`;

export const StyledManualAnchor = styled.a`
  color: ${({ theme }) => theme.colors.black};
`;
