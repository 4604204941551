import { RefreshButton } from 'components/Button/RefreshButton';
import styled from 'styled-components';

export const FilterContainer = styled.div`
  background-color: #eff2f7;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 18px 22px 12px 22px;
  display: flex;
  gap: 24px;
`;

export const RefreshButtonStyled = styled(RefreshButton)`
  max-height: 2rem;
  padding: 0;
  aspect-ratio: 1;
`;
