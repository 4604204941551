import { Badge, Button } from 'antd';
import HeaderDropdown from 'components/HeaderDropdown';
import { TIMETRACKER_PATH } from 'features/Routes/helpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { theme } from 'theme';
import { NoticeIconProps } from './helpers';
import NoticeList from './NoticeList';
import {
  BellStyled,
  ButtonStyled,
  DivContainer,
  HeaderContainer,
  Title,
} from './styles';

const NoticeIcon: React.FC<NoticeIconProps> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [visible, setVisible] = useState<boolean>(false);
  const openTable = () => {
    history.push(TIMETRACKER_PATH.NOTIFICATIONS);
    setVisible(false);
  };
  const getNotificationBox = (): React.ReactNode => {
    const { onClear, onItemClick, onCancelNotification, list } = props;
    return (
      <>
        <HeaderContainer>
          <Title>{t('timetracker.notifications.title')}</Title>
          <Button
            disabled={!list || list.length === 0}
            type="link"
            onClick={() => onClear && onClear()}
          >
            <u>{t('timetracker.notifications.markAllAsRead')}</u>
          </Button>
        </HeaderContainer>
        <NoticeList
          data={list}
          onCancelNotification={(id): void =>
            onCancelNotification && onCancelNotification(id)
          }
          onClick={(item): void => onItemClick && onItemClick(item)}
        />
        <DivContainer>
          <ButtonStyled
            type="primary"
            disabled={!list || list.length === 0}
            onClick={openTable}
          >
            {t('timetracker.notifications.viewAll')}
          </ButtonStyled>
        </DivContainer>
      </>
    );
  };

  const { count, bell } = props;

  const notificationBox = getNotificationBox();
  const NoticeBellIcon = bell || <BellStyled />;
  const trigger = (
    <Badge style={{ cursor: 'pointer' }} size="small" count={count}>
      {NoticeBellIcon}
    </Badge>
  );
  if (!notificationBox) {
    return trigger;
  }

  return (
    <HeaderDropdown
      placement="bottomRight"
      overlay={notificationBox}
      trigger={['click']}
      open={visible}
      onOpenChange={setVisible}
      overlayStyle={{
        width: '450px',
        backgroundColor: theme.colors.white,
        padding: '25px',
        borderRadius: '10px',
        boxShadow: '0px 0px 15px 6px rgba(0,0,0,0.1)',
      }}
    >
      {trigger}
    </HeaderDropdown>
  );
};

export default NoticeIcon;
