import styled from 'styled-components';
import { Pagination } from 'antd';

export const StyledPagination = styled(Pagination)`
  button {
    border-radius: ${({ theme }) => theme.shape.generalBorderRadius};
  }
`;

export const DivPagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
`;
