import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class EducationService {
  getById = (personId: number): Promise<DTO.EducationResponse> => {
    return apiService.request({
      url: `${Endpoints.getEducationById}/${personId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getByUserId = (): Promise<DTO.EducationResponse> => {
    return apiService.request({
      url: `${Endpoints.getEducationByUserId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getType = (): Promise<any> => {
    return apiService.request({
      url: Endpoints.getEducationType,
      options: {
        method: 'GET',
      },
    });
  };

  getStatus = (): Promise<any> => {
    return apiService.request({
      url: Endpoints.getEducationStatus,
      options: {
        method: 'GET',
      },
    });
  };

  addEducation = (education: DTO.AddEducation): Promise<any> => {
    return apiService.request({
      url: Endpoints.addEducation,
      options: {
        method: 'POST',
        body: JSON.stringify(education),
      },
    });
  };

  add = (education: DTO.AddEducation): Promise<DTO.ActionResult<unknown>> => {
    return apiService.request({
      url: Endpoints.AddEducationByUser,
      options: {
        method: 'POST',
        body: JSON.stringify(education),
      },
    });
  };

  deleteEducation = (educationId: number | undefined): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.deleteEducation}/${educationId}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  deleteEducationByOwner = (educationId: number | undefined): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.deleteEducationByOwner}/${educationId}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  editEducation = (edu: DTO.Education): Promise<any> => {
    return apiService.request({
      url: Endpoints.editEducation,
      options: {
        method: 'PUT',
        body: JSON.stringify(edu),
      },
    });
  };

  editEducationByUser = (edu: DTO.Education): Promise<any> => {
    return apiService.request({
      url: Endpoints.editEducationByUserId,
      options: {
        method: 'PUT',
        body: JSON.stringify(edu),
      },
    });
  };
}

export const educationService = new EducationService();
