import styled from 'styled-components';
import { Layout, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { TIMETRACKER_PATH } from 'features/Routes/helpers';

export const LayoutStyled = styled(Layout)`
  min-width: 802px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.background};

  li:has(> span a[href='${TIMETRACKER_PATH.NOTIFICATIONS}']) {
    display: none !important;
  }

  li.ant-menu-item {
    white-space: normal;
    display: flex;
    align-items: center;
  }
`;

export const LayoutHeader = styled(Layout.Header)`
  width: 100%;
  height: 85px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  background: ${({ theme }) => theme.colors.white};

  span:not(.ant-scroll-number-only):not(.ant-scroll-number-only-unit):not(.anticon-bell) {
    margin-right: 15px;
  }

  h3 {
    margin: 0;
    font-size: 14px;
  }
  .ant-btn-link {
    padding: 0 !important;
  }
`;

export const LayoutSider = styled(Layout.Sider)`
  background: ${({ theme }) => theme.colors.sidebar};
`;

export const LayoutContent = styled(Layout.Content)`
  height: calc(100vh - 158px);
  margin: 0 10px 10px 30px;
  overflow: auto;
  background: ${({ theme }) => theme.colors.grayModalBackground};
`;

export const EmptySpace = styled.div`
  background: ${({ theme }) => theme.colors.grayModalBackground};
  height: 30px;
  width: calc(100% - 60px);
  margin-left: 20px;
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const ChildrenWrapper = styled.div`
  padding: 30px 40px 30px 20px;
  margin-bottom: 5%;
  h1 {
    margin: 0;
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: 900;
  }
  &.home {
    padding-top: 0 !important;
  }
`;

export const HelpDeskWrap = styled.div`
  position: absolute;
  bottom: 15px;
  width: 100%;
  .helpCircle {
    border-radius: 50% !important;
    width: 40px !important;
    margin-left: 25%;
  }
  .help {
    margin-left: 10%;
    height: 30px;
    width: 160px;
    border-radius: 35px;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  height: 85px;
  padding: 40px;
  background: #ffffff;

  img {
    width: 100%;
  }
`;

export const DivHomeLogo = styled.div`
  display: flex;
  align-items: center;
  height: 128px;
  margin-right: 30px;
  padding-left: 10px;

  img {
    width: 100%;
    max-width: 120px;
  }
`;

export const DivWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
`;

export const Footer = styled.div`
  padding: 2vh;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

export const PolicyTextStyled = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.footerText} !important;
  padding: 0 1vh;
`;

export const PolicyWraperStyled = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.footerText} !important;
  padding: 0 1vh;
`;
