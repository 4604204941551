import React from 'react';
import { FormProps, FormInstance as AntdFormInstance } from 'antd';
import { FormStyled, FormItemStyled } from './styles';

export type FormInstance = AntdFormInstance<any>;

export function useForm<T = any>() {
  return FormStyled.useForm<T>();
}

const Form = ({ children, ...restProps }: FormProps) => {
  return (
    <>
      <FormStyled {...restProps}>{children}</FormStyled>
    </>
  );
};

Form.Item = FormItemStyled;

export default Form;
