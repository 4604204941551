import React from 'react';
import { SwitchProps } from 'antd';
import { SwitchStyled } from './styles';

const Switch = ({ ...restProps }: SwitchProps) => {
  return (
    <>
      <SwitchStyled {...restProps} />
    </>
  );
};

export default Switch;
