import { FormInstance, notification, UploadFile } from 'antd';
import { IconType } from 'antd/lib/notification';
import { generatePath, matchPath, RouteComponentProps } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { CONFIGURATIONS_PATH } from 'features/Routes/helpers';
import { FormItemConfig, Options } from 'components/Form/helpers';
import { USER_PATHS } from 'shared/constants/common.const';
import { Permissions } from 'features/Routes/GuardedRoute/helpers';
import { getFromSessionStorage } from 'shared/utils/storage.utils';

declare global {
  interface Window {
    APP_CONFIG: DTO.GlobalConfig;
  }
}

const filteredPermissions: any = {};

const setFilteredPermissionsFn = (permissions: Permissions[]) => {
  const objKeys = Object.keys(filteredPermissions) || [];
  let index;
  for (index = 0; index < objKeys.length; index += 1) {
    delete filteredPermissions[objKeys[index]];
  }

  for (index = 0; index < permissions.length; index += 1) {
    const element = permissions[index];
    let option = filteredPermissions[element.optionPath];
    if (!option) {
      option = [];
      filteredPermissions[element.optionPath] = option;
    }

    option.push(element);
  }
};

export const setFilteredPermissions = setFilteredPermissionsFn;
const getOptionPermission = (optionPath: string) => {
  if (Object.keys(filteredPermissions).length === 0) {
    const userPaths: Permissions[] = getFromSessionStorage(USER_PATHS);
    setFilteredPermissionsFn(userPaths || []);
  }
  return filteredPermissions[optionPath];
};

export const hasOption = (optionPath: string) => {
  return !!getOptionPermission(optionPath);
};

export const hasPermission = (permissionName: string, optionPath?: string) => {
  const pathName = optionPath || window.location.pathname;

  let hasPermissionBool = false;
  const options = getOptionPermission(pathName);

  if (options) {
    hasPermissionBool = !!options.find((element: any) => {
      return element.description === permissionName;
    });
  }

  return hasPermissionBool;
};

const NOTIFICATION_DURATION = 6;

export const showNotificationList = (
  t: Function,
  notificationConfig: {
    type: IconType;
    description: JSX.Element;
    style?: React.CSSProperties;
    message: string;
    key?: string;
  }
) => {
  const { type, description, style, message, key } = notificationConfig;

  return notification[type === ('errors' as IconType) ? 'error' : type]({
    message: t(message),
    description,
    style,
    duration: NOTIFICATION_DURATION,
    key,
  });
};

export const showNotification = (
  t: Function,
  notificationConfig: {
    type: IconType;
    description: string;
    key?: string;
  } | null,
  isGeneralError?: boolean
) => {
  if (isGeneralError || !notificationConfig) {
    return notification.error({
      message: t('common.notificationTitles.error'),
      description: t('common.genericErrorMessage'),
      duration: NOTIFICATION_DURATION,
    });
  }

  const { type, description, key } = notificationConfig;
  return notification[type === ('errors' as IconType) ? 'error' : type]({
    message: t(`common.notificationTitles.${type}`),
    description: t(description),
    duration: NOTIFICATION_DURATION,
    key,
  });
};

export const findPathInMenuItem = (currentPath: string, items: any[]) => {
  return items?.find((item) => {
    let nestedPath = null;
    const { path, nestedPaths } = item;

    const findPathMethod = (np: { path: string }) => {
      if (
        np.path === CONFIGURATIONS_PATH.CUSTOMER_PROJECTS &&
        (currentPath === CONFIGURATIONS_PATH.PROJECT_DETAILS ||
          currentPath === CONFIGURATIONS_PATH.PROJECT_EXTERNAL_ID ||
          currentPath === CONFIGURATIONS_PATH.ASSIGNMENT_LIST)
      ) {
        return false;
      }
      return matchPath(currentPath, { path: np.path, exact: false }) !== null;
    };

    if (nestedPaths) {
      nestedPath = nestedPaths.find(findPathMethod);
    }

    return !!nestedPath || path === currentPath;
  });
};

export interface PathParameter {
  key: string;
  value: string | number | boolean;
}

export const redirectToPath = (
  history: RouteComponentProps['history'],
  path: string,
  id?: PathParameter,
  params?: PathParameter[]
) => {
  let redirectPath = '';

  if (id) {
    redirectPath = generatePath(path, { [id.key]: id.value });
  } else {
    redirectPath = path;
  }

  if (params) {
    redirectPath += '?';
    params.forEach((param) => {
      redirectPath += `${param.key}=${param.value}&`;
    });
    redirectPath = redirectPath.slice(0, -1);
  }

  return history.push(redirectPath);
};

export const getWindow = (): any => window;

export const getUniqueItems = (items: any[], uniqueKey: string) => {
  const addedItems = new Set();
  const uniqueItems = items.filter((item) => {
    const duplicate = addedItems.has(item[uniqueKey]);
    addedItems.add(item[uniqueKey]);
    return !duplicate;
  });
  return uniqueItems;
};

export const isFormEmpty = (form: FormInstance): boolean => {
  return (
    Object.keys(form.getFieldsValue(true)).filter(
      (key) => form.getFieldsValue(true)[key]
    ).length !== 0
  );
};
export const getDateFormatted = (value: string, t: Function): string => {
  return `${t(`common.months.${moment(value).format('MM')}`)} ${moment(
    value
  ).format('D')}, ${moment(value).format('YYYY')}`;
};

export const getDateFormattedShort = (value: string, t: Function): string => {
  return `${moment(value).format('DD')} ${t(
    `common.months.${moment(value).format('MM')}`
  ).substring(0, 3)} ${moment(value).format('YYYY')}`;
};

export const validateStartDateVersusTodayAndEndDate = (
  currentDate: Moment,
  endDate: Moment
) => {
  const today = moment();
  const greaterThanToday = currentDate > today;
  const greaterThanEndDate = endDate ? currentDate > endDate : false;
  return greaterThanEndDate || greaterThanToday;
};

export const validateEndDateVersusTodayAndStartDate = (
  currentDate: Moment,
  startDate: Moment
) => {
  const today = moment();
  const greaterThanToday = currentDate > today;
  const lowerThanStartDate = startDate ? currentDate < startDate : false;
  return lowerThanStartDate || greaterThanToday;
};

export const validateDateInRanges = (
  currentDate: Moment,
  startProjectDate: any,
  endProjectDate: any,
  startAssignDate: any,
  endAssignDate: any
) => {
  const rangeStart =
    (startAssignDate ? moment(startAssignDate) : false) ||
    moment(startProjectDate);
  const rangeEnd =
    (endAssignDate ? moment(endAssignDate) : false) || moment(endProjectDate);

  rangeStart.set({ hour: 23, minute: 59, seconds: 57 });
  currentDate.set({ hour: 23, minute: 59, seconds: 58 });
  rangeEnd.set({ hour: 23, minute: 59, seconds: 59 });
  return currentDate.isBefore(rangeStart) || currentDate.isAfter(rangeEnd);
};

export const downloadFile = (base64String: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = base64String;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const generateRandomId = (): number => {
  return Math.ceil(Math.random() * 10000);
};

export const getFormattedFileSize = (file: UploadFile) => {
  if (!file) return '0kb';
  const fileSize = file.size?.toString() || '0';

  if (fileSize.length < 7) {
    return `${Math.round(+fileSize / 1024).toFixed(2)}kb`;
  }
  return `${(Math.round(+fileSize / 1024) / 1000).toFixed(2)}MB`;
};

export const getStatusValueFromKey = (status: string): string => {
  // remove "timetracker.status..." string part
  return status.slice(19);
};

export const searchParam = (paramName: string) => {
  let paramValue;
  if (window.location.search) {
    paramValue = (window.location.search || '')
      .replace('?', '')
      .split(`${paramName}=`)[1]
      ?.split('&')[0];
  }
  return paramValue;
};

export const sortOptions = (options: Options[]): Options[] => {
  return options.sort((optionA, optionB) => {
    const nameA = optionA.name;
    const nameB = optionB.name;

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const getTranslatedOptions = (
  options: any[],
  t: Function
): Options[] => {
  const typedOptions: Options[] = options.map((option) => {
    return {
      value: option.id,
      name: t(option.name),
    } as unknown as Options;
  });
  return sortOptions(typedOptions);
};

export const removeSpacesFromFormField = (
  form: FormInstance,
  fieldName: string,
  justTrim?: boolean
) => {
  if (justTrim) {
    const value = form.getFieldValue(fieldName) || '';
    const newValue = value.trim();
    if (newValue) return;
    form.setFieldValue(fieldName, newValue);
    form.validateFields([fieldName]);
    return;
  }
  const newValue = form.getFieldValue(fieldName).replaceAll(' ', '');
  form.setFieldValue(fieldName, newValue);
  form.validateFields([fieldName]);
};

export function comparePossibleDates(firstValue: any, secondValue: any) {
  const firstDate = moment(firstValue);
  const secondDate = moment(secondValue);
  const firstDateValid = firstDate.isValid();
  const secondDateValid = secondDate.isValid();

  if (firstDateValid && secondDateValid) {
    return firstDate.isSame(secondDate);
  }

  return false;
}

/** Returns true if object had changes and false if it is the same */
export function lookForChanges(
  newObject: Record<string, any>,
  originalObject: Record<string, any>
) {
  let thereAreChanges = false;

  Object.keys(newObject).forEach((key) => {
    if (thereAreChanges) return;

    const areDatesAndSame = comparePossibleDates(
      newObject[key],
      originalObject[key]
    );

    if (areDatesAndSame) {
      thereAreChanges = false;
    } else {
      const formValue = newObject[key] ? newObject[key].toString() : undefined;
      const recordValue = originalObject[key]
        ? originalObject[key].toString()
        : undefined;

      if (formValue !== recordValue) {
        thereAreChanges = true;
      }
    }
  });

  return thereAreChanges;
}

export function getRequiredFields(fields: FormItemConfig[]) {
  return fields.reduce((accumulator, currentValue) => {
    if (
      !currentValue.disabled &&
      currentValue.rules?.some((rule) => rule.required)
    ) {
      return [...accumulator, currentValue.name];
    }

    return accumulator;
  }, [] as string[]);
}

export function validateForm(
  form: FormInstance,
  originalObject?: Record<string, any>
) {
  const areThereErrors = form
    .getFieldsError()
    .some((item) => !!item.errors.length);

  const areThereChanges = originalObject
    ? lookForChanges(form.getFieldsValue(), originalObject)
    : true;

  return !areThereErrors && areThereChanges;
}
