import React, { ComponentType } from 'react';
import { Props } from './helpers';
import { OptionStyled, SelectStyled } from './styles';

const Select = Object.assign(
  React.forwardRef(
    ({ children, ...restProps }: Props, ref: React.Ref<HTMLSelectElement>) => {
      return (
        <SelectStyled<ComponentType<any>>
          getPopupContainer={(triggerNode: HTMLElement) =>
            triggerNode.parentNode as HTMLElement
          }
          {...restProps}
          ref={ref}
        >
          {children}
        </SelectStyled>
      );
    }
  ),
  {
    Option: OptionStyled,
  }
);

export default Select;
