import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class StateService {
  getStatesByCountry = (countryId: string): Promise<DTO.States> => {
    return apiService.request({
      url: `${Endpoints.getStatesByCountry}${countryId}`,
      options: {
        method: 'GET',
      },
    });
  };
}

export const stateService = new StateService();
