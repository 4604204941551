import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class AreaService {
  getAreasNotAssignedToUser = (userId: string): Promise<any> => {
    return apiService.request({
      url: Endpoints.getAreasNotAssignedToUser,
      options: {
        method: 'POST',
        body: JSON.stringify({
          userId,
        }),
      },
    });
  };
}

export const areaService = new AreaService();
