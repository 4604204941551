import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class AbsencesService {
  approveLeader = (
    absenceApproved: DTO.AbsenceUpdate
  ): Promise<DTO.SingleAbsence> => {
    return apiService.request({
      url: `${Endpoints.acceptAbsenceAsLeader}${absenceApproved.absenceId}`,
      options: {
        method: 'PUT',
      },
    });
  };

  approveReviewer = (
    absenceApproved: DTO.AbsenceUpdate
  ): Promise<DTO.SingleAbsence> => {
    return apiService.request({
      url: `${Endpoints.acceptAbsenceAsReviewer}${absenceApproved.absenceId}`,
      options: {
        method: 'PUT',
      },
    });
  };

  amendLeader = (
    amendAbsence: DTO.AbsenceUpdate
  ): Promise<DTO.SingleAbsence> => {
    return apiService.request({
      url: `${Endpoints.amendAbsenceAsLeader}${amendAbsence.absenceId}`,
      options: {
        method: 'PUT',
      },
    });
  };

  amendReviewer = (
    amendAbsence: DTO.AbsenceUpdate
  ): Promise<DTO.SingleAbsence> => {
    return apiService.request({
      url: `${Endpoints.amendAbsenceAsReviewer}${amendAbsence.absenceId}`,
      options: {
        method: 'PUT',
      },
    });
  };

  updateComments = (
    commentAbsence: DTO.AbsenceComments
  ): Promise<DTO.SingleAbsence> => {
    return apiService.request({
      url: Endpoints.updateAbsenceComments,
      options: {
        method: 'POST',
        body: JSON.stringify(commentAbsence),
      },
    });
  };

  getTimeOffHistory = (props: {
    timeOffId: number;
    body: DTO.GetPaginatedList<
      Partial<DTO.TimeOffHistory> & { leaders: string }
    >;
  }): Promise<DTO.GridResults<DTO.TimeOffHistory>> => {
    const { timeOffId, body } = props;
    return apiService.request({
      url: `${Endpoints.getTimeOffHistory}/${timeOffId}`,
      options: {
        method: 'POST',
        body: JSON.stringify(body),
      },
    });
  };
}

export const absencesService = new AbsencesService();
