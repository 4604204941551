import styled from 'styled-components';

export const DivContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: center;

  img {
    width: 80px;
    margin-right: 30px;
  }
`;

export const DivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  button {
    width: fit-content;
    padding: 0;
    margin: 0;
    margin-top: 10px;
  }
`;
