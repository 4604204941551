import React, { FC, memo, useState, useEffect, useCallback } from 'react';
import { APP_PATH } from 'features/Routes/helpers';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { findPathInMenuItem } from 'shared/utils/common.utils';
import { ChildrenContainer, MenuContainer, MenuStyled } from './styles';
import { FormattedMenuItem, MenuItem } from './helper';

// TODO: Make menuItems and handleSelected mandatory when collapsible Menu is implemented
interface Props {
  menuItems?: MenuItem[];
  handleSelected?(event: any): void;
  isChild?: boolean;
  parentSelectedKey?: string;
  setParentSelectedKey?: (key: string) => void;
}

const Menu: FC<Props> = ({
  menuItems = [],
  handleSelected,
  isChild,
  parentSelectedKey,
  setParentSelectedKey,
}): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedKey, setSelectedKey] = useState('');
  const handleSelectedItem = useCallback(
    (event: any) => {
      if (!handleSelected) {
        return;
      }

      const { key } = event;
      setSelectedKey(`${key}`);
      handleSelected(key);
    },
    [handleSelected]
  );

  const getItems: (items?: MenuItem[]) => FormattedMenuItem[] | undefined =
    useCallback(
      (_items?: MenuItem[]) =>
        _items?.map((item) => {
          return {
            label: (
              <div>
                <Link
                  onClick={() => {
                    handleSelectedItem(item);
                  }}
                  to={item.path}
                >
                  {t(item.name)}
                </Link>

                {item.children && (
                  <ChildrenContainer
                    style={{ bottom: `${item.children.length * -94}%` }}
                  >
                    <Menu
                      menuItems={item.children}
                      handleSelected={handleSelected}
                      isChild
                      parentSelectedKey={parentSelectedKey}
                      setParentSelectedKey={setSelectedKey}
                    />
                  </ChildrenContainer>
                )}
              </div>
            ),
            key: item.key,
            icon: item.icon,
          };
        }),
      [handleSelected, handleSelectedItem, parentSelectedKey, t]
    );

  useEffect(() => {
    if (!menuItems || !handleSelected || isChild) {
      return;
    }
    const currentPath = history.location.pathname;
    const selectedItem = findPathInMenuItem(currentPath, menuItems);
    const key = selectedItem?.key.toString();
    if (!key) {
      history.replace(APP_PATH.HOME);
    } else {
      setSelectedKey(key);
      handleSelected(key);
    }
  }, [getItems, handleSelected, history, isChild, menuItems]);

  useEffect(() => {
    setParentSelectedKey?.(selectedKey);
  }, [isChild, selectedKey, setParentSelectedKey]);

  return (
    <MenuContainer>
      <MenuStyled
        selectedKeys={[parentSelectedKey || selectedKey]}
        items={getItems(menuItems)}
      />
    </MenuContainer>
  );
};

export default memo(Menu);

Menu.defaultProps = {
  menuItems: undefined,
  handleSelected: undefined,
  isChild: false,
  parentSelectedKey: undefined,
  setParentSelectedKey: undefined,
};
