import React from 'react';
import flowhrLogo from 'shared/images/flowhr_logo.svg';
import { FIRST_ELEMENT } from 'shared/constants/common.const';
import {
  ClockCircleOutlined,
  UserOutlined,
  FileTextOutlined,
  CopyOutlined,
  CalendarOutlined,
  ProfileOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { LayoutConfig, MenuItem } from 'features/Layouts/helpers';
import { TIMETRACKER_PATH } from '../helpers';
import { Permissions } from '../GuardedRoute/helpers';

export const TIMETRACKER_LAYOUT: LayoutConfig = {
  title: 'timetracker.title',
  logo: flowhrLogo,
  menuItems: [
    {
      key: 0,
      name: 'timetracker.notifications.title',
      icon: <CalendarOutlined />,
      path: TIMETRACKER_PATH.NOTIFICATIONS,
      nestedPaths: [],
    },
    {
      key: 1,
      name: 'timetracker.menu.timetracker',
      icon: <ClockCircleOutlined />,
      path: TIMETRACKER_PATH.HOME,
      nestedPaths: [],
    },
    {
      key: 2,
      name: 'timetracker.timeSheets.timesheets',
      icon: <CopyOutlined />,
      path: TIMETRACKER_PATH.TIMESHEETS,
      nestedPaths: [
        {
          name: 'timetracker.timeSheets.viewTimesheet',
          path: TIMETRACKER_PATH.TIMESHEETS_VIEW,
        },
        {
          name: 'timetracker.timeSheets.editTimesheet',
          path: TIMETRACKER_PATH.TIMESHEETS_EDIT,
        },
      ],
    },
    {
      key: 5,
      name: 'timetracker.menu.importReport',
      icon: <UploadOutlined />,
      path: TIMETRACKER_PATH.IMPORT_REPORTS,
    },
    {
      key: 6,
      name: 'timetracker.menu.reportTime',
      icon: <FileTextOutlined />,
      path: TIMETRACKER_PATH.REPORTS,
      nestedPaths: [],
    },
    {
      key: 7,
      name: 'timetracker.menu.reviewTimeSheets',
      icon: <UserOutlined />,
      path: TIMETRACKER_PATH.REVIEW_TIMESHEETS,
      nestedPaths: [],
    },
    {
      key: 8,
      name: 'timetracker.menu.reviewTimeSheetsByArea',
      icon: <UserOutlined />,
      path: TIMETRACKER_PATH.REVIEW_TIMESHEETS_BY_AREA,
      nestedPaths: [
        {
          name: 'timetracker.timeSheets.viewTimesheet',
          path: TIMETRACKER_PATH.REVIEW_ALL_TIMESHEETS_HISTORY,
        },
      ],
    },
    {
      key: 10,
      name: 'timetracker.menu.manageReviewTimeSheets',
      icon: <ProfileOutlined />,
      path: TIMETRACKER_PATH.MANAGE_TIMESHEET,
      nestedPaths: [
        {
          name: 'timetracker.timeSheets.viewTimesheet',
          path: TIMETRACKER_PATH.TIMESHEETS_REVIEWER_VIEW,
        },
        {
          name: 'timetracker.timeSheets.editTimesheet',
          path: TIMETRACKER_PATH.TIMESHEETS_REVIEWER_EDIT,
        },
      ],
    },
    {
      key: 11,
      name: 'timetracker.timeOff.timeOff',
      icon: <CalendarOutlined />,
      path: TIMETRACKER_PATH.ABSENCES,
      nestedPaths: [],
    },
    {
      key: 12,
      name: 'timetracker.menu.reviewTimeOff',
      icon: <CalendarOutlined />,
      path: TIMETRACKER_PATH.REVIEW_ABSENCES,
      nestedPaths: [],
    },
    {
      key: 13,
      name: 'timetracker.menu.reviewAllTimeOff',
      icon: <CalendarOutlined />,
      path: TIMETRACKER_PATH.REVIEW_ALL_ABSENCES,
      nestedPaths: [],
    },
    {
      key: 14,
      name: 'timetracker.menu.manageTimeOff',
      icon: <CalendarOutlined />,
      path: TIMETRACKER_PATH.MANAGE_ABSENCES,
      nestedPaths: [],
    },
  ],
};

export const getInitialRoute = (permissions: Permissions[]): string => {
  const conf: Permissions[] = permissions.filter(
    (permission) => permission.sectionPath === TIMETRACKER_PATH.ROOT
  );
  const confgOrder: string = conf.reduce((lowest, current) => {
    return current.order < lowest.order ? current : lowest;
  }, conf[FIRST_ELEMENT]).optionPath;

  return confgOrder;
};

export const getAllLayout = (permissions: Permissions[]): LayoutConfig => {
  const layout: LayoutConfig = {
    title: 'timetracker.title',
    logo: flowhrLogo,
    menuItems: [],
  };
  const menuFiltered = TIMETRACKER_LAYOUT.menuItems.filter((menu: MenuItem) => {
    return permissions.some((permission: Permissions) => {
      return permission.optionPath === menu.path;
    });
  });

  layout.menuItems = menuFiltered;
  return layout;
};
