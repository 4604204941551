import React, { FC, Suspense, useCallback, lazy, useEffect } from 'react';
import {
  RouteProps,
  Route,
  Redirect,
  Switch,
  useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { USER_PATHS, ProfileOptions } from 'shared/constants/common.const';
import { showNotification } from 'shared/utils/common.utils';
import { getFromSessionStorage } from 'shared/utils/storage.utils';
import BaseLayout from 'features/Layouts';
import { LayoutConfig } from 'features/Layouts/helpers';
import { getAllLayout, getInitialRoute } from './helpers';
import { CONFIGURATIONS_PATH, APP_PATH } from '../helpers';
import { Permissions } from '../GuardedRoute/helpers';

const GuardedRoute = lazy(() => import('features/Routes/GuardedRoute'));
const UsersContainer = lazy(
  () => import('features/Modules/Timetracker/Users/users.container')
);
const CustomerContainer = lazy(
  () => import('features/Modules/Timetracker/Customers/customer.container')
);
const ProjectsContainer = lazy(
  () => import('features/Modules/Timetracker/Projects/projects.container')
);
const CompaniesContainer = lazy(
  () => import('features/Modules/Timetracker/Companies/companies.container')
);
const CompanyGroupsPage = lazy(
  () => import('features/Modules/Timetracker/CompanyGroups')
);
const ProfilesContainer = lazy(
  () => import('features/Modules/Timetracker/Profiles/profiles.container')
);

const ConfigurationsRoutes: FC<RouteProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const userPaths: Permissions[] = getFromSessionStorage(USER_PATHS);

  const layout: LayoutConfig = getAllLayout(userPaths);

  useEffect(() => {
    if (layout.menuItems.length === 0) {
      showNotification(t, {
        type: 'error',
        description: 'common.notificationUserNotAllow',
      });
      history.push(APP_PATH.HOME);
    }
  }, [layout, history, t]);

  const UsersContainerPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={CONFIGURATIONS_PATH.USERS}
        component={() => <UsersContainer />}
        option={ProfileOptions.option}
      />
    ),
    []
  );

  const CompanyGroupPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={CONFIGURATIONS_PATH.COMPANIES}
        component={() => <CompanyGroupsPage />}
        option={ProfileOptions.option}
      />
    ),
    []
  );

  const CompaniesContainerPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={CONFIGURATIONS_PATH.COMPANIES}
        component={() => <CompaniesContainer />}
        option={ProfileOptions.option}
      />
    ),
    []
  );
  const CustomersContainerPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={CONFIGURATIONS_PATH.CUSTOMERS}
        component={() => <CustomerContainer />}
        option={ProfileOptions.option}
      />
    ),
    []
  );
  const ProjectsContainerPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={CONFIGURATIONS_PATH.PROJECTS}
        component={() => <ProjectsContainer />}
        option={ProfileOptions.option}
      />
    ),
    []
  );

  return (
    <BaseLayout config={layout}>
      <Suspense fallback={<Spin />}>
        <Switch>
          <Route
            exact
            path={CONFIGURATIONS_PATH.ROOT}
            render={useCallback(
              () => (
                <Redirect to={getInitialRoute(userPaths)} />
              ),
              [userPaths]
            )}
          />
          <Route path={CONFIGURATIONS_PATH.USERS} render={UsersContainerPath} />
          <Route
            path={CONFIGURATIONS_PATH.PROFILES}
            render={useCallback(
              () => (
                <ProfilesContainer />
              ),
              []
            )}
          />
          <Route
            path={CONFIGURATIONS_PATH.COMPANIES}
            render={CompaniesContainerPath}
          />
          <Route
            path={CONFIGURATIONS_PATH.COMPANY_GROUP}
            render={CompanyGroupPath}
          />
          <Route
            path={CONFIGURATIONS_PATH.CUSTOMERS}
            render={CustomersContainerPath}
          />
          <Route
            path={CONFIGURATIONS_PATH.PROJECTS}
            render={ProjectsContainerPath}
          />
        </Switch>
      </Suspense>
    </BaseLayout>
  );
};

export default ConfigurationsRoutes;
