import { COMPANY_ID, UserRolType } from 'shared/constants/common.const';
import { getFromSessionStorage } from 'shared/utils/storage.utils';
import { RootState } from 'store/root.reducer';

export const UNKNOWN = 'Unknown';

export const selectUser = ({ auth }: RootState): DTO.Nullable<DTO.User> =>
  auth.user;

export const selectUserFullName = ({ auth }: RootState): string => {
  const name = auth.user?.firstName;
  const lastName = auth.user?.lastName;

  if (name && lastName) {
    return name.concat(' ', lastName);
  }

  return UNKNOWN;
};

export const selectUserName = ({ auth }: RootState): string => {
  return auth.user?.firstName || UNKNOWN;
};

export const selectUserId = ({ auth }: RootState): string =>
  auth.user?.userId || '';

export const selectUserEmail = ({ auth }: RootState): string =>
  auth.user?.email || '';

export const selectRolesNames = ({ auth }: RootState): UserRolType[] => {
  const roles: UserRolType[] = [];
  auth.user?.roles?.forEach((rol) => {
    roles.push(rol);
  });
  return roles;
};

export const selectCompanies = ({
  auth,
}: RootState): { id: string; name: string }[] => {
  const companies: { [key: string]: string } = {};
  auth.user?.roles?.forEach((rol) => {
    const key = rol.companyId?.toString() || '';
    companies[key] = rol.companyName || '';
  });

  const companiesArray: { id: string; name: string }[] = [];
  Object.keys(companies).forEach((key) =>
    companiesArray.push({ id: key, name: companies[key] })
  );

  return companiesArray;
};

export const selectCurrentCompany = ({ auth }: RootState): string => {
  const sessionCompanyId = getFromSessionStorage(COMPANY_ID);
  const companyId =
    auth.user?.companyIdSelected?.toString() || sessionCompanyId?.toString();
  return companyId;
};

export const selectCurrentCompanyName = ({ auth }: RootState): string => {
  const companyIdSelected =
    auth.user?.companyIdSelected?.toString() ||
    getFromSessionStorage(COMPANY_ID);
  const company = auth.user?.roles?.find(
    (rol) => rol.companyId?.toString() === companyIdSelected?.toString()
  );

  return company?.companyName || '';
};
