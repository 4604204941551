import React, {
  FC,
  Suspense,
  useCallback,
  lazy,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  COMPANY_ID,
  SESSION_USER,
  USER_PATHS,
  UserRolType,
  ProfileOptions,
} from 'shared/constants/common.const';
import { getFromSessionStorage } from 'shared/utils/storage.utils';
import {
  RouteProps,
  Route,
  Redirect,
  Switch,
  useHistory,
} from 'react-router-dom';
import BaseLayout from 'features/Layouts';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { projectPeople } from 'shared/services/projectPeople.service';
import { apiService } from 'shared/services';

import { getErrorInfo } from 'shared/utils/error.utils';
import { LayoutConfig } from 'features/Layouts/helpers';
import { TIMETRACKER_PATH } from '../helpers';
import { getInitialRoute, getAllLayout } from './helpers';
import { Permissions } from '../GuardedRoute/helpers';

const GuardedRoute = lazy(() => import('features/Routes/GuardedRoute'));
const HomePage = lazy(() => import('features/Modules/Timetracker/HomePage'));
const TimesheetsRoutes = lazy(
  () => import('features/Modules/Timetracker/Timesheets/Timesheets.container')
);
const ReviewTimeEntries = lazy(
  () => import('features/Modules/Timetracker/ReviewTimeEntries')
);

const ReviewAllTimeSheets = lazy(
  () => import('features/Modules/Timetracker/ReviewAllTimeSheets')
);

const ReviewAllTimeSheetsHistory = lazy(
  () =>
    import('features/Modules/Timetracker/ReviewAllTimeSheets/TimeEntryHistory')
);

const Users = lazy(() => import('features/Modules/Timetracker/Users'));
const ImportReport = lazy(
  () => import('features/Modules/Timetracker/ImportReport')
);
const Report = lazy(() => import('features/Modules/Timetracker/Report'));
const Notifications = lazy(
  () => import('features/Modules/Timetracker/Notifications')
);
const Absences = lazy(() => import('features/Modules/Timetracker/Absences'));

const TimeOffHistory = lazy(
  () => import('features/Modules/Timetracker/ReviewAbsences/TimeOffHistory')
);

const ReviewAbsences = lazy(
  () => import('features/Modules/Timetracker/ReviewAbsences')
);
const ManageTimesheetsRoutes = lazy(
  () =>
    import(
      'features/Modules/Timetracker/ManageTimesheets/ManageTimesheets.container'
    )
);
const ManageAbsences = lazy(
  () => import('features/Modules/Timetracker/ManageAbsences')
);

const emptyLayout = {
  title: '',
  logo: '',
  menuItems: [],
};

const TimetrackerRoutes: FC<RouteProps> = () => {
  let roles: UserRolType[] = useMemo(() => [], []);
  let roleNames: string[] = useMemo(() => [], []);
  const [layout, setLayout] = useState<LayoutConfig>(emptyLayout);
  const [layoutIsReady, setLayoutIsReady] = useState<boolean>(false);
  const [leaderState, setLeaderState] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const user = getFromSessionStorage(SESSION_USER);
  const currentCompanyId = getFromSessionStorage(COMPANY_ID);
  const userPaths: Permissions[] = getFromSessionStorage(USER_PATHS);

  if (user) {
    roles = user.roles.filter(
      (rol: UserRolType) =>
        rol.companyId?.toString() === currentCompanyId?.toString()
    );
    roleNames = roles.map((rol) => rol.roleName);
  }

  const validateUserIsLeader = useCallback(() => {
    if (!apiService.hasCompanyId() || leaderState) {
      return;
    }
    setLeaderState(true);
    projectPeople
      .getPersonLeader()
      .then(() => {
        const newLayout: LayoutConfig = getAllLayout(userPaths);
        setLayout(newLayout);
        setLayoutIsReady(true);
      })
      .catch((errorPromise: Promise<any>) => {
        getErrorInfo(errorPromise, t);
        const newLayout: LayoutConfig = getAllLayout(userPaths);
        setLayout(newLayout);
        setLayoutIsReady(true);
      });
  }, [t, leaderState, userPaths]);

  useEffect(() => {
    if (layoutIsReady) {
      return;
    }

    if (user) {
      validateUserIsLeader();
    }
  }, [history, layoutIsReady, roleNames, t, user, validateUserIsLeader]);

  const TimeTrackerHomePath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={TIMETRACKER_PATH.HOME}
        component={() => <HomePage />}
        option={ProfileOptions.option}
      />
    ),
    []
  );
  const TimesheetRoutesPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={TIMETRACKER_PATH.TIMESHEETS}
        component={() => <TimesheetsRoutes />}
        option={ProfileOptions.option}
      />
    ),
    []
  );
  const ReviewTimeEntriesPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={TIMETRACKER_PATH.REVIEW_TIMESHEETS}
        component={() => <ReviewTimeEntries />}
        option={ProfileOptions.option}
      />
    ),
    []
  );
  const ReviewTimesheetsByAreaPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={TIMETRACKER_PATH.REVIEW_TIMESHEETS_BY_AREA}
        component={() => (
          <ReviewAllTimeSheets
            path={TIMETRACKER_PATH.REVIEW_TIMESHEETS_BY_AREA}
          />
        )}
        option={ProfileOptions.option}
      />
    ),
    []
  );
  const ReviewAllTimesheetsHistoryPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={TIMETRACKER_PATH.REVIEW_ALL_TIMESHEETS_HISTORY}
        component={() => (
          <ReviewAllTimeSheetsHistory
            path={TIMETRACKER_PATH.REVIEW_ALL_TIMESHEETS_HISTORY}
          />
        )}
        option={ProfileOptions.permission}
      />
    ),
    []
  );
  const ImportReportPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={TIMETRACKER_PATH.IMPORT_REPORTS}
        component={() => <ImportReport />}
        option={ProfileOptions.option}
      />
    ),
    []
  );
  const ReportPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={TIMETRACKER_PATH.REPORTS}
        component={() => <Report />}
        option={ProfileOptions.option}
      />
    ),
    []
  );
  const NotificationsPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={TIMETRACKER_PATH.NOTIFICATIONS}
        component={() => <Notifications />}
        option={ProfileOptions.option}
      />
    ),
    []
  );
  const TimetrackerUserPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={TIMETRACKER_PATH.USERS}
        component={() => <Users />}
        option={ProfileOptions.option}
      />
    ),
    []
  );
  const AbsencePath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={TIMETRACKER_PATH.ABSENCES}
        component={() => <Absences />}
        option={ProfileOptions.option}
      />
    ),
    []
  );
  const ReviewAbsencePath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={TIMETRACKER_PATH.REVIEW_ABSENCES}
        component={() => (
          <ReviewAbsences path={TIMETRACKER_PATH.REVIEW_ABSENCES} />
        )}
        option={ProfileOptions.option}
      />
    ),
    []
  );
  const ReviewAllAbsencePath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={TIMETRACKER_PATH.REVIEW_ALL_ABSENCES}
        component={() => (
          <ReviewAbsences path={TIMETRACKER_PATH.REVIEW_ALL_ABSENCES} />
        )}
        option={ProfileOptions.option}
      />
    ),
    []
  );
  const ManageTimesheetsPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={TIMETRACKER_PATH.MANAGE_TIMESHEET}
        component={() => <ManageTimesheetsRoutes />}
        option={ProfileOptions.option}
      />
    ),
    []
  );
  const ManageAbsencePath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={TIMETRACKER_PATH.MANAGE_ABSENCES}
        component={() => <ManageAbsences />}
        option={ProfileOptions.option}
      />
    ),
    []
  );
  const TimeOffHistoryPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={TIMETRACKER_PATH.TIME_OFF_HISTORY}
        component={() => <TimeOffHistory />}
        option={ProfileOptions.permission}
      />
    ),
    []
  );

  return (
    <BaseLayout config={layout}>
      <Suspense fallback={<Spin />}>
        <Switch>
          <Route
            key={TIMETRACKER_PATH.ROOT}
            exact
            path={TIMETRACKER_PATH.ROOT}
            render={useCallback(
              () => (
                <Redirect to={getInitialRoute(userPaths)} />
              ),
              [userPaths]
            )}
          />
          <Route
            key={TIMETRACKER_PATH.HOME}
            exact
            path={TIMETRACKER_PATH.HOME}
            render={TimeTrackerHomePath}
          />
          <Route
            key={TIMETRACKER_PATH.TIMESHEETS}
            path={TIMETRACKER_PATH.TIMESHEETS}
            render={TimesheetRoutesPath}
          />
          <Route
            key={TIMETRACKER_PATH.REVIEW_TIMESHEETS}
            exact
            path={TIMETRACKER_PATH.REVIEW_TIMESHEETS}
            render={ReviewTimeEntriesPath}
          />
          <Route
            key={TIMETRACKER_PATH.REVIEW_TIMESHEETS_BY_AREA}
            exact
            path={TIMETRACKER_PATH.REVIEW_TIMESHEETS_BY_AREA}
            render={ReviewTimesheetsByAreaPath}
          />
          <Route
            key={TIMETRACKER_PATH.REVIEW_ALL_TIMESHEETS_HISTORY}
            exact
            path={TIMETRACKER_PATH.REVIEW_ALL_TIMESHEETS_HISTORY}
            render={ReviewAllTimesheetsHistoryPath}
          />
          <Route
            key={TIMETRACKER_PATH.IMPORT_REPORTS}
            exact
            path={TIMETRACKER_PATH.IMPORT_REPORTS}
            render={ImportReportPath}
          />
          <Route exact path={TIMETRACKER_PATH.REPORTS} render={ReportPath} />
          <Route
            exact
            key={TIMETRACKER_PATH.NOTIFICATIONS}
            path={TIMETRACKER_PATH.NOTIFICATIONS}
            render={NotificationsPath}
          />
          <Route
            key={TIMETRACKER_PATH.USERS}
            exact
            path={TIMETRACKER_PATH.USERS}
            render={TimetrackerUserPath}
          />

          <Route
            key={TIMETRACKER_PATH.ABSENCES}
            exact
            path={TIMETRACKER_PATH.ABSENCES}
            render={AbsencePath}
          />
          <Route
            key={TIMETRACKER_PATH.REVIEW_ABSENCES}
            exact
            path={TIMETRACKER_PATH.REVIEW_ABSENCES}
            render={ReviewAbsencePath}
          />
          <Route
            key={TIMETRACKER_PATH.REVIEW_ALL_ABSENCES}
            exact
            path={TIMETRACKER_PATH.REVIEW_ALL_ABSENCES}
            render={ReviewAllAbsencePath}
          />
          <Route
            key={TIMETRACKER_PATH.MANAGE_TIMESHEET}
            path={TIMETRACKER_PATH.MANAGE_TIMESHEET}
            render={ManageTimesheetsPath}
          />
          <Route
            key={TIMETRACKER_PATH.MANAGE_ABSENCES}
            path={TIMETRACKER_PATH.MANAGE_ABSENCES}
            render={ManageAbsencePath}
          />
          <Route
            key={TIMETRACKER_PATH.TIME_OFF_HISTORY}
            path={TIMETRACKER_PATH.TIME_OFF_HISTORY}
            render={TimeOffHistoryPath}
          />
        </Switch>
      </Suspense>
    </BaseLayout>
  );
};

export default TimetrackerRoutes;
