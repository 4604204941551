export default abstract class FieldRange {
  static readonly phone = Object.freeze({ min: 3, max: 20 });

  static readonly billingRate = Object.freeze({
    min: 0.01,
    max: 69_999_999_999_999.99,
    maxDecimalPlaces: 2,
  });

  static readonly address = Object.freeze({ min: undefined, max: 280 });

  static readonly zipCode = Object.freeze({ min: undefined, max: 9 });

  static readonly id = Object.freeze({ min: undefined, max: 30 });

  static readonly names = Object.freeze({ min: undefined, max: 90 });

  static readonly firstLastName = Object.freeze({ min: undefined, max: 90 });

  static readonly secondLastName = Object.freeze({ min: undefined, max: 90 });

  static readonly projectName = Object.freeze({ min: 1, max: 200 });

  static readonly projectExternalId = Object.freeze({ min: 1, max: 200 });

  static readonly comment = Object.freeze({ min: undefined, max: 2000 });

  static readonly identification = Object.freeze({ min: undefined, max: 30 });

  static readonly role = Object.freeze({ min: 2, max: 90 });
}
