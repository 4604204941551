import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '..';
import { CancellationModalProps } from './helper';

function Cancellation({
  confirm,
  cancel,
  showModalState,
}: CancellationModalProps) {
  const { t } = useTranslation();
  const [isShowingModal, setIsShowingModal] = showModalState;

  const onOk = useCallback(() => {
    setIsShowingModal(false);
    confirm?.();
  }, [confirm, setIsShowingModal]);

  const onCancel = useCallback(() => {
    setIsShowingModal(false);
    cancel?.();
  }, [cancel, setIsShowingModal]);

  return (
    <Modal
      open={isShowingModal}
      title={t('common.confirm')}
      okType="primary"
      okText={t('common.confirm')}
      cancelText={t('common.cancel')}
      centered
      onOk={onOk}
      onCancel={onCancel}
    >
      {t('timetracker.projectDetails.cancelConfirmMessage')}
    </Modal>
  );
}

export function useCancellationModal() {
  const [confirm, setConfirm] = useState<() => void>();
  const [cancel, setCancel] = useState<() => void>();
  const showModalState = useState(false);
  const [, setIsShowingModal] = showModalState;

  const confirmCancellation = useCallback((): Promise<boolean> => {
    return new Promise((resolve) => {
      setConfirm(() => () => {
        resolve(true);
      });

      setCancel(() => () => {
        resolve(false);
      });

      setIsShowingModal(true);
    });
  }, [setIsShowingModal]);

  return {
    CancellationModal: () => (
      <Cancellation
        confirm={confirm}
        cancel={cancel}
        showModalState={showModalState}
      />
    ),
    confirmCancellation,
  };
}
