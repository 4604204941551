import { Divider } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { FormModes } from 'shared/constants/common.const';
import AddPopover from './AddPopover';
import EditPopover from './EditPopover';
import {
  PopoverStyled,
  ListContainer,
  PillsContainer,
  SkillTypeContainer,
} from './styles';

interface Props {
  possibleSkills: DTO.PositionPossibleSkills[];
  formMode: FormModes;
  skillType: number;
  skills: DTO.PositionSkills[] | [];
  setSkills: Function;
  maxSkills?: number;
  skillLevels: DTO.PositionSkillLevel[];
  skillCategory: DTO.PositionSkillCategory;
  onChange?: () => void;
}

const Skill: FC<Props> = ({
  possibleSkills,
  skillType,
  skills,
  setSkills,
  formMode,
  maxSkills,
  skillLevels,
  skillCategory,
  onChange,
}: Props): JSX.Element => {
  const handleRemoveSkill = useCallback(
    (id: number) => {
      setSkills(skills.filter((a) => a.id !== id));
      onChange?.();
    },
    [onChange, setSkills, skills]
  );
  const handleAddSkill = useCallback(
    (skill: DTO.PositionSkills) => {
      setSkills([...skills, skill]);
      onChange?.();
    },
    [onChange, setSkills, skills]
  );

  return (
    <>
      <SkillTypeContainer key={skillType}>
        <ListContainer>
          <PillsContainer>
            {skills
              .filter((skill) => skill.skillTypeId === skillType)
              .map((item: DTO.PositionSkills) => {
                let rating = 0;
                if (item.skillLevelId !== undefined) {
                  rating =
                    skillLevels.find((el) => el.id === item.skillLevelId)
                      ?.score || 0;
                }
                return (
                  <PopoverStyled key={item.id}>
                    <EditPopover
                      count={skillLevels.length}
                      data={item}
                      childIdDeleted={handleRemoveSkill}
                      formMode={formMode}
                      rate={rating}
                    />
                  </PopoverStyled>
                );
              })}
          </PillsContainer>
          <PopoverStyled>
            <AddPopover
              skillCategory={skillCategory}
              possibleSkills={possibleSkills}
              skillLevels={skillLevels}
              setAddData={handleAddSkill}
              skills={skills}
              skillType={skillType}
              disabled={maxSkills ? skills.length === maxSkills : false}
            />
          </PopoverStyled>
        </ListContainer>
        <Divider />
      </SkillTypeContainer>
    </>
  );
};

export default memo(Skill);

Skill.defaultProps = {
  maxSkills: undefined,
  onChange: undefined,
};
