import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class ProjectPeople {
  getAllPeopleByProject = (
    page: number,
    pageSize: number,
    projectId: number,
    searchTerm: string,
    filter: {}
  ): Promise<DTO.ProjectPersonDetail> => {
    return apiService.request({
      url: `${Endpoints.getAllPeopleByProjectFiltered}${projectId}`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          page,
          pageSize,
          searchTerm,
          sortColumn: 'Name',
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: filter || {},
        }),
      },
    });
  };

  savePersonToProject = (person: DTO.PersonAddToProject): Promise<any> => {
    return apiService.request({
      url: Endpoints.addNewPersonToProject,
      options: {
        method: 'POST',
        body: JSON.stringify({
          ...person,
          billingRate: person.billingRate?.toString(),
          percentageAllocation: person.percentageAllocation ?? null,
        }),
      },
    });
  };

  editPersonToProject = (person: DTO.PersonAddToProject): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.editProjectPerson}${person.id}`,
      options: {
        method: 'PUT',
        body: JSON.stringify({
          ...person,
          percentageAllocation: person.percentageAllocation ?? null,
        }),
      },
    });
  };

  editPersonPutFromProject = (person: DTO.PersonAddToProject): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.editPersonPutFromProject}${person.id}`,
      options: {
        method: 'PUT',
        body: JSON.stringify({
          ...person,
          percentageAllocation: person.percentageAllocation ?? null,
        }),
      },
    });
  };

  getProjectsByPerson = (
    page: number,
    pageSize: number,
    personId: string,
    searchTerm: string,
    filter: {}
  ): Promise<DTO.ProjectPersonResult> => {
    return apiService.request({
      url: `${Endpoints.getProjectsByPerson}${personId}`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          page,
          pageSize,
          searchTerm,
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: filter || {},
        }),
      },
    });
  };

  getProjectsByPersonFromPeople = (
    page: number,
    pageSize: number,
    personId: string,
    searchTerm: string,
    filter: {}
  ): Promise<DTO.ProjectPersonResult> => {
    return apiService.request({
      url: `${Endpoints.getProjectsByPersonFromPeople}/${personId}`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          page,
          pageSize,
          searchTerm,
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: filter || {},
        }),
      },
    });
  };

  getProjectsByPersonFromProjects = (
    page: number,
    pageSize: number,
    personId: string,
    searchTerm: string,
    filter: {}
  ): Promise<DTO.ProjectPersonResult> => {
    return apiService.request({
      url: `${Endpoints.getProjectsByPersonFromProjects}/${personId}`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          page,
          pageSize,
          searchTerm,
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: filter || {},
        }),
      },
    });
  };

  saveProjectToPerson = (
    personProject: DTO.ProjectAddToPersonData
  ): Promise<DTO.ProjectAddToPerson> => {
    return apiService.request({
      url: Endpoints.addNewProjectToPerson,
      options: {
        method: 'POST',
        body: JSON.stringify(personProject),
      },
    });
  };

  getAllProjectsNotInPerson = (
    personId: number,
    clientId: number
  ): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getAllProjectsNotInPerson}${personId}/${clientId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getPersonLeader = (): Promise<any> => {
    return apiService.request({
      url: Endpoints.getPersonLeader,
      options: {
        method: 'GET',
      },
    });
  };

  deleteProject = (projectId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.deleteProjectByPerson}${projectId}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  getProjectPerson = (projectPersonId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getProjectPerson}/${projectPersonId}`,
      options: {
        method: 'GET',
      },
    });
  };

  activeProjectPerson = (projectPersonId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.activeProjectPerson}/${projectPersonId}`,
      options: {
        method: 'PUT',
        body: JSON.stringify({}),
      },
    });
  };

  inactiveProjectPerson = (projectPersonId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.inactiveProjectPerson}/${projectPersonId}`,
      options: {
        method: 'PUT',
        body: JSON.stringify({}),
      },
    });
  };
}

export const projectPeople = new ProjectPeople();
