import { DEFAULT_LOCALE } from 'shared/constants/common.const';
import { i18Service } from 'shared/services';

export const sortLocales = (locales: DTO.Locale[]): DTO.Locale[] => {
  if (!Array.isArray(locales)) return [DEFAULT_LOCALE];

  const newLocales = locales.filter(
    (locale) => locale.uiCulture !== DEFAULT_LOCALE.uiCulture
  );

  return [DEFAULT_LOCALE, ...newLocales];
};

export const getUnflattenLocaleBundle = (data: DTO.Translation[]) => {
  const result = {};
  data.forEach((item) => {
    const keys = item.languageKey.split('.');
    keys.reduce(
      (
        acc: { [key: string]: Record<string, unknown> },
        nextKey: string,
        i: number
      ) => {
        let nestedObj = {};
        if (!acc[nextKey]) {
          nestedObj = keys.length - 1 === i ? item.value : {};
          acc[nextKey] = nestedObj;
        }
        return acc[nextKey] || nestedObj;
      },
      result
    );
  });

  return result;
};

export const fetchLocaleById = (
  locale: DTO.Locale,
  setIsLoadingKeys?: Function
) => {
  if (setIsLoadingKeys) setIsLoadingKeys(true);
  i18Service
    .fetchLocaleById(locale.id)
    .then((bundle) => {
      if (setIsLoadingKeys) setIsLoadingKeys(false);
      i18Service.onChangeLocaleBundle(
        getUnflattenLocaleBundle(bundle),
        locale.uiCulture
      );
    })
    .catch(() => {
      i18Service.setDefaultLocale();
      if (setIsLoadingKeys) setIsLoadingKeys(false);
    });
};
