import { Avatar, Button, List } from 'antd';
import { NOTIFICATION_STATE_ID } from 'components/UserProfile/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  formatDatetoTitle,
  getFirstLetters,
  newOrderDateArray,
  NoticeListProps,
} from './helpers';
import {
  Description,
  DividerStyled,
  ListLi,
  ListStyled,
  NoNewNotifications,
  TitleDay,
} from './styles';

const NoticeList = ({
  data = [],
  onClick,
  onCancelNotification,
}: NoticeListProps): JSX.Element => {
  const { t } = useTranslation();

  if (!data || data.length === 0) {
    return (
      <NoNewNotifications>
        {t('timetracker.notifications.noNewNotifications')}
      </NoNewNotifications>
    );
  }
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return (
    <>
      {newOrderDateArray(data).map((date) => {
        let dateName;
        if (
          new Date(date.date.concat('T00:00:00')).toDateString() ===
          today.toDateString()
        ) {
          dateName = t('timetracker.notifications.today');
        } else if (
          new Date(date.date.concat('T00:00:00')).toDateString() ===
          yesterday.toDateString()
        ) {
          dateName = t('timetracker.notifications.yesterday');
        } else dateName = formatDatetoTitle(new Date(date.date), t);

        return (
          <React.Fragment key={date.date}>
            <DividerStyled orientation="left">
              <TitleDay>{dateName}</TitleDay>
            </DividerStyled>
            <ListLi>
              <List<DTO.Notification>
                key={date.date}
                dataSource={date.notifications}
                size="small"
                renderItem={(item, i) => {
                  const leftIcon =
                    item.statusId !== NOTIFICATION_STATE_ID.read ? (
                      <Avatar>{getFirstLetters(item.authorFullName)}</Avatar>
                    ) : (
                      <Avatar style={{ opacity: 0.5 }}>
                        {getFirstLetters(item.authorFullName)}
                      </Avatar>
                    );

                  return (
                    <ListStyled
                      key={`${item.id || i}${i}`}
                      actions={[
                        onCancelNotification && (
                          <Button
                            type="link"
                            onClick={() => onCancelNotification(item.id)}
                          >
                            x
                          </Button>
                        ),
                      ]}
                    >
                      <List.Item.Meta
                        avatar={leftIcon}
                        title={
                          <Description
                            onClick={() => onClick && onClick(item)}
                            className={
                              item.statusId === NOTIFICATION_STATE_ID.read
                                ? 'read'
                                : ''
                            }
                          >
                            <strong>{item.authorFullName}</strong>{' '}
                            {t(item.description)}
                          </Description>
                        }
                      />
                    </ListStyled>
                  );
                }}
              />
            </ListLi>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default NoticeList;
