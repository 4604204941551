import { PopoverProps } from 'antd';
import React, { PropsWithChildren } from 'react';
import { StyledPopover } from './styles';

interface Props extends PopoverProps {
  width?: string;
  margin?: string;
}

const Popover = ({
  children,
  ...restProps
}: PropsWithChildren<Props>): JSX.Element => {
  return <StyledPopover {...restProps}>{children}</StyledPopover>;
};

export default Popover;

Popover.defaultProps = {
  width: '',
  margin: '',
};
