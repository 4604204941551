import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Button';
import { generatePath, useHistory } from 'react-router';
import { PLACEMENT_PATH } from 'features/Routes/helpers';
import { ModalMode } from 'shared/constants/common.const';
import { hasPermission } from 'shared/utils/common.utils';
import { positionsService } from 'shared/services';
import { InterviewersView, Title, TitleWrapper } from './styles';
import InterviewersTable from './components/InterviewersTable';
import InterviewerModal from './components/InterviewerModal';
import ExpertiseFilter from './components/ExpertiseFilter';
import { InterviewerContext } from './InterviewerContext';

function Interviewers() {
  const { t } = useTranslation();
  const history = useHistory();
  const [expertiseSuggestions, setExpertiseSuggestions] = useState<
    DTO.Expertise[]
  >([]);

  const expertiseOptions = useMemo(() => {
    return expertiseSuggestions.map((_expertise) => ({
      value: _expertise.name,
      label: _expertise.name,
    }));
  }, [expertiseSuggestions]);

  const isAbleToAdd = useMemo(() => {
    return hasPermission(
      'placement.interviewer.interviewer_add',
      generatePath(PLACEMENT_PATH.INTERVIEWERS)
    );
  }, []);

  const isAbleToViewTable = useMemo(() => {
    return hasPermission(
      'placement.interviewer.interviewer_viewAll',
      generatePath(PLACEMENT_PATH.INTERVIEWERS)
    );
  }, []);

  const getExpertise = useCallback(async () => {
    const response = await positionsService.getExpertise();

    setExpertiseSuggestions(response || []);
  }, []);

  const openAddModal = () => {
    history.push({
      pathname: generatePath(PLACEMENT_PATH.INTERVIEWERS, {
        mode: ModalMode.add,
      }),
      search: history.location.search,
    });
  };

  useEffect(() => {
    getExpertise();
  }, [getExpertise]);

  return (
    <InterviewerContext.Provider value={{ expertiseOptions }}>
      <InterviewersView>
        <TitleWrapper>
          <Title>{t('positions.interviewers.listOfInterviewers')}</Title>
          {isAbleToAdd && (
            <Button type="primary" onClick={openAddModal}>
              {t('common.add')}
            </Button>
          )}
        </TitleWrapper>
        {isAbleToViewTable && (
          <>
            <ExpertiseFilter /> <InterviewersTable />
          </>
        )}
        <InterviewerModal />
      </InterviewersView>
    </InterviewerContext.Provider>
  );
}

export default Interviewers;
