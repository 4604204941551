export const getFromSessionStorage = (key: string) => {
  const value = sessionStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

export const setToSessionStorage = (key: string, value: any) =>
  sessionStorage.setItem(key, JSON.stringify(value));

export const removeFromSessionStorage = (key: string) =>
  sessionStorage.removeItem(key);

export const getFromLocalStorage = (key: string) => {
  const localStorageValue = localStorage.getItem(key);
  return localStorageValue ? JSON.parse(localStorageValue) : null;
};

export const setToLocalStorage = (key: string, value: any) =>
  localStorage.setItem(key, JSON.stringify(value));

export const updateValueLocalStorage = (key: string, value: any) => {
  const currentValue = getFromLocalStorage(key);

  const newValue = {
    ...currentValue,
    ...value,
  };

  setToLocalStorage(key, newValue);
};

export const removeFromLocalStorage = (key: string) =>
  localStorage.removeItem(key);
