import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class UserProfileService {
  getUserProfiles = (
    userId: string,
    page: number,
    pageSize: number,
    filter?: any
  ): Promise<any> => {
    return apiService.request({
      url: Endpoints.getUserProfiles,
      options: {
        method: 'POST',
        body: JSON.stringify({
          page,
          pageSize,
          filter,
          valueId: '',
          sortColumn: 'userid',
          sortDirection: 'asc',
          searchTerm: userId,
        }),
      },
    });
  };

  getProfilesNotInUser = (userId: string): Promise<any> => {
    return apiService.request({
      url: Endpoints.getProfilesNotInUser,
      options: {
        method: 'POST',
        body: JSON.stringify({
          userId,
        }),
      },
    });
  };

  editProfileStatus = (
    userId: string,
    profileId: string,
    companyId: number,
    isActive: boolean
  ): Promise<any> => {
    return apiService.request({
      url: Endpoints.editProfileStatus,
      options: {
        method: 'PUT',
        body: JSON.stringify({
          userId,
          profileId,
          companyId,
          isActive,
        }),
      },
    });
  };

  addUserProfile = (
    userId: string,
    profileId: string,
    companyId: number
  ): Promise<any> => {
    return apiService.request({
      url: Endpoints.addUserProfile,
      options: {
        method: 'POST',
        body: JSON.stringify({
          userId,
          profileId,
          companyId,
        }),
      },
    });
  };

  getPathPermissions = (userId: string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getPathPermissions}${userId}`,
      options: {
        method: 'GET',
      },
    });
  };
}

export const userProfileService = new UserProfileService();
