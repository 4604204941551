import styled from 'styled-components';

export const DivWrapper = styled.div`
  width: auto;
  display: flex;
  margin: 15px 0;
`;

export const FormField = styled.div`
  width: 100%;
`;

export const FormWrapper = styled.div`
  margin-right: 24px;
  .ant-input-number {
    min-width: 100%;
  }
`;

export const FormSection = styled.h2`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 24px;
  font-weight: bold;
`;

export const TagCollectionWrapper = styled.div`
  margin-top: 21px;
`;
