import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class UserProfileAreaService {
  getAllUserProfileAreaByUser = (
    userId: string,
    page: number,
    pageSize: number,
    filter?: any
  ): Promise<any> => {
    return apiService.request({
      url: Endpoints.getAllUserProfileAreaByUser,
      options: {
        method: 'POST',
        body: JSON.stringify({
          page,
          pageSize,
          filter,
          valueId: '',
          sortColumn: 'name',
          sortDirection: 'asc',
          searchTerm: userId,
        }),
      },
    });
  };

  activateUserProfileArea = (userProfileAreaId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.activateUserProfileArea}${userProfileAreaId}`,
      options: {
        method: 'PUT',
      },
    });
  };

  inactivateUserProfileArea = (userProfileAreaId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.inactivateUserProfileArea}${userProfileAreaId}`,
      options: {
        method: 'PUT',
      },
    });
  };

  addUserProfileArea = (
    userProfileId: number,
    areaId: number
  ): Promise<any> => {
    return apiService.request({
      url: Endpoints.addUserProfileArea,
      options: {
        method: 'POST',
        body: JSON.stringify({
          userProfileId,
          areaId,
        }),
      },
    });
  };
}

export const userProfileAreaService = new UserProfileAreaService();
