import React, { PropsWithChildren } from 'react';
import { InputProps as AntInputProps } from 'antd';
import { InputStyled } from './styles';

interface Props extends AntInputProps {
  width?: string;
  margin?: string;
}

export function Input({
  children,
  ...restProps
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <>
      <InputStyled {...restProps}>{children}</InputStyled>
    </>
  );
}

Input.TextArea = InputStyled.TextArea;
Input.Password = InputStyled.Password;
Input.defaultProps = {
  width: '',
  margin: '',
};
