import React from 'react';
import { TagContainer } from './styles';

function Tag(props: { children?: React.ReactNode }): JSX.Element {
  const { children } = props;

  return <TagContainer>{children}</TagContainer>;
}

Tag.defaultProps = {
  children: undefined,
};

export default Tag;
