import React from 'react';
import { DatePickerProps as AntdDatePicker } from 'antd';
import { DatePickerStyled, RangePickerStyled } from './styles';

export type DatePickerProps = AntdDatePicker;

const DatePicker = ({ ...restProps }: DatePickerProps) => {
  return (
    <DatePickerStyled
      getPopupContainer={(triggerNode: HTMLElement) =>
        triggerNode.parentNode as HTMLElement
      }
      {...restProps}
      inputReadOnly
    />
  );
};

DatePicker.RangePicker = RangePickerStyled;

export default DatePicker;
