import React from 'react';
import { TIMETRACKER_PATH } from 'features/Routes/helpers';
import flowHrLogo from 'shared/images/flowhr_logo.svg';
import { UserOutlined } from '@ant-design/icons';

export interface MenuItem {
  key: number;
  name: string;
  icon?: JSX.Element;
  path: string;
  children?: MenuItem[];
  nestedPaths?: NestedPath[];
}

export interface NestedPath {
  name: string;
  path: string;
}

export interface LayoutConfig {
  title: string;
  logo: string;
  menuItems: MenuItem[];
}

export const LEADER_ONLY_ITEMS: MenuItem[] = [
  {
    key: 1,
    name: 'timetracker.menu.reviewTimeSheets',
    icon: <UserOutlined />,
    path: TIMETRACKER_PATH.REVIEW_TIMESHEETS,
  },
];

export const HOME_PAGE_LAYOUT: LayoutConfig = {
  title: 'common.title',
  logo: flowHrLogo,
  menuItems: [],
};

export const NOUSER_PAGE_LAYOUT: LayoutConfig = {
  title: 'common.title',
  logo: flowHrLogo,
  menuItems: [],
};
