import { SavedTimesheetType } from 'components/Timetracker/helpers';
import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class TimesheetService {
  getTimeSheet = (personId: number | string, date: string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getTimeSheet}/${personId}/${date}`,
      options: {
        method: 'POST',
      },
    });
  };

  getTimeSheetById = (timesheetId: number | string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getTimeSheetById}/${timesheetId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getTimeSheetByIdToEdit = (timesheetId: number | string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getTimeSheetByIdToEdit}${timesheetId}`,
      options: {
        method: 'GET',
      },
    });
  };

  saveTimeSheet = (timesheet: SavedTimesheetType): Promise<any> => {
    return apiService.request({
      url: Endpoints.saveTimeSheet,
      options: {
        method: 'POST',
        body: JSON.stringify(timesheet),
      },
    });
  };

  submitTimeSheet = (timesheetId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.submitTimeSheet}/${timesheetId}`,
      options: {
        method: 'PUT',
      },
    });
  };

  submitTimeSheetForReviewer = (timesheetId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.submitTimesheetForReviewer}/${timesheetId}`,
      options: {
        method: 'PUT',
      },
    });
  };

  getAllFilter = (
    date: DTO.Nullable<string>,
    page: number,
    pageSize: number,
    filter: Object
  ): Promise<DTO.TimeSheetsInfo> => {
    return apiService.request({
      url: `${Endpoints.getAllTimeSheets}${date ? `/${date}` : ''}`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          page,
          pageSize,
          filter,
          sortDirection: 'desc',
        }),
      },
    });
  };

  getAllFilterReviewer = (
    page: number,
    pageSize: number,
    startDate: string,
    endDate: string,
    filter: Object
  ): Promise<DTO.TimeSheetsInfo> => {
    return apiService.request({
      url: `${Endpoints.manageTimesheets}/${startDate}/${endDate}`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          page,
          pageSize,
          sortDirection: 'desc',
          filter,
        }),
      },
    });
  };

  getTimesheetByReviewerEdit = (timesheetId: string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.editTimesheetByReviewer}/${timesheetId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getTimesheetByReviewer = (timesheetId: string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.viewTimesheetByReviewer}/${timesheetId}`,
      options: {
        method: 'GET',
      },
    });
  };

  saveTimeSheetByReviewer = (timesheet: SavedTimesheetType): Promise<any> => {
    return apiService.request({
      url: Endpoints.saveTimesheetByReviewer,
      options: {
        method: 'POST',
        body: JSON.stringify(timesheet),
      },
    });
  };

  importTimesheetFile = (importedFile: DTO.ImportReport): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.importTimesheet}`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          attachment: importedFile.attachment,
          filename: importedFile.filename,
        }),
      },
    });
  };
}

export const timesheetService = new TimesheetService();
