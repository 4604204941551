import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  selectCurrentCompany,
  selectUserFullName,
  selectUserId,
  UNKNOWN,
} from 'store/auth/auth.selectors';
import {
  selectLocales,
  selectCurrentLocal,
} from 'store/locale/locale.selectors';
import {
  UserOutlined,
  LogoutOutlined,
  DownOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import { Button } from 'components/Button';
import Dropdown from 'components/Dropdown';
import { APP_PATH } from 'features/Routes/helpers';
import {
  removeFromSessionStorage,
  setToLocalStorage,
} from 'shared/utils/storage.utils';
import {
  LANGUAGE_ID,
  SESSION_USER,
  USER_PATHS,
} from 'shared/constants/common.const';
import { fetchLocaleById } from 'store/locale/locale.helpers';
import { setCurrentLocalData } from 'store/locale/locale.actions';
import { removeUser } from 'store/auth/auth.actions';
import { apiService } from 'shared/services';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import NoticeIcon from 'components/NotificationPanel';
import { notificationService } from 'shared/services/notification.service';
import { getErrorInfo } from 'shared/utils/error.utils';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import _ from 'lodash';
import { MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { hasOption } from 'shared/utils/common.utils';
import { DivContainer, DivWrapper, Divider, DivWrapperButton } from './styles';
import {
  formatSignal,
  NOTIFICATION_STATE_ID,
  SignalNotificationType,
  UserMenuKeys,
} from './helpers';

const UserProfile = (): JSX.Element => {
  const { t } = useTranslation();
  const currentCompanyId = useSelector(selectCurrentCompany);
  const name = useSelector(selectUserFullName);
  const userId = useSelector(selectUserId);
  const languages = useSelector(selectLocales);
  const currentLocal = useSelector(selectCurrentLocal);
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [notifications, setNotifications] = useState<DTO.Notification[]>([]);
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const [firstSignal, setFirstSignal] = useState(true);

  const removeUserAndRedirect = useCallback(() => {
    removeFromSessionStorage(SESSION_USER);
    removeFromSessionStorage(USER_PATHS);
    dispatch(removeUser());
    history.push(APP_PATH.LOGIN);
  }, [history, dispatch]);

  const handleMSALLogout = useCallback(
    (logoutType: string) => {
      if (logoutType === 'popup') {
        instance
          .logoutPopup({
            mainWindowRedirectUri: APP_PATH.LOGIN,
          })
          .then(() => {
            removeUserAndRedirect();
          });
      } else if (logoutType === 'redirect') {
        instance.logoutRedirect({
          postLogoutRedirectUri: '/',
        });
      }
    },
    [removeUserAndRedirect, instance]
  );

  const handleLogout = useCallback(() => {
    if (isAuthenticated) {
      handleMSALLogout('popup');
    } else {
      removeUserAndRedirect();
    }
  }, [handleMSALLogout, removeUserAndRedirect, isAuthenticated]);

  const dropdownMenuHandler = useCallback(
    (key) => {
      const locale = languages?.find((lang) => lang.id === parseInt(key, 10));

      if (locale) {
        fetchLocaleById(locale);
        dispatch(setCurrentLocalData(locale));
        setCurrentLocalData(locale);
        apiService.setLanguageId(String(locale.id));
        setToLocalStorage(LANGUAGE_ID, String(locale.id));
      }
    },
    [languages, dispatch]
  );
  const menuItems: MenuProps['items'] = useMemo(
    () =>
      languages?.map((lang) => {
        return {
          label: (
            <span
              tabIndex={0}
              role="button"
              onClick={() => dropdownMenuHandler(lang.id)}
              onKeyDown={(evnet) =>
                evnet.key === 'Enter' && dropdownMenuHandler(lang.id)
              }
            >
              {' '}
              {lang.name}{' '}
            </span>
          ),
          key: lang.id,
        };
      }),
    [languages, dropdownMenuHandler]
  );

  let userMenuItems: MenuProps['items'] = [];

  const buildMenu = () => {
    userMenuItems = [];
    if (hasOption('/home/manage-account')) {
      userMenuItems.push({
        label: (
          <Link to={APP_PATH.MANAGE_ACCOUNT}>
            {t('manageAccount.manageAccount')}
          </Link>
        ),
        key: UserMenuKeys.manageProfile,
      });
    }
  };

  window.addEventListener('buildUserMenu', () => {
    buildMenu();
  });

  buildMenu();

  function removeObjectWithId(arr: DTO.Notification[], id: number) {
    return arr.filter((obj) => obj.id !== id);
  }

  const getNotifications = useCallback(() => {
    notificationService
      .getAll()
      .then((response) => {
        const notReadArray = response.result.filter(
          (notification: DTO.Notification) =>
            notification.statusId === NOTIFICATION_STATE_ID.new
        );

        if (notReadArray.length >= 10) {
          setNotifications(notReadArray);
        } else
          setNotifications(_.orderBy(response.result, ['statusId'], ['desc']));
      })
      .catch((errorPromise: Promise<any>) => {
        getErrorInfo(errorPromise, t);
      });
  }, [t]);

  const handleCancelNotification = useCallback(
    (notificationId: number) => {
      notificationService
        .hideNotification(notificationId)
        .catch((errorPromise: Promise<any>) => {
          getErrorInfo(errorPromise, t);
        });
      setNotifications(removeObjectWithId(notifications, notificationId));
    },
    [notifications, t]
  );

  useEffect(() => {
    if (!userId || !currentCompanyId) {
      return;
    }
    getNotifications();
    const connect = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withUrl(`${window.APP_CONFIG.API_BASE_URI}/Notifications`)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, [getNotifications, userId, currentCompanyId]);

  const handleSignalConnections = useCallback(() => {
    if (!userId) {
      return;
    }
    const signalConnections = [
      'AddAbsenceNotification',
      'SendApprovedAbsenceNotification',
      'SendAmendedAbsenceNotification',
      'AddTimeSheetNotification',
      'SendTimeSheetApprovedNotification',
      'SendTimeSheetAmendedNotification',
      'CancelAbsenceNotification',
    ];

    if (connection && firstSignal) {
      setFirstSignal(false);
      connection
        .start()
        .then(() => {
          signalConnections.forEach((signal) => {
            connection.on(signal, (_id, message: string) => {
              const jsonSignal: SignalNotificationType = JSON.parse(message);
              if (jsonSignal.TeamsLeaderUserId === userId) {
                setNotifications((current) => [
                  formatSignal(jsonSignal),
                  ...current,
                ]);
              }
            });
          });
        })
        .catch((errorPromise: Promise<any>) => {
          getErrorInfo(errorPromise, t);
        });
    }
  }, [connection, firstSignal, t, userId]);

  useEffect(() => {
    handleSignalConnections();
  }, [handleSignalConnections]);

  const onItemClick = useCallback(
    (item: DTO.Notification) => {
      notificationService
        .readNotification(item.id)
        .catch((errorPromise: Promise<any>) => {
          getErrorInfo(errorPromise, t);
        })
        .finally(() => {
          window.location.href = item.url;
        });
    },
    [t]
  );

  const onClear = useCallback(() => {
    notificationService
      .clearNotifications(notifications.map((notification) => notification.id))
      .then((response) => {
        setNotifications(response.results);
      })
      .catch((errorPromise: Promise<any>) => {
        getErrorInfo(errorPromise, t);
      });
  }, [notifications, t]);

  const notHiddenNotifications = useCallback(() => {
    return notifications
      .filter(
        (notification) =>
          notification.statusId === NOTIFICATION_STATE_ID.new ||
          notification.statusId === NOTIFICATION_STATE_ID.read
      )
      .slice(0, 10);
  }, [notifications]);

  const activeNotifications = useCallback(() => {
    return notifications.filter(
      (notification) => notification.statusId === NOTIFICATION_STATE_ID.new
    );
  }, [notifications]);

  return (
    <DivContainer>
      {name !== UNKNOWN && (
        <>
          <DivWrapper>
            <Dropdown menu={{ items: userMenuItems }}>
              <Button type="text">
                <UserOutlined />
                <span>{name}</span>
              </Button>
            </Dropdown>
          </DivWrapper>
          <Divider />
        </>
      )}
      <DivWrapper>
        <Dropdown menu={{ items: menuItems }}>
          <Button type="text">
            <GlobalOutlined />
            <span>{currentLocal?.name}</span>
            <DownOutlined />
          </Button>
        </Dropdown>
      </DivWrapper>
      {(!!userId || isAuthenticated) && (
        <>
          <Divider />
          <DivWrapper>
            <Button onClick={handleLogout} type="text" block>
              <LogoutOutlined />
              {t('common.logout')}
            </Button>
          </DivWrapper>
          {!!currentCompanyId && (
            <DivWrapperButton>
              <NoticeIcon
                count={activeNotifications().length}
                onCancelNotification={handleCancelNotification}
                onItemClick={onItemClick}
                onClear={onClear}
                list={notHiddenNotifications()}
              />
            </DivWrapperButton>
          )}
        </>
      )}
    </DivContainer>
  );
};

export default memo(UserProfile);
