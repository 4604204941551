import styled from 'styled-components';

export const TagContainer = styled.div`
  font-size: 0.875rem;
  border-radius: 120px;
  font-weight: bold;
  padding: 13px 26px;
  background-color: #deefff;
  word-break: break-word;
`;
