import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class ExperienceService {
  getExperienceById = (personId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getExperienceById}/${personId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getExperienceByUser = (): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getExperienceByUser}`,
      options: {
        method: 'GET',
      },
    });
  };

  deleteExperience = (experienceId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.deleteExperience}/${experienceId}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  deleteExperienceByOwner = (experienceId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.deleteExperienceByOwner}/${experienceId}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  addExperience = (exp: DTO.AddExperience): Promise<any> => {
    return apiService.request({
      url: Endpoints.addExperience,
      options: {
        method: 'POST',
        body: JSON.stringify(exp),
      },
    });
  };

  addExperienceByUser = (exp: DTO.AddExperience): Promise<any> => {
    return apiService.request({
      url: Endpoints.addExperienceByUser,
      options: {
        method: 'POST',
        body: JSON.stringify(exp),
      },
    });
  };

  editExperience = (exp: DTO.AddExperience): Promise<any> => {
    return apiService.request({
      url: Endpoints.editExperience,
      options: {
        method: 'PUT',
        body: JSON.stringify(exp),
      },
    });
  };

  editExperienceByUser = (exp: DTO.AddExperience): Promise<any> => {
    return apiService.request({
      url: Endpoints.editExperienceByuser,
      options: {
        method: 'PUT',
        body: JSON.stringify(exp),
      },
    });
  };
}

export const experienceService = new ExperienceService();
