import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class StatusService {
  getAll = (): Promise<DTO.Statuses> => {
    return apiService.request({
      url: Endpoints.getStatuses,
      options: {
        method: 'GET',
      },
    });
  };
}

export const statusService = new StatusService();
