import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class PositionsService {
  getPositionsByCompany = (
    page: number,
    pageSize: number,
    filter?: {}
  ): Promise<DTO.PositionPagination<DTO.PositionsByCompany>> => {
    return apiService.request({
      url: Endpoints.getOpenPositionsByCompany,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: '',
          page,
          pageSize,
          sortColumn: 'PositionName',
          sortDirection: 'asc',
          total: 0,
          message: '',
          isSuccessful: true,
          filter: filter || {},
        }),
      },
    });
  };

  getPositionById = (
    id: string
  ): Promise<DTO.PositionResponse2<DTO.Position>> => {
    return apiService.request({
      url: `${Endpoints.getOpenPositionById}/${id}`,
      options: {
        method: 'GET',
      },
    });
  };

  getPositionTypes = (): Promise<DTO.PositionResponse<DTO.PositionType>> => {
    return apiService.request({
      url: `${Endpoints.getPositionTypes}`,
      options: {
        method: 'GET',
      },
    });
  };

  getPlacementPositionTypes = (): Promise<
    DTO.PositionResponse<DTO.PositionTypes>
  > => {
    return apiService.request({
      url: `${Endpoints.getPlacementPositionTypes}`,
      options: {
        method: 'GET',
      },
    });
  };

  getPlacementPositionSkillsByName = (): Promise<
    DTO.PositionResponse<DTO.PositionPossibleSkills>
  > => {
    return apiService.request({
      url: `${Endpoints.getPlacementPositionByName}`,
      options: {
        method: 'GET',
      },
    });
  };

  getPlacementPositionStatusByCompanyId = (id: string) => {
    return apiService.request({
      url: `${Endpoints.getPlacementPositionStatusByCompany}/${id}`,
      options: {
        method: 'GET',
      },
    });
  };

  getPlacementPositionClientName = (): Promise<
    DTO.PositionResponse<DTO.PositionClient>
  > => {
    return apiService.request({
      url: `${Endpoints.getPlacementPositionClientName}`,
      options: {
        method: 'GET',
      },
    });
  };

  getPlacementPositionRoles = (): Promise<
    DTO.PositionResponse<DTO.PositionRoles>
  > => {
    return apiService.request({
      url: `${Endpoints.getPlacementPositionRoles}`,
      options: {
        method: 'GET',
      },
    });
  };

  getPlacementPositionProject = (
    clientId: string | undefined,
    clientName: string | undefined
  ): Promise<DTO.PositionResponse<DTO.PositionProject>> => {
    let paramObject = {};
    if (clientId) {
      paramObject = {
        ...paramObject,
        clientId,
      };
    }
    if (clientName) {
      paramObject = {
        ...paramObject,
        clientName,
      };
    }
    const params = new URLSearchParams(paramObject).toString();
    return apiService.request({
      url: `${Endpoints.getPlacementPositionProject}?${params}`,
      options: {
        method: 'GET',
        params: {
          clientId,
          clientName,
        },
      },
    });
  };

  getPositionsSkillCategories = () => {
    return apiService.request<DTO.PositionResponse<DTO.PositionSkillCategory>>({
      url: Endpoints.getPlacementSkillCategories,
      options: {
        method: 'GET',
      },
    });
  };

  getPositionSkillLevel = (skillCategoryId: number) => {
    return apiService.request<DTO.PositionResponse<DTO.PositionSkillLevel>>({
      url: `${Endpoints.getPlacementSkillLevel}?skillTypeId=${skillCategoryId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getPositionSeniority = (): Promise<
    DTO.PositionResponse<DTO.PositionSeniority>
  > => {
    return apiService.request({
      url: `${Endpoints.getPositionSeniority}`,
      options: {
        method: 'GET',
      },
    });
  };

  getPositionPlacementProject = (): Promise<
    DTO.PositionResponse<DTO.PositionPlacementProject>
  > => {
    return apiService.request({
      url: `${Endpoints.getPlacementProjects}`,
      options: {
        method: 'GET',
      },
    });
  };

  updatePositionComments = (data: DTO.PositionComment) => {
    return apiService.request({
      url: Endpoints.updateOpenPositionComment,
      options: {
        method: 'POST',
        body: JSON.stringify(data),
      },
    });
  };

  getPositionCountry = (): Promise<DTO.Country[]> => {
    return apiService.request({
      url: `${Endpoints.getCountries}`,
      options: {
        method: 'GET',
      },
    });
  };

  deletePosition = (id: number | undefined) => {
    return apiService.request({
      url: `${Endpoints.deleteOpenPosition}/${id}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  reasonDisabled = (id: number, statusId: React.Key) => {
    return apiService.request({
      url: `${Endpoints.disableStatusOpenPosition}/${id}?reasonStatusId=${statusId}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  getProjectById = (projectId: number): Promise<DTO.ProjectsInfo> => {
    return apiService.request({
      url: `${Endpoints.getProjectById}${projectId}`,
      options: {
        method: 'GET',
      },
    });
  };

  savePositions = (
    positions: DTO.NewPositionDraft
  ): Promise<DTO.ActionResult<DTO.NewPositionResponse>> => {
    return apiService.request({
      url: `${Endpoints.saveOpenPosition}`,
      options: {
        method: 'POST',
        body: JSON.stringify(positions),
      },
    });
  };

  editPositions = (
    positions: Partial<DTO.NewPositionDraft>,
    positionId: number
  ) => {
    return apiService.request({
      url: `${Endpoints.editOpenPosition}/${positionId}`,
      options: {
        method: 'PUT',
        body: JSON.stringify(positions),
      },
    });
  };

  getCandidatesList = (
    params: DTO.GetCandidatesRequest
  ): Promise<DTO.GetCandidatesResponse> => {
    return apiService.request<DTO.GetCandidatesResponse>({
      url: Endpoints.getCandidatesList,
      options: {
        method: 'POST',
        body: JSON.stringify(params),
      },
    });
  };

  getCandidateStatusList = (): Promise<DTO.GetCandidateStatusListResponse> => {
    return apiService.request({
      url: Endpoints.getCandidateStatusList,
      options: {
        method: 'GET',
      },
    });
  };

  addCandidate = (params: DTO.AddCandidateRequest) => {
    return apiService.request<void>({
      url: Endpoints.addCandidate,
      options: {
        method: 'POST',
        body: JSON.stringify(params),
      },
    });
  };

  getCandidateById = (
    candidateId: string
  ): Promise<DTO.PositionResponse2<DTO.Candidate>> => {
    return apiService.request<DTO.PositionResponse2<DTO.Candidate>>({
      url: `${Endpoints.getCandidateById}${candidateId}`,
      options: {
        method: 'GET',
      },
    });
  };

  editCandidate = (
    params: Partial<DTO.AddCandidateRequest>,
    candidateId: string
  ) => {
    return apiService.request<DTO.PositionResponse2<DTO.Candidate>>({
      url: `${Endpoints.editCandidate}${candidateId}`,
      options: {
        method: 'POST',
        body: JSON.stringify(params),
      },
    });
  };

  addInterviewer = (props: {
    body: DTO.InterviewerBody;
  }): Promise<DTO.ActionResult<void>> => {
    const { body } = props;
    return apiService.request({
      url: Endpoints.addInterviewer,
      options: {
        method: 'POST',
        body: JSON.stringify(body),
      },
    });
  };

  getExpertise = (): Promise<DTO.Expertise[]> => {
    return apiService.request({
      url: Endpoints.getExpertise,
      options: {
        method: 'GET',
      },
    });
  };

  getInterviewers = (props: {
    body: DTO.InterviewerListBody;
  }): Promise<DTO.GridResults<DTO.InterviewerList>> => {
    const { body } = props;
    return apiService.request({
      url: Endpoints.getInterviewers,
      options: {
        method: 'POST',
        body: JSON.stringify(body),
      },
    });
  };

  getInterviewer = (props: {
    id: number;
  }): Promise<DTO.ActionResult<DTO.InterviewerList>> => {
    const { id } = props;
    return apiService.request({
      url: `${Endpoints.getInterviewerById}/${id}`,
      options: {
        method: 'GET',
      },
    });
  };

  deleteInterviewer = (props: {
    interviewerId: number;
  }): Promise<DTO.ActionResult<void>> => {
    const { interviewerId } = props;

    return apiService.request({
      url: `${Endpoints.deleteInterviewer}/${interviewerId}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  inactivateInterviewer = (props: {
    interviewerId: number;
  }): Promise<DTO.ActionResult<void>> => {
    const { interviewerId } = props;

    return apiService.request({
      url: `${Endpoints.inactivateInterviewer}/${interviewerId}`,
      options: {
        method: 'PUT',
      },
    });
  };

  activateInterviewer = (props: {
    interviewerId: number;
  }): Promise<DTO.ActionResult<void>> => {
    const { interviewerId } = props;

    return apiService.request({
      url: `${Endpoints.activateInterviewer}/${interviewerId}`,
      options: {
        method: 'PUT',
      },
    });
  };

  editInterviewer = (props: {
    body: DTO.InterviewerUpdateBody;
  }): Promise<DTO.ActionResult<void>> => {
    const { body } = props;

    return apiService.request({
      url: `${Endpoints.editInterviewer}`,
      options: {
        method: 'PUT',
        body: JSON.stringify(body),
      },
    });
  };

  getAvailableInterviewers = (props: {
    personName: string;
  }): Promise<DTO.PersonResponseReviewer> => {
    const { personName } = props;
    return apiService.request({
      url: `${Endpoints.getAvailableInterviewers}/?searchTerms=${personName}`,
      options: {
        method: 'GET',
      },
    });
  };
}

export const positionsService = new PositionsService();
