import { Button } from 'components/Button';
import styled from 'styled-components';
import Table from 'components/Timetracker/Table/index';

export const DivWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  background: ${({ theme }) => theme.colors.headerBackground};
  padding: 2vh 2vh 2vh 2vh;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  .save-btn {
    margin-right: 20px;
  }
`;

export const StyledButton = styled(Button)`
  max-width: 160px;
  margin: 0;
`;

export const WeekLabelStyled = styled.p`
  b {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const CenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const TableContainer = styled.div`
  margin-top: 20px;
  -webkit-box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
  border-radius: ${({ theme }) => theme.shape.generalBorderRadius};
  background: #ffffff;
  padding: 2vh 2vh 2vh 2vh;
`;
export const TableStyled = styled(Table)`
  background: blue;

  border: none !important;
  border-radius: 0px !important;
`;

export const IconStyled = styled.div`
  margin: 30px;

  .anticon {
    font-size: 70px;
    color: ${({ theme }) => theme.colors.grayBackground};
  }
`;

export const StyledMainTitle = styled.h2`
  margin: 30px 0;
  font-size: 28px;
  font-weight: 900;
  color: ${({ theme }) => theme.colors.mainColor};
`;

export const DateContainer = styled.div``;

export const TableWrapper = styled.div`
  -webkit-box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
  border-radius: ${({ theme }) => theme.shape.generalBorderRadius};
  background: #ffffff;
  padding: 2vh 2vh 2vh 2vh;
`;
