import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Popover from 'components/Popover';
import React, { FC, memo, useEffect, useState } from 'react';
import { FormModes } from 'shared/constants/common.const';
import { Badge } from 'antd';
import { ButtonPill, ButtonPillStars, CloseButton, RateStyled } from './styles';
import DeleleSVGIcon from './deleteIcon';

interface Props {
  data: DTO.PositionSkills;
  childIdDeleted: Function;
  formMode: FormModes;
  rate: number;
  count: number;
}

const EditPopover: FC<Props> = ({
  data,
  childIdDeleted,
  formMode,
  rate,
  count,
}: Props): JSX.Element => {
  const [idDeleted, setIdDeleted] = useState<number | string>();

  useEffect(() => {
    if (idDeleted) childIdDeleted(idDeleted);
  }, [idDeleted, childIdDeleted]);

  const DeleteIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={DeleleSVGIcon} {...props} />
  );
  return (
    <Popover key={data.id} placement="bottomLeft" trigger="click">
      {(formMode === FormModes.created ||
        formMode === FormModes.creating ||
        formMode === FormModes.edit) && (
        <Badge
          offset={[-18, 12]}
          count={
            <CloseButton
              type="link"
              onClick={() => {
                setIdDeleted(data.id);
              }}
              icon={<DeleteIcon />}
            />
          }
        >
          <ButtonPillStars key={data.id}>
            {data.name}
            <RateStyled count={count} disabled value={rate} />
          </ButtonPillStars>
        </Badge>
      )}
      {formMode === FormModes.viewing && (
        <ButtonPill key={data.id}>{data.name}</ButtonPill>
      )}
    </Popover>
  );
};

export default memo(EditPopover);
