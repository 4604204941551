import styled from 'styled-components';

export const EditorWrapper = styled.div`
  border: ${(props) =>
    props.className?.includes('error') ? '1px solid red' : '1px solid #d9d9d9'};
  border-radius: 4px;
  .rdw-editor-toolbar {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom: 1px solid #d9d9d9;
  }
  .public-DraftStyleDefault-block {
    padding: 10px 25px;
  }
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 20px;
`;

export const TextAreaBottomText = styled.div`
  display: flex;
  justify-content: space-between;
`;
