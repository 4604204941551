import React from 'react';
import { TooltipProps } from 'antd';
import { TooltipStyled } from './styles';

const Tooltip = ({ children, ...restProps }: TooltipProps) => {
  return (
    <>
      <TooltipStyled {...restProps}>{children}</TooltipStyled>
    </>
  );
};

export default Tooltip;
