import React, { useMemo, useState, useEffect } from 'react';
import TabsComponent from 'components/Tabs';
import { useTranslation } from 'react-i18next';
import { FormModes } from 'shared/constants/common.const';
import { useParams, useLocation } from 'react-router-dom';
import Form from './PersonalInformation';
import { DEFAULT_TAB, TabKey, UrlParams } from './helper';
import { Style } from './styles';

const Tabs = () => {
  const [searchParams, setSearchParams] = useState<URLSearchParams>();
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setSearchParams(queryParams);
  }, [location.search]);
  const params = useParams<UrlParams>();
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<string>(DEFAULT_TAB);

  const titleKey = useMemo(() => {
    if (params.mode === FormModes.creating) {
      return 'positions.candidate.createPerson';
    }
    if (params.mode === FormModes.editing) {
      return 'positions.actionTitles.editCandidate';
    }
    return 'positions.actionTitles.viewCandidate';
  }, [params]);

  const tabs = useMemo(() => {
    return [
      {
        label: t('timetracker.people.personalInformation'),
        key: TabKey.personalInformation,
        children: (
          <Form candidateId={searchParams?.get('candidateId') || null} />
        ),
      },
    ];
  }, [searchParams, t]);

  const onTabChange = (key: string) => {
    setCurrentTab(key);
  };

  return (
    <>
      <Style.SectionTitle>{t(titleKey)}</Style.SectionTitle>
      <TabsComponent
        type="card"
        defaultActiveKey={DEFAULT_TAB}
        activeKey={currentTab}
        onTabClick={onTabChange}
        items={tabs}
        tabPosition="left"
      />
    </>
  );
};

export default Tabs;
