import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class ReasonsService {
  getReasons = (contractType: number): Promise<DTO.AllReasons> => {
    return apiService.request({
      url: `${Endpoints.getReasons}?contractTypeId=${contractType}`,
      options: {
        method: 'GET',
      },
    });
  };

  saveReason = (reason: DTO.Reason): Promise<DTO.SingleReason> => {
    return apiService.request({
      url: Endpoints.getReasons,
      options: {
        method: 'POST',
        body: JSON.stringify(reason),
      },
    });
  };
}

export const reasonsService = new ReasonsService();
