export interface allocation {
  description: string;
  id: number;
  isActive: boolean;
}
export interface seniority {
  name: string;
  id: number;
  companyId: number;
}

export interface client {
  name: string;
}

export interface role {
  name: string;
}

export enum SkillTypes {
  PROFESSIONALSKILLS = 1,
  LANGUAGES = 3,
}

export interface projects {
  projectId: number;
  name: string;
  clientId: number;
  clientIdentification: string;
  countryName: string;
  clientName: string;
  isDeleted: true;
  startDate: string;
  endDate: string;
  type: string;
  rolIdSelected: string;
  createdAt: string;
  modifiedAt: string;
  isActive: boolean;
}
export interface positionType {
  createdAt: string;
  id: number;
  isActive: boolean;
  modifiedAt: string;
  positionTypeName: string;
}

export interface Skills {
  companyId: number;
  createdAt?: string;
  id: number | string;
  isActive: boolean;
  isDeleted: boolean;
  modifiedAt?: string;
  name: string;
  skillTypeId: number;
  skillLevelId?: number;
  skillLevel?: string;
}

export interface OpenPositionskill {
  skillId?: number | string;
  skillCategory?: string;
}

export interface PositionsInterface {
  id?: number;
  positionName: string;
  jobDescription: string;
  mainResponsabilities: string;
  seniorityId: number;
  allocationTypeId?: number;
  projectPositionId?: number;
  positionTypeId: number;
  positionCreationDate: string;
  positionStartDate: string;
  senioritytName?: string;
  rolName?: string;
  positionAllocations: any[];
  isActive: boolean;
  createdAt: string;
  modifiedAt: string;
  openPositionskill?: OpenPositionskill[] | [];
  openPositionskills?: OpenPositionskill[] | []; //! do not remove it, it is used in the view position
  openPositionSkills?: any[] | [];
}

export interface PositionsExtensionInterface {
  id?: number;
  positionName: string;
  jobDescription: string;
  mainResponsabilities: string;
  seniorityId: number;
  allocationTypeId?: number;
  projectId?: number;
  positionTypeId: number;
  positionCreationDate: string;
  positionStartDate: string;
  isActive: boolean;
  createdAt: string;
  modifiedAt: string;
  openPositionskill?: OpenPositionskill[] | [];
  openPositionskills?: OpenPositionskill[] | []; //! do not remove it, it is used in the view position
  seniorityName: string;
  allocDesc: string;
  projectName: string;
}

export enum AllSkillsEmun {
  MUSTHAVE = 'mustHave',
  NICETOHAVE = 'niceToHave',
  LANGUAGESKILLS = 'languageSkills',
}
export interface AllSkills {
  mustHave: Skills[];
  niceToHave: Skills[];
  languageSkills: Skills[];
}

export const INITIAL_SKILLS_DATA: AllSkills = {
  mustHave: [],
  niceToHave: [],
  languageSkills: [],
};

export interface AddPositionContext {
  currentPosition?: DTO.Position;
  hasChanged: boolean;
  setHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
}
