import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Rate } from 'antd';
import styled from 'styled-components';

export const CloseButton = styled(Button)`
  width: 20px;
  height: 20px;
`;

export const DeleteIcon = styled(CloseCircleFilled)`
  color: white;
  font-size: '16px';
`;

export const ButtonPill = styled.div`
  margin: 5px;
  background: #d4e9fc;
  padding: 20px 30px;
  border-radius: 85px;
  border: 2px solid #d4e9fc;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  font-weight: bold;
  height: 40px;
  :hover {
    background: #d4e9fc;
    border: 2px solid #d4e9fc;
    color: black;
    cursor: pointer;
  }
`;

export const ButtonPillStars = styled.div`
  margin: 5px;
  background: ${({ theme }) => theme.colors.ligthBlueBackground};
  padding: 8px 25px 8px 12px;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.ligthBlueBackground};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  font-weight: bold;
  height: 80px;
  :hover {
    background: ${({ theme }) => theme.colors.ligthBlueBackground};
    border: 2px solid ${({ theme }) => theme.colors.ligthBlueBackground};
    color: black;
    cursor: pointer;
  }
`;

export const RateStyled = styled(Rate)`
  font-size: 16px;
`;
