import { Button } from 'components/Button';
import styled from 'styled-components';

export const Style = {
  Container: styled.div``,
  Header: styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  `,
  SectionTitle: styled.h4`
    font-size: 1.25rem;
    font-weight: bold;
  `,
  Button: styled(Button)`
    margin-right: 30px;
  `,
  TableWrapper: styled.div`
    box-sizing: border-box;
    padding: 30px;
    background-color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    .ant-table-content {
      min-height: 0rem;
    }
    .ant-pagination {
      padding-top: 30px;
      display: flex;
      justify-content: flex-end;
    }
    > table {
      box-sizing: border-box;
      min-width: 100%;
    }
  `,
};

export const Space = styled.div`
  margin-bottom: 10px;
`;
