import React, { FC } from 'react';
import { FilePdfOutlined } from '@ant-design/icons';
import { manualObject, manuals } from './helper';
import {
  StyledManualAnchor,
  StyledManualLink,
  StyledUnorderedList,
} from './styles';

interface Prop {
  searchInput: string;
}

const FilteredManualList: FC<Prop> = ({ searchInput }): JSX.Element => {
  const searchKeywords = (keywordString: string, searchItem: string) => {
    const keywordArray = keywordString.split(' ');
    return !!keywordArray.some((word) =>
      word.toLowerCase().includes(searchItem.toLowerCase())
    );
  };

  return (
    <>
      <StyledUnorderedList>
        {manuals
          .filter((manual: manualObject) =>
            searchKeywords(manual.caption, searchInput)
          )
          .map((manual: manualObject) => (
            <StyledManualLink
              key={`manual-${manual.category}-${manual.manualLink}`}
            >
              <StyledManualAnchor
                href={manual.manualLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FilePdfOutlined /> {manual.caption}
              </StyledManualAnchor>
            </StyledManualLink>
          ))}
      </StyledUnorderedList>
    </>
  );
};

export default FilteredManualList;
