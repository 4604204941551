import { BellOutlined } from '@ant-design/icons';
import { Badge, Button, ButtonProps, Divider, List, Typography } from 'antd';
import styled from 'styled-components';

export const ButtonStyled = styled(Button)<ButtonProps>`
  :disabled {
    background-color: ${({ theme }) => theme.colors.primaryDisabled} !important;
    color: ${({ theme }) => theme.colors.white};
    &:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }
  position: absolute;
  right: 0;
  bottom: 0;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0 0 10px 10px;
  width: 100%;
  height: 45px;
`;

export const DivContainer = styled.div`
  margin-top: 50px;
`;

export const TitleDay = styled(Typography)`
  font-size: 14px;
`;

export const DividerStyled = styled(Divider)`
  margin: 2px !important;
  padding: 10px 0 !important;
`;

export const Description = styled.div`
  top: 20px;
  font-size: 13px;
  cursor: pointer;
`;

export const Title = styled.h2`
  font-weight: bold;
`;

export const ListStyled = styled(List.Item)`
  .ant-list-item-action {
    margin-left: auto;
  }

  .ant-list-item-meta-avatar {
    margin-right: 0px !important;
  }
  .ant-list-item-meta-title {
    margin: 4px;
  }
  .read {
    opacity: 0.6;
  }
`;

export const ListLi = styled.div`
  li {
    padding: 2px 0 !important;
    border-bottom: 0 !important;
  }
  span {
    margin-right: 8px !important;
  }
  .ant-avatar {
    background: ${({ theme }) => theme.colors.sidebarActive};
  }
  .read {
    opacity: 0.5;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const BellStyled = styled(BellOutlined)`
  cursor: pointer;
`;

export const BadgeStyled = styled(Badge)`
  cursor: pointer;
`;
export const NoNewNotifications = styled(Typography)`
  display: flex;
  justify-content: center;
`;
