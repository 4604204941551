import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabForm from 'components/Timetracker/Form/TabForm';
import {
  ActionsConfig,
  TabFormConfig,
} from 'components/Timetracker/Form/TabForm/helpers';
import { useForm } from 'components/Form';
import {
  FieldData,
  FormItemConfig,
  getOptions,
  Options,
  REGEXP,
} from 'components/Form/helpers';
import {
  cityService,
  clientService,
  peopleService,
  positionsService,
  stateService,
} from 'shared/services';
import { getErrorInfo } from 'shared/utils/error.utils';
import { useHistory, useParams } from 'react-router';
import {
  getRequiredFields,
  removeSpacesFromFormField,
  showNotification,
} from 'shared/utils/common.utils';
import { getCountryOptions } from 'features/Modules/Timetracker/Users/UserForm/helpers';
import { useWatch } from 'antd/lib/form/Form';
import { FieldRange } from 'shared/constants';
import moment from 'moment';
import { validatePhone } from 'shared/utils';
import { FormModes, WAIT_TIME } from 'shared/constants/common.const';
import _ from 'lodash';
import { PLACEMENT_PATH } from 'features/Routes/helpers';
import { LoadingOutlined } from '@ant-design/icons';
import { Style } from './styles';
import {
  CC_IDENTIFICATION_TYPE,
  DATE_FORMAT,
  formatCandidateToEdit,
  formatCandidateToFormField,
  FormFields,
  FormPlaceholders,
  FormTitles,
  MIN_AGE,
} from './helper';
import { UrlParams } from '../helper';
import { getStatusList } from '../../helper';

interface TabsProps {
  candidateId: string | null;
}

const CandidateForm: FC<TabsProps> = (props) => {
  const { candidateId } = props;
  const history = useHistory();
  const { mode } = useParams<UrlParams>();
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const [identificationTypes, setIdentificationTypes] = useState<Options[]>([]);
  const [genders, setGenders] = useState<Options[]>([]);
  const [countries, setCountries] = useState<Options[]>([]);
  const [cities, setCities] = useState<Options[]>([]);
  const [states, setStates] = useState<Options[]>([]);
  const [recruiters, setRecruiters] = useState<Options[]>([]);
  const [referrals, setReferrals] = useState<Options[]>([]);
  const [colombianCities, setColombianCities] = useState<Options[]>([]);
  const [statusList, setStatusList] = useState<Options[]>([]);
  const [roles, setRoles] = useState<Options[]>([]);
  const identificationType = useWatch<number>(
    FormFields.identificationType,
    form
  );
  const isReferred = useWatch<boolean>(FormFields.isReferred, form);

  const getIdentificationTypes = useCallback(async () => {
    try {
      const response = await peopleService.fetchIdentificationTypes();
      setIdentificationTypes(getOptions(response.results, false));
    } catch (error) {
      getErrorInfo(error as Promise<string>, t);
    }
  }, [t, setIdentificationTypes]);

  const getGenders = useCallback(async () => {
    try {
      const response = await peopleService.fetchGenders();
      const genderOptions = getOptions(
        response.result.map((gender) => {
          return { ...gender, name: t(gender.name) };
        }),
        false
      );

      setGenders(genderOptions);
    } catch (error) {
      setGenders([]);
      getErrorInfo(error as Promise<string>, t);
    }
  }, [t]);

  const getCountries = useCallback(() => {
    clientService
      .fetchCountries()
      .then((response) => {
        setCountries(getCountryOptions(response, t));
      })
      .catch((errorPromise: Promise<any>) => {
        getErrorInfo(errorPromise, t);
      });
  }, [t, setCountries]);

  const getStates = useCallback(
    async (countryId: string) => {
      try {
        const response = await stateService.getStatesByCountry(countryId);
        setStates(getOptions(response.results, false));
      } catch (error) {
        setStates([]);
        getErrorInfo(error as Promise<string>, t);
      } finally {
        form.setFieldValue(FormFields.state, undefined);
        form.setFieldValue(FormFields.city, undefined);
      }
    },
    [form, t]
  );

  const getCities = useCallback(
    async (countryId: string, stateId: string) => {
      try {
        const response = await cityService.getCitiesByState(countryId, stateId);
        setCities(getOptions(response.results, false));
      } catch (error) {
        setCities([]);
        getErrorInfo(error as Promise<string>, t);
      } finally {
        form.setFieldValue(FormFields.city, undefined);
      }
    },
    [form, t]
  );

  const getColombianCities = useCallback(async () => {
    try {
      const response = await cityService.getColombiaCities();

      setColombianCities(getOptions(response.results, false));
    } catch (error) {
      setColombianCities([]);
      getErrorInfo(error as Promise<string>, t);
    }
  }, [t]);

  const getRoles = useCallback(async () => {
    try {
      const response = await positionsService.getPlacementPositionRoles();
      setRoles(getOptions(response.results, false));
    } catch (error) {
      setRoles([]);
      getErrorInfo(error as Promise<string>, t);
    }
  }, [t]);

  const getActivePersons = useCallback(
    async (
      setter: React.Dispatch<React.SetStateAction<Options<string | number>[]>>,
      name: string
    ) => {
      try {
        const response = await peopleService.getActivePersonsInACompanyGroup(
          name
        );

        if (response.results) {
          const personOptions = response.results.map((person) => ({
            id: person.personId,
            name: person.fullName,
          }));

          setter(getOptions(personOptions, false));
        }
      } catch (error) {
        setter([]);
        getErrorInfo(error as Promise<string>, t);
      }
    },
    [t]
  );

  const addCandidate = useCallback(
    async (values: DTO.AddCandidateRequest) => {
      try {
        await positionsService.addCandidate({
          ...values,
          isActive: true,
          isDeleted: false,
          birthDate: new Date(values.birthDate).toISOString().split('T')[0],
        });
        history.push(PLACEMENT_PATH.CANDIDATES);
        showNotification(
          t,
          {
            type: 'success',
            description: t('common.saveSuccessful'),
          },
          false
        );
      } catch (error) {
        getErrorInfo(error as Promise<string>, t);
      }
    },
    [history, t]
  );

  const editCandidate = useCallback(async () => {
    if (candidateId) {
      try {
        const candidate = formatCandidateToEdit(
          form.getFieldsValue(),
          isReferred
        );
        await positionsService.editCandidate(candidate, candidateId);
        showNotification(
          t,
          {
            type: 'success',
            description: t('common.editSuccessful'),
          },
          false
        );
        history.push(PLACEMENT_PATH.CANDIDATES);
      } catch (e) {
        getErrorInfo(e as Promise<string>, t);
      }
    }
  }, [candidateId, form, isReferred, t, history]);

  const getActivePersonsDebounced = useMemo(
    () => _.debounce(getActivePersons, WAIT_TIME),
    [getActivePersons]
  );

  const isIdentificationCCType = useMemo(() => {
    return identificationType === CC_IDENTIFICATION_TYPE;
  }, [identificationType]);

  const formFieldsConfig: FormItemConfig[] = useMemo(() => {
    return [
      {
        key: FormFields.basicInfo,
        label: t(FormTitles.basicInfo),
        name: FormFields.basicInfo,
        type: 'section',
        isSection: true,
      },
      {
        key: FormFields.email,
        label: t(FormTitles.email),
        name: FormFields.email,
        placeholder: t(FormPlaceholders.email),
        disabled: mode === FormModes.viewing || mode === FormModes.editing,
        type: 'input',
        onChangeHandler: () =>
          removeSpacesFromFormField(form, FormFields.email),
        rules: [
          {
            required: mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
          {
            pattern: new RegExp(REGEXP.EMAIL),
            message: t('timetracker.people.emailAddressNotValid'),
          },
        ],
      },
      {
        key: `${FormFields.hidden}-1`,
        label: t(FormTitles.hidden),
        name: `${FormFields.hidden}-1`,
        placeholder: t(FormPlaceholders.hidden),
        type: 'hidden',
      },
      {
        key: `${FormFields.hidden}-2`,
        label: t(FormTitles.hidden),
        name: `${FormFields.hidden}-2`,
        placeholder: t(FormPlaceholders.hidden),
        type: 'hidden',
      },
      {
        key: FormFields.identificationType,
        label: t(FormTitles.identificationType),
        name: FormFields.identificationType,
        placeholder: t(FormPlaceholders.identificationType),
        disabled: mode === FormModes.viewing,
        type: 'select',
        onChangeHandler: () =>
          form.setFieldValue(FormFields.issuedIn, undefined),
        options: identificationTypes,
        rules: [
          {
            required: mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
        ],
      },
      {
        key: FormFields.identification,
        label: t(FormTitles.identification),
        name: FormFields.identification,
        placeholder: t(FormPlaceholders.identification),
        disabled: mode === FormModes.viewing,
        type: 'input',
        onChangeHandler: () =>
          removeSpacesFromFormField(form, FormFields.identification),
        rules: [
          {
            required: mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
          {
            max: FieldRange.identification.max,
            message: t('common.fieldMustNotExceedXCharacters', {
              x: FieldRange.identification.max,
            }),
          },
        ],
      },
      {
        key: FormFields.issuedIn,
        label: t(FormTitles.issuedIn),
        name: FormFields.issuedIn,
        placeholder: t(FormPlaceholders.issuedIn),
        type: 'select',
        options: colombianCities,
        disabled: !isIdentificationCCType || mode === FormModes.viewing,
        rules: [
          {
            required: isIdentificationCCType && mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
        ],
      },
      {
        key: FormFields.names,
        label: t(FormTitles.names),
        name: FormFields.names,
        placeholder: t(FormPlaceholders.names),
        disabled: mode === FormModes.viewing,
        type: 'input',
        onChangeHandler: () =>
          removeSpacesFromFormField(form, FormFields.names, true),
        rules: [
          {
            required: mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
          {
            pattern: REGEXP.ALPHANUMERIC_SPACE,
            message: t('common.fieldInvalidCharacters'),
          },
          {
            max: FieldRange.names.max,
            message: t('common.fieldMustNotExceedXCharacters', {
              x: FieldRange.names.max,
            }),
          },
        ],
      },
      {
        key: FormFields.firstLastName,
        label: t(FormTitles.firstLastName),
        name: FormFields.firstLastName,
        placeholder: t(FormPlaceholders.firstLastName),
        disabled: mode === FormModes.viewing,
        type: 'input',
        onChangeHandler: () =>
          removeSpacesFromFormField(form, FormFields.firstLastName, true),
        rules: [
          {
            required: mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
          {
            pattern: REGEXP.ALPHANUMERIC_SPACE,
            message: t('common.fieldInvalidCharacters'),
          },
          {
            max: FieldRange.firstLastName.max,
            message: t('common.fieldMustNotExceedXCharacters', {
              x: FieldRange.firstLastName.max,
            }),
          },
        ],
      },
      {
        key: FormFields.secondLastName,
        label: t(FormTitles.secondLastName),
        name: FormFields.secondLastName,
        placeholder: t(FormPlaceholders.secondLastName),
        disabled: mode === FormModes.viewing,
        type: 'input',
        onChangeHandler: () =>
          removeSpacesFromFormField(form, FormFields.secondLastName, true),
        rules: [
          {
            pattern: REGEXP.ALPHANUMERIC_SPACE,
            message: t('common.fieldInvalidCharacters'),
          },
          {
            max: FieldRange.secondLastName.max,
            message: t('common.fieldMustNotExceedXCharacters', {
              x: FieldRange.secondLastName.max,
            }),
          },
        ],
      },
      {
        key: FormFields.dateOfBirth,
        label: t(FormTitles.dateOfBirth),
        name: FormFields.dateOfBirth,
        placeholder: t(FormPlaceholders.dateOfBirth),
        disabled: mode === FormModes.viewing,
        type: 'date',
        dateConfig: {
          dateFormat: DATE_FORMAT,
          disabledDate: (current: moment.Moment) =>
            current > moment().startOf('day').add(-MIN_AGE, 'years'),
          defaultPickerValue: moment().startOf('day').add(-MIN_AGE, 'years'),
        },
        rules: [
          {
            required: mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
        ],
      },
      {
        key: FormFields.nationality,
        label: t(FormTitles.nationality),
        name: FormFields.nationality,
        placeholder: t(FormPlaceholders.nationality),
        disabled: mode === FormModes.viewing,
        type: 'select',
        options: countries,
        rules: [
          {
            required: mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
        ],
      },
      {
        key: FormFields.gender,
        label: t(FormTitles.gender),
        name: FormFields.gender,
        placeholder: t(FormPlaceholders.gender),
        disabled: mode === FormModes.viewing,
        type: 'select',
        options: genders,
        rules: [
          {
            required: mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
        ],
      },
      {
        key: FormFields.contactInfo,
        label: t(FormTitles.contactInfo),
        name: FormFields.contactInfo,
        placeholder: t(FormPlaceholders.contactInfo),
        type: 'section',
        isSection: true,
      },
      {
        key: FormFields.residentCountry,
        label: t(FormTitles.residentCountry),
        name: FormFields.residentCountry,
        placeholder: t(FormPlaceholders.residentCountry),
        disabled: mode === FormModes.viewing,
        type: 'select',
        options: countries,
        onChangeHandler: (countryId: number) => getStates(countryId.toString()),
        rules: [
          {
            required: mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
        ],
      },
      {
        key: FormFields.state,
        label: t(FormTitles.state),
        name: FormFields.state,
        placeholder: t(FormPlaceholders.state),
        disabled: mode === FormModes.viewing,
        type: 'select',
        options: states,
        onChangeHandler: (stateId: number) =>
          getCities(
            form.getFieldValue(FormFields.residentCountry),
            stateId.toString()
          ),
        rules: [
          {
            required: mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
        ],
      },
      {
        key: FormFields.city,
        label: t(FormTitles.city),
        name: FormFields.city,
        placeholder: t(FormPlaceholders.city),
        disabled: mode === FormModes.viewing,
        type: 'select',
        options: cities,
        rules: [
          {
            required: mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
        ],
      },
      {
        key: FormFields.phoneNumber,
        label: t(FormTitles.phoneNumber),
        name: FormFields.phoneNumber,
        placeholder: t(FormPlaceholders.phoneNumber),
        disabled: mode === FormModes.viewing,
        type: 'phone',
        onChangeHandler: () =>
          removeSpacesFromFormField(form, FormFields.phoneNumber),
        rules: [
          {
            required: mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
          {
            min: FieldRange.phone.min,
            message: t('common.fieldMustHaveAtLeastXCharacters', {
              x: FieldRange.phone.min,
            }),
          },
          {
            max: FieldRange.phone.max,
            message: t('common.fieldMustNotExceedXCharacters', {
              x: FieldRange.phone.max,
            }),
          },
          {
            pattern: new RegExp(REGEXP.PHONE_START),
            message: t('common.fieldInvalidCharacters'),
          },
          {
            validator: (_rule: unknown, value: string) => {
              const isValid = validatePhone(value);
              if (!isValid) {
                return Promise.reject(t('common.invalidPhoneNumber'));
              }

              return Promise.resolve(true);
            },
          },
        ],
      },
      {
        key: FormFields.zipCode,
        label: t(FormTitles.zipCode),
        name: FormFields.zipCode,
        disabled: mode === FormModes.viewing,
        placeholder: t(FormPlaceholders.zipCode),
        type: 'input',
        onChangeHandler: () =>
          removeSpacesFromFormField(form, FormFields.zipCode),
        rules: [
          {
            pattern: REGEXP.ALPHANUMERIC,
            message: t('common.fieldInvalidCharacters'),
          },
          {
            min: FieldRange.zipCode.min,
            message: t('common.fieldMustHaveAtLeastXCharacters', {
              x: FieldRange.zipCode.min,
            }),
          },
          {
            max: FieldRange.zipCode.max,
            message: t('common.fieldMustNotExceedXCharacters', {
              x: FieldRange.zipCode.max,
            }),
          },
        ],
      },
      {
        key: FormFields.address,
        label: t(FormTitles.address),
        name: FormFields.address,
        placeholder: t(FormPlaceholders.address),
        disabled: mode === FormModes.viewing,
        type: 'input',
        onChangeHandler: () =>
          removeSpacesFromFormField(form, FormFields.address, true),
        rules: [
          {
            required: mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
          {
            max: FieldRange.address.max,
            message: t('common.fieldMustNotExceedXCharacters', {
              x: FieldRange.address.max,
            }),
          },
        ],
      },
      {
        key: FormFields.recruiter,
        label: t(FormTitles.recruiter),
        name: FormFields.recruiter,
        type: 'section',
        isSection: true,
      },
      {
        key: FormFields.recruiterName,
        label: t(FormTitles.recruiterName),
        name: FormFields.recruiterName,
        placeholder: t(FormPlaceholders.recruiterName),
        type: 'select',
        disabled: mode === FormModes.viewing,
        options: recruiters,
        onSearch: (value: string) =>
          getActivePersonsDebounced(setRecruiters, value),
        rules: [
          {
            required: mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
        ],
      },
      {
        key: FormFields.isReferred,
        label: t(FormTitles.isReferred),
        name: FormFields.isReferred,
        placeholder: t(FormPlaceholders.isReferred),
        disabled: mode === FormModes.viewing,
        type: 'switch',
        checked: 'checked',
        switchConfig: {
          defaultChecked: false,
          checkedChildren: t('common.yesDiacritical'),
          unCheckedChildren: t('common.no'),
        },
      },
      {
        key: FormFields.nameOfReferral,
        label: t(FormTitles.nameOfReferral),
        name: FormFields.nameOfReferral,
        placeholder: t(FormPlaceholders.nameOfReferral),
        type: 'select',
        options: referrals,
        onSearch: (value: string) =>
          getActivePersonsDebounced(setReferrals, value),
        disabled: !isReferred || mode === FormModes.viewing,
        rules: [
          {
            required: isReferred && mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
        ],
      },
      {
        key: FormFields.role,
        label: t(FormTitles.role),
        name: FormFields.role,
        placeholder: t(FormPlaceholders.role),
        type: 'autocomplete',
        disabled: mode === FormModes.viewing,
        options: roles,
        rules: [
          {
            required: mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
          {
            min: FieldRange.role.min,
            message: t('common.fieldMustHaveAtLeastXCharacters', {
              x: FieldRange.role.min,
            }),
          },
          {
            max: FieldRange.role.max,
            message: t('common.fieldMustNotExceedXCharacters', {
              x: FieldRange.role.max,
            }),
          },
        ],
      },
      {
        key: FormFields.status,
        label: t(FormTitles.status),
        name: FormFields.status,
        placeholder: t(FormPlaceholders.status),
        type: 'select',
        disabled: mode === FormModes.viewing,
        options: statusList,
        rules: [
          {
            required: mode !== FormModes.viewing,
            message: t('common.fieldIsRequired'),
          },
        ],
      },
    ];
  }, [
    t,
    mode,
    identificationTypes,
    colombianCities,
    isIdentificationCCType,
    countries,
    genders,
    states,
    cities,
    recruiters,
    referrals,
    isReferred,
    roles,
    statusList,
    form,
    getStates,
    getCities,
    getActivePersonsDebounced,
  ]);

  const requiredFields = useMemo(
    () => getRequiredFields(formFieldsConfig),
    [formFieldsConfig]
  );

  const tabFormConfig: TabFormConfig = useMemo(() => {
    return {
      formMode: mode,
      formInstance: form,
      formFieldsConfig,
      fieldsData: [] as FieldData[],
      spanCol: 8,
    };
  }, [form, formFieldsConfig, mode]);

  const actionsConfig: ActionsConfig = useMemo(() => {
    return {
      onSubmit: (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        addCandidate(form.getFieldsValue());
      },
      onFinish: addCandidate,
      onEdit: editCandidate,
      pathToRedirect: PLACEMENT_PATH.CANDIDATES,
    };
  }, [addCandidate, editCandidate, form]);

  const getCandidateById = useCallback(async () => {
    if (
      candidateId &&
      identificationTypes.length &&
      colombianCities.length &&
      genders.length &&
      statusList.length &&
      roles.length
    ) {
      setIsLoading(true);
      const { result } = await positionsService.getCandidateById(candidateId);
      const statesResult = await stateService.getStatesByCountry(
        result.countryId
      );
      const citiesByCountry = await cityService.getCitiesByState(
        result.countryId,
        result.stateId
      );
      const selectedCities = getOptions(citiesByCountry.results, false);
      setCities(selectedCities);
      const selectedStates = getOptions(statesResult.results);
      setStates(selectedStates);
      const recruiterResult = await peopleService.getPerson(result.recruiterId);
      setRecruiters(
        getOptions(
          [
            {
              id: recruiterResult.result.personId || 0,
              name: recruiterResult.result.fullName,
            },
          ],
          false
        )
      );
      let referred: number | undefined;
      if (result.isReferred) {
        const referredResult = await peopleService.getPerson(result.referredId);
        setReferrals(
          getOptions(
            [
              {
                id: referredResult.result.personId || 0,
                name: referredResult.result.fullName,
              },
            ],
            false
          )
        );
        referred = referredResult.result.personId;
      }
      const candidate = formatCandidateToFormField(
        result,
        colombianCities,
        selectedStates,
        genders,
        identificationTypes,
        recruiterResult.result,
        referred,
        statusList,
        roles,
        selectedCities
      );
      form.setFieldsValue(candidate);
      setIsLoading(false);
    }
  }, [
    candidateId,
    identificationTypes,
    colombianCities,
    genders,
    statusList,
    roles,
    form,
  ]);

  const initialize = useCallback(async () => {
    const fetchIdentificationTypes = getIdentificationTypes();
    const fetchGenders = getGenders();
    const fetchCountries = getCountries();
    const fetchColombianCities = getColombianCities();
    const fetchRoles = getRoles();
    const fetchStatusList = getStatusList(t);
    form.setFieldsValue({ [FormFields.isReferred]: false });
    const [statusListResponse] = await Promise.all([
      fetchStatusList,
      fetchIdentificationTypes,
      fetchGenders,
      fetchCountries,
      fetchColombianCities,
      fetchRoles,
    ]);
    setIsLoading(false);
    setStatusList(statusListResponse);
  }, [
    form,
    getColombianCities,
    getCountries,
    getGenders,
    getIdentificationTypes,
    getRoles,
    t,
  ]);

  useEffect(() => {
    getCandidateById();
  }, [getCandidateById]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (mode !== FormModes.viewing && mode !== FormModes.editing) {
      form.setFieldValue(FormFields.issuedIn, undefined);
    }
  }, [mode, form, identificationType]);

  useEffect(() => {
    if (mode !== FormModes.viewing && mode !== FormModes.editing) {
      form.setFieldValue(FormFields.nameOfReferral, undefined);
    }
  }, [mode, form, isReferred]);

  return (
    <>
      {isLoading ? (
        <LoadingOutlined />
      ) : (
        <Style.Form>
          <TabForm
            viewingTypeButton="default"
            viewingLabelButton="common.cancel"
            showRequiredFieldText={FormModes.viewing !== mode}
            tabFormConfig={tabFormConfig}
            isSaved={false}
            actionsConfig={actionsConfig}
            requiredFields={requiredFields}
          />
        </Style.Form>
      )}
    </>
  );
};

export default CandidateForm;
