export function formatDayString(dayIndex: number) {
  return [
    'common.weekDays.sun',
    'common.weekDays.mon',
    'common.weekDays.tue',
    'common.weekDays.wed',
    'common.weekDays.thu',
    'common.weekDays.fri',
    'common.weekDays.sat',
  ][dayIndex];
}
// ADD LANGUAGE KEYS
export function formatMonthString(monthIndex: number) {
  return [
    'common.months.01',
    'common.months.02',
    'common.months.03',
    'common.months.04',
    'common.months.05',
    'common.months.06',
    'common.months.07',
    'common.months.08',
    'common.months.09',
    'common.months.10',
    'common.months.11',
    'common.months.12',
  ][monthIndex];
}

export function formatDatetoTitle(date: Date, t: Function) {
  const dayString = t(formatDayString(date.getDay()));
  const month = `${t(formatMonthString(date.getMonth())).slice(0, 3)}.`;
  const dayNumber = date.getDate();
  return `${dayString} \n ${month} ${dayNumber}`;
}
