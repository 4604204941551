import { CheckOutlined, MinusCircleFilled } from '@ant-design/icons';
import { Divider, Typography } from 'antd';
import styled from 'styled-components';

export const PopoverContainer = styled.div``;

export const TitlePopover = styled.div`
  height: 10px;
  margin-top: 10px;
`;

export const SkillName = styled.div`
  width: 150px;
  margin-left: '15px';
`;

export const DividerStyled = styled(Divider)`
  margin: 10px 0;
`;

export const PopoverTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .ant-typography {
    margin-left: 15px;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 10px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DeleteButton = styled(Typography)`
  text-decoration: underline;
  color: red;
`;

export const DefaultButton = styled(Typography)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.mainColor};
`;

export const RatingComponent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 15px;
  align-items: center;
`;

export const CheckOutlinedStyled = styled(CheckOutlined)`
  color: #00de59;
  font-size: 18px;
`;

export const MinusFilledStyled = styled(MinusCircleFilled)`
  color: red;
  font-size: 18px;
`;

export const RatingName = styled.span`
  margin-left: 20px;
  width: 80px;
  text-align: right;
  font-weight: bold;
`;
