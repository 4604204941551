import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PLACEMENT_PATH } from 'features/Routes/helpers';
import { Button } from 'components/Button';
import { hasPermission } from 'shared/utils/common.utils';
import PositionsTable from './Positions/PositionsTable';
import { DivContainer, DivButton, Title } from './styles';

const PositionsHome: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <DivContainer>
        <Title>{t('positions.list')}</Title>
        {hasPermission(
          'placement.openPositions.openPositions_add',
          PLACEMENT_PATH.POSITIONS
        ) && (
          <DivButton>
            <Button type="primary">
              <Link to={PLACEMENT_PATH.ADD_POSITION}>{t('common.add')}</Link>
            </Button>
          </DivButton>
        )}
      </DivContainer>

      {hasPermission(
        'placement.openPositions.openPositions_viewAll',
        PLACEMENT_PATH.POSITIONS
      ) && <PositionsTable />}
    </>
  );
};

export default PositionsHome;
