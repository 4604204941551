import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class AuthService {
  login = (userLogin: DTO.AuthenticateByEmailOrUserName): Promise<DTO.User> => {
    return apiService.request({
      url: Endpoints.login,
      options: {
        method: 'POST',
        body: JSON.stringify(userLogin),
      },
    });
  };

  changePasswordFirstTime = (
    newPassword: DTO.ChangePasswordFirstTimeBody
  ): Promise<void> => {
    return apiService.request({
      url: Endpoints.changePasswordFirstTime,
      options: {
        method: 'PUT',
        body: JSON.stringify(newPassword),
      },
    });
  };

  sendEmailPasswordRecovery = (body: DTO.RequestPasswordRecoveryBody) => {
    return apiService.request({
      url: Endpoints.sendEmailPasswordRecovery,
      options: {
        method: 'POST',
        body: JSON.stringify(body),
      },
    });
  };

  changePasswordFromEmail = (body: DTO.ChangePasswordFromEmailBody) => {
    return apiService.request({
      url: Endpoints.changePasswordFromEmail,
      options: {
        method: 'PUT',
        body: JSON.stringify(body),
      },
    });
  };

  validateRecoveryPasswordToken = (
    body: DTO.ValidateRecoveryPasswordTokenBody
  ) => {
    return apiService.request({
      url: Endpoints.validateRecoveryPasswordToken,
      options: {
        method: 'POST',
        body: JSON.stringify(body),
      },
    });
  };
}

export const authService = new AuthService();
