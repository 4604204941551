import { LineRuleType } from 'docx';
import { DocxTheme } from './docxTheme';

export interface Theme {
  colors: {
    black: string;
    white: string;
    red: string;
    blue: string;
    seaBlue: string;
    orange: string;
    background: string;
    grayBackground: string;
    grayModalBackground: string;
    grayLight: string;
    green: string;
    inputBorder: string;
    sidebar: string;
    sidebarActive: string;
    mainColor: string;
    outlines: string;
    defaultText: string;
    primaryText: string;
    secondaryText: string;
    grayTransparent: string;
    textDisabled: string;
    backgroundDisabled: string;
    backgroundDisabledRGA: string;
    headerBackground: string;
    modalHeaderBackground: string;
    hourBackgroundDark: string;
    primaryDisabled: string;
    boxShadow: string;
    links: string;
    horizontalLine: string;
    ligthBlueBackground: string;
    footerText: string;
    inputError: string;
    lightBlue700: string;
    status: {
      accepted: string;
      notApproved: string;
      amending: string;
      pending: string;
      sent: string;
      canceled: string;
      voided: string;
    };
    activeStatus: {
      active: string;
      inactive: string;
      pending: string;
    };
  };
  fontWeight: {
    normal: string;
    bold: string;
  };
  buttonSize: {
    width: string;
    height: string;
  };
  selectSizeType: {
    size: string;
  };
  shape: {
    generalBorderRadius: string;
  };
  opacity: {
    low: number;
    medium: number;
    high: number;
  };
  docxTheme: DocxTheme;
}

export const docxTheme = {
  fonts: {
    default: 'Calibri',
    heading: 'Arial Black',
  },
  colors: {
    primary: '234061',
    secondary: '006FB6',
    text: '000000',
    lightText: '666666',
    background: 'f2f2f2',
  },
  paragraphStyles: {
    default: {
      font: 'Calibri',
      size: 22,
      color: '000000',
    },
    heading1: {
      font: 'Arial Black',
      size: 38,
      color: '234061',
    },
    heading2: {
      font: 'Arial Black',
      size: 32,
      color: '234061',
    },
    bullet: {
      level: 0,
    },
  },
  spacing: {
    line: {
      default: 240,
      rule: LineRuleType.EXACT,
    },
    paragraph: {
      before: 0,
      after: 120,
    },
  },
  header: {
    background: '006FB6',
    color: 'FFFFFF',
    fontSize: 36,
  },
  footer: {
    color: 'A7A7A7',
    fontSize: 16,
  },
  table: {
    cellMargin: {
      top: 100,
      bottom: 100,
      left: 100,
      right: 100,
    },
  },
};

export const theme: Theme = {
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    red: 'rgba(255, 0, 0, 0.5)',
    blue: '#1890FF',
    seaBlue: '#2d63c8',
    orange: '#F4A101',
    green: '#00de59',
    background: '#F7F8FC',
    grayBackground: '#bfbfbf',
    grayModalBackground: '#F9FAFF',
    ligthBlueBackground: '#d4e9fc',
    grayLight: '#fafafa',
    grayTransparent: 'rgba(0, 0, 0, 0.42)',
    inputBorder: '#d9d9d9',
    sidebar: '#253370',
    sidebarActive: '#303A78',
    mainColor: '#1890FF',
    outlines: '#C4C4C4',
    primaryText: '#262626',
    secondaryText: '#9FA2B4',
    defaultText: 'rgba(0, 0, 0, 0.85)',
    textDisabled: 'rgba(20, 20, 20, 0.5)',
    backgroundDisabled: '#f5f5f5',
    modalHeaderBackground: '#006FB6',
    backgroundDisabledRGA: 'rgba(191, 191, 191, 0.5)',
    hourBackgroundDark: '#e6e6e6',
    primaryDisabled: '#B5DBFF',
    boxShadow: 'rgba(0, 0, 0, 0.02)',
    links: '#1890FF',
    horizontalLine: '#F1F1F1',
    headerBackground: '#EFF2F7',
    footerText: '#BCBCBC',
    inputError: '#ff4d4f',
    lightBlue700: '#338FD3',
    status: {
      accepted: '#27C867',
      notApproved: '#D91111',
      amending: '#D91111',
      pending: '#969696',
      sent: '#1890FF',
      canceled: '#bfbfbf',
      voided: '#fa9828',
    },
    activeStatus: {
      active: '#27C867',
      inactive: '#C4C4C4',
      pending: '#969696',
    },
  },
  fontWeight: {
    normal: '400',
    bold: '800',
  },
  buttonSize: {
    width: '100px',
    height: '40px',
  },
  selectSizeType: {
    size: 'default',
  },
  shape: {
    generalBorderRadius: '4px',
  },
  opacity: {
    low: 0.2,
    medium: 0.4,
    high: 1,
  },
  docxTheme,
};
