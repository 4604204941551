import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Modal from '..';
import { DeletionModalProps } from './helper';
import { Description } from './style';

function ConfirmationModalBase(props: DeletionModalProps) {
  const { t } = useTranslation();
  const { confirm, cancel, showModalState, title, description } = props;
  const [isShowingModal, setIsShowingModal] = showModalState;

  const onOk = useCallback(() => {
    setIsShowingModal(false);
    confirm?.();
  }, [confirm, setIsShowingModal]);

  const onCancel = useCallback(() => {
    setIsShowingModal(false);
    cancel?.();
  }, [cancel, setIsShowingModal]);

  return (
    <Modal
      open={isShowingModal}
      title={title}
      okType="primary"
      okText={t('common.confirm')}
      cancelText={t('common.cancel')}
      centered
      onOk={onOk}
      onCancel={onCancel}
    >
      <Description>
        <Trans components={{ br: <br /> }} i18nKey={description} />
      </Description>
    </Modal>
  );
}

export function useConfirmationModal() {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState<() => void>();
  const [cancel, setCancel] = useState<() => void>();
  const showModalState = useState(false);
  const [, setIsShowingModal] = showModalState;

  const confirmAction = useCallback(() => {
    return new Promise((resolve) => {
      setConfirm(() => () => {
        resolve(true);
      });

      setCancel(() => () => {
        resolve(false);
      });

      setIsShowingModal(true);
    });
  }, [setIsShowingModal]);

  const ConfirmationModal = (props: {
    title?: string;
    description?: string;
  }): JSX.Element => {
    const { title, description } = props;
    return (
      <ConfirmationModalBase
        confirm={confirm}
        cancel={cancel}
        showModalState={showModalState}
        title={title}
        description={description}
      />
    );
  };

  ConfirmationModal.defaultProps = {
    title: t('common.confirm'),
    description: t('common.confirmAction'),
  };

  return {
    ConfirmationModal,
    confirmAction,
  };
}
