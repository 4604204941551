import { apiService } from 'shared/services/api.service';
import { SET_USER, SetUserAction, RemoveUserAction } from './auth.types';

export const setUser = (userData: DTO.User): SetUserAction => {
  apiService.setToken(userData?.token || '');
  return { type: SET_USER, payload: userData };
};

export const removeUser = (): RemoveUserAction => {
  apiService.setToken('');
  return { type: SET_USER, payload: null };
};
