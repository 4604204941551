class SmartFetch {
  private atrInterceptor: (resposne: Response) => Promise<void> | void = () => {
    throw new Error('method Not implemented');
  };

  get interceptor() {
    return this.atrInterceptor;
  }

  set interceptor(callback: (respose: Response) => Promise<void> | void) {
    this.atrInterceptor = callback;
  }

  async fetch<T>(
    url: string,
    options: RequestInit = {},
    disableJson?: boolean
  ): Promise<T | void | any> {
    const response = await fetch(url, options);

    const { status } = response;

    await this.interceptor(response);

    if (status >= 400) {
      throw response.text();
    }

    if (!disableJson) return response.json();

    return response;
  }
}

export const smartFetch = new SmartFetch();
