import React, { FC, Suspense } from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import BaseLayout from 'features/Layouts';
import { PLACEMENT_PATH } from 'features/Routes/helpers';
import OpenPositions from 'features/Modules/OpenPositions';
import PositionItem from 'features/Modules/OpenPositions/Positions/PositionItem';
import AddPositions from 'features/Modules/OpenPositions/Positions/AddPositions';
import Candidates from 'features/Modules/OpenPositions/Candidates';
import CandidatesTabs from 'features/Modules/OpenPositions/Candidates/Tabs';
import Interviewers from 'features/Modules/OpenPositions/Interviewers';
import { usePositions } from '../../Modules/OpenPositions/provider';

const Positions: FC<RouteProps> = () => {
  const { availableRoutes } = usePositions();

  return (
    <BaseLayout config={availableRoutes}>
      <Switch>
        <Route
          exact
          path={PLACEMENT_PATH.ROOT}
          render={() => <Redirect to={PLACEMENT_PATH.POSITIONS} />}
        />
        <Route
          exact
          path={PLACEMENT_PATH.POSITIONS}
          render={() => <OpenPositions />}
        />
        <Route
          exact
          path={PLACEMENT_PATH.ADD_POSITION}
          render={() => <AddPositions />}
        />
        <Route
          exact
          path={PLACEMENT_PATH.VIEW}
          render={() => <PositionItem />}
        />
        <Route
          exact
          path={PLACEMENT_PATH.EDIT}
          render={() => <AddPositions />}
        />
        <Route
          exact
          path={PLACEMENT_PATH.CANDIDATES}
          render={() => <Candidates />}
        />
        <Route
          path={PLACEMENT_PATH.CANDIDATE_FORM}
          render={() => <CandidatesTabs />}
        />
        <Route
          path={PLACEMENT_PATH.INTERVIEWERS}
          render={() => <Interviewers />}
        />
      </Switch>
      <Suspense fallback={<LoadingOutlined width="20px" />} />
    </BaseLayout>
  );
};

export default Positions;
