import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Tag from 'components/Tag';
import { PlusCircleOutlined } from '@ant-design/icons';
import Popover from 'components/Popover';
import { AutoComplete } from 'antd';
import _ from 'lodash';
import { SHORT_WAIT_TIME } from 'shared/constants/common.const';
import { FormInstance } from 'components/Form';
import { TagItem } from './helper';
import {
  AddWrapper,
  CloseIcon,
  ListWrapper,
  MainWrapper,
  Wrapper,
} from './styles';

function TagCollection(props: {
  tagList?: TagItem[];
  isEditable?: boolean;
  addButtonText?: string;
  popoverTitle?: string;
  options?: {
    value: string;
    label: string;
  }[];
  onSearch?: (props: { searchValue: string }) => void;
  onChange?: (props: { tags: TagItem[] }) => void;
  form?: FormInstance;
  fieldName?: string;
}) {
  const {
    tagList,
    isEditable,
    addButtonText,
    popoverTitle,
    options,
    onChange = ({ tags }) => tags,
    onSearch,
    form,
    fieldName,
  } = props;
  const [isShowingInput, setIsShowingInput] = useState<boolean>(false);
  const [tagName, setTagName] = useState<string>('');
  const inputId = 'tag-collection-input';
  const [filteredOptions, setFilteredOptions] = useState(options);
  const tagSet = useRef(new Set(tagList));
  const [reactiveTagList, setReactiveTagList] = useState(
    Array.from(tagSet.current)
  );

  const showInput = () => setIsShowingInput(true);
  const hideInput = () => setIsShowingInput(false);

  const focusInput = useCallback(() => {
    const input = document.getElementById(inputId);
    setTimeout(() => input?.focus(), 0);
  }, []);

  const blurInput = useCallback(() => {
    const input = document.getElementById(inputId);
    setTimeout(() => input?.blur(), 0);
  }, []);

  const updateTagsState = useCallback(() => {
    const tagArray = Array.from(tagSet.current).reduce(
      (accumulator, currentValue) => {
        if (
          accumulator.find(
            (tag) =>
              tag.name.toLocaleLowerCase() ===
              currentValue.name.toLocaleLowerCase()
          )
        )
          return accumulator;
        return [...accumulator, currentValue];
      },
      [] as TagItem[]
    );
    setReactiveTagList(tagArray);
    onChange({ tags: tagArray });
  }, [onChange]);

  const addTag = useCallback(
    async (_props: {
      key?: React.KeyboardEvent<HTMLDivElement> | undefined;
      tagName?: string;
    }) => {
      const { key } = _props;
      const currentTagName = _props.tagName || tagName;

      if (fieldName && !_props.tagName) {
        try {
          await form?.validateFields([fieldName]);
        } catch {
          return;
        }
      }

      if ((key?.key === 'Enter' && tagName) || _props.tagName) {
        tagSet.current.add({ name: currentTagName });
        updateTagsState();
        setIsShowingInput(false);
        blurInput();
      }
    },
    [blurInput, fieldName, form, tagName, updateTagsState]
  );

  const addTagDebounced = useMemo(
    () => _.debounce(addTag, SHORT_WAIT_TIME),
    [addTag]
  );

  const cancelAddTagThenAddTag = (...args: Parameters<typeof addTag>) => {
    setTimeout(addTagDebounced.cancel, 0);
    addTag(...args);
  };

  const removeTag = (item: TagItem) => {
    tagSet.current.delete(item);
    updateTagsState();
    if (fieldName) form?.validateFields([fieldName]);
  };

  const filterOptions = useCallback(
    (_props: { searchValue: string }) => {
      const { searchValue } = _props;

      const filtered = options?.filter(
        (option) =>
          option.label.toLowerCase().includes(searchValue.toLowerCase()) &&
          reactiveTagList.find((tag) => tag.name === option.label) === undefined
      );

      if (
        !options?.find(
          (option) => option.label.toLowerCase() === searchValue.toLowerCase()
        )
      ) {
        filtered?.push({ value: searchValue, label: searchValue });
      }

      setFilteredOptions(filtered);

      onSearch?.({ searchValue });
    },
    [onSearch, options, reactiveTagList]
  );

  useEffect(() => {
    if (isShowingInput) {
      focusInput();
    }
  }, [focusInput, isShowingInput]);

  useEffect(() => {
    tagSet.current = new Set(tagList);
    setReactiveTagList(tagList || []);
  }, [tagList]);

  return (
    <MainWrapper>
      <ListWrapper>
        {reactiveTagList?.map((tag) => (
          <Wrapper key={tag.id}>
            {isEditable && <CloseIcon onClick={() => removeTag(tag)} />}
            <Tag>{tag.name}</Tag>
          </Wrapper>
        ))}
        {isEditable && (
          <AddWrapper onClick={showInput} onBlur={hideInput}>
            <Popover
              open={isShowingInput}
              placement="right"
              content={
                isShowingInput && (
                  <AutoComplete
                    style={{ width: 120 }}
                    id={inputId}
                    allowClear
                    onKeyDown={(key) => addTagDebounced({ key })}
                    onChange={(value) => setTagName(value)}
                    options={filteredOptions}
                    onSearch={(searchValue) => filterOptions({ searchValue })}
                    onSelect={(value) =>
                      cancelAddTagThenAddTag({ tagName: value })
                    }
                  />
                )
              }
              title={popoverTitle}
            >
              <PlusCircleOutlined /> {addButtonText}
            </Popover>
          </AddWrapper>
        )}
      </ListWrapper>
    </MainWrapper>
  );
}

TagCollection.defaultProps = {
  tagList: [],
  isEditable: true,
  addButtonText: '',
  popoverTitle: '',
  onChange: (props: { tags: TagItem[] }) => props.tags,
  options: [],
  onSearch: undefined,
  form: undefined,
  fieldName: undefined,
};

export default TagCollection;
