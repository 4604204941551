import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ActionsAntTable from 'components/ActionsAntTable';
import { useTranslation } from 'react-i18next';
import { positionsService } from 'shared/services';
import { getErrorInfo } from 'shared/utils/error.utils';
import Switch from 'components/Switch';
import { TableWrapper } from 'components/Timetracker/Timesheet/styles';
import { generatePath, useHistory, useParams } from 'react-router';
import { ModalMode } from 'shared/constants/common.const';
import { useActionTable } from 'components/ActionsAntTable/hooks';
import { ColumnsType } from 'antd/lib/table';
import { hasPermission, showNotification } from 'shared/utils/common.utils';
import { PLACEMENT_PATH } from 'features/Routes/helpers';
import Tooltip from 'components/Tooltip';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useConfirmationModal } from 'components/Modal/ConfirmationModal';
import {
  InterviewerColumnsTypes,
  InterviewerTableColumns,
  MAX_SKILLS,
} from './helper';
import { EXPERTISE } from '../ExpertiseFilter';
import { EyeOutlinedStyled, IconsWrapper } from './styles';

function InterviewersTable() {
  const { t } = useTranslation();
  const { mode, id } = useParams<{ mode: ModalMode; id: string }>();
  const history = useHistory();
  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search]
  );
  const expertiseFilter = searchParams.get(EXPERTISE);

  const [interviewers, setInterviewers] = useState<DTO.InterviewerList[]>([]);
  const {
    getTableRequestBody,
    generateInputFilter,
    generateBooleanFilter,
    Paginator,
    paginationState,
  } = useActionTable<InterviewerColumnsTypes>();
  const [pagination, setPagination] = paginationState;
  const { confirmAction: confirmDeletion, ConfirmationModal: DeletionModal } =
    useConfirmationModal();
  const {
    confirmAction: confirmInactivation,
    ConfirmationModal: InactivationModal,
  } = useConfirmationModal();

  const body = useMemo(() => {
    const temporalBody = getTableRequestBody({
      sortColumn: InterviewerTableColumns.INTERVIEWER,
    });

    return {
      ...temporalBody,
      filter: {
        ...temporalBody.filter,
        expertise: expertiseFilter ?? undefined,
      },
    };
  }, [expertiseFilter, getTableRequestBody]);

  const getInterviewers = useCallback(async () => {
    try {
      const response = await positionsService.getInterviewers({
        body,
      });
      setInterviewers(response.results);
      setPagination((prev) => ({ ...prev, total: response.total }));
    } catch (error) {
      getErrorInfo(error as Promise<string>, t);
    }
  }, [body, setPagination, t]);

  const openModal = useCallback(
    (props: { record: DTO.InterviewerList; mode: ModalMode }) => {
      const { mode: modalMode } = props;
      history.push({
        pathname: generatePath(PLACEMENT_PATH.INTERVIEWERS, {
          ...Object.fromEntries(searchParams),
          mode: modalMode,
          id: props.record.interviewerId,
        }),
        search: window.location.search,
      });
    },
    [history, searchParams]
  );

  const deleteInterviewer = useCallback(
    async (props: { record: DTO.InterviewerList }) => {
      const { record } = props;

      const response = await positionsService.deleteInterviewer({
        interviewerId: record.interviewerId,
      });
      showNotification(t, {
        type: 'success',
        description: response.message,
      });
    },
    [t]
  );

  const tryToDeleteInterviewer = useCallback(
    async (props: { record: DTO.InterviewerList }) => {
      const wasConfirmed = await confirmDeletion();
      if (wasConfirmed) {
        try {
          await deleteInterviewer(props);
          await getInterviewers();
        } catch (error) {
          getErrorInfo(error as Promise<string>, t);
        }
      }
    },
    [confirmDeletion, deleteInterviewer, getInterviewers, t]
  );

  const inactivateInterviewer = useCallback(
    async (props: { interviewerId: number }) => {
      const { interviewerId } = props;

      if (id) throw new Error();

      const response = await positionsService.inactivateInterviewer({
        interviewerId,
      });
      showNotification(t, {
        type: 'success',
        description: response.message,
      });
    },
    [id, t]
  );

  const activateInterviewer = useCallback(
    async (props: { interviewerId: number }) => {
      const { interviewerId } = props;

      if (id) throw new Error();

      const response = await positionsService.activateInterviewer({
        interviewerId,
      });
      showNotification(t, {
        type: 'success',
        description: response.message,
      });
    },
    [id, t]
  );

  const tryToActivateInactiveInterviewer = useCallback(
    async (props: { isActivating: boolean; interviewerId: number }) => {
      const { isActivating, interviewerId } = props;
      const wasConfirmed = await confirmInactivation();

      const action = isActivating ? activateInterviewer : inactivateInterviewer;

      if (wasConfirmed) {
        try {
          await action({ interviewerId });
          await getInterviewers();
        } catch (error) {
          getErrorInfo(error as Promise<string>, t);
        }
      }
    },
    [
      activateInterviewer,
      confirmInactivation,
      getInterviewers,
      inactivateInterviewer,
      t,
    ]
  );

  const isAbleTo = useMemo(
    () => ({
      view: hasPermission(
        'placement.interviewer.interviewer_view',
        generatePath(PLACEMENT_PATH.INTERVIEWERS)
      ),
      delete: hasPermission(
        'placement.interviewer.interviewer_delete',
        generatePath(PLACEMENT_PATH.INTERVIEWERS)
      ),
      edit: hasPermission(
        'placement.interviewer.interviewer_edit',
        generatePath(PLACEMENT_PATH.INTERVIEWERS)
      ),
      activeInactive: hasPermission(
        'placement.interviewer.interviewer_activeInactive',
        generatePath(PLACEMENT_PATH.INTERVIEWERS)
      ),
    }),
    []
  );

  const columns: ColumnsType<InterviewerColumnsTypes> = useMemo(() => {
    const { activeInactive: isAbleToActiveInactive, ...actions } = isAbleTo;
    const showActions = Object.values(actions).some((value) => value);

    const response = [
      {
        title: t('positions.interviewers.interviewer', { count: 1 }),
        width: '400px',
        key: InterviewerTableColumns.INTERVIEWER,
        dataIndex: InterviewerTableColumns.INTERVIEWER,
        ...generateInputFilter({
          dataIndex: InterviewerTableColumns.INTERVIEWER,
          placeholder: t('common.searchByName'),
        }),
      },
      {
        title: t('humanCapital.personalInformation.companyEmail'),
        width: '',
        key: InterviewerTableColumns.EMAIL,
        dataIndex: InterviewerTableColumns.EMAIL,
        ...generateInputFilter({
          dataIndex: InterviewerTableColumns.EMAIL,
          placeholder: t('common.searchByEmail'),
        }),
      },
      {
        title: t('positions.interviewers.expertise'),
        width: '',
        key: InterviewerTableColumns.EXPERTISE,
        dataIndex: InterviewerTableColumns.EXPERTISE,
        render: (expertise: DTO.Expertise[]) => (
          <Tooltip
            title={
              expertise.length > MAX_SKILLS &&
              expertise.map((item) => item.name).join(', ')
            }
          >
            {expertise
              .slice(0, MAX_SKILLS)
              .map((item) => item.name)
              .join(', ') + (expertise.length > MAX_SKILLS ? '...' : '')}
          </Tooltip>
        ),
      },
      {
        title: t('common.status'),
        width: '',
        key: InterviewerTableColumns.STATUS,
        dataIndex: InterviewerTableColumns.STATUS,
        ...generateBooleanFilter({
          dataIndex: InterviewerTableColumns.STATUS,
        }),
        render: (status: boolean, record: InterviewerColumnsTypes) => (
          <Switch
            checked={status}
            checkedChildren={t('common.active')}
            unCheckedChildren={t('common.inactive')}
            disabled={!isAbleToActiveInactive}
            onChange={(value) =>
              tryToActivateInactiveInterviewer({
                isActivating: value,
                interviewerId: record.interviewerId,
              })
            }
          />
        ),
      },
    ];

    if (showActions) {
      response.push({
        title: t('common.actions'),
        width: '',
        key: InterviewerTableColumns.ACTIONS,
        dataIndex: InterviewerTableColumns.ACTIONS,
        fixed: 'right',
        render: (_: unknown, record: InterviewerColumnsTypes) => (
          <IconsWrapper>
            {isAbleTo.view && (
              <Tooltip title={t('common.view')}>
                <EyeOutlinedStyled
                  onClick={() =>
                    record &&
                    openModal({
                      record: record as unknown as DTO.InterviewerList,
                      mode: ModalMode.view,
                    })
                  }
                />
              </Tooltip>
            )}
            {isAbleTo.view && (
              <Tooltip title={t('common.edit')}>
                <EditOutlined
                  onClick={() =>
                    record &&
                    openModal({
                      record: record as unknown as DTO.InterviewerList,
                      mode: ModalMode.edit,
                    })
                  }
                />
              </Tooltip>
            )}
            {isAbleTo.delete && (
              <Tooltip title={t('common.delete')}>
                <DeleteOutlined
                  onClick={() =>
                    record &&
                    tryToDeleteInterviewer({
                      record: record as unknown as DTO.InterviewerList,
                    })
                  }
                />
              </Tooltip>
            )}
          </IconsWrapper>
        ),
      });
    }

    return response;
  }, [
    generateBooleanFilter,
    generateInputFilter,
    isAbleTo,
    t,
    tryToActivateInactiveInterviewer,
    tryToDeleteInterviewer,
    openModal,
  ]);

  useEffect(() => {
    if (!mode) {
      getInterviewers();
    }
  }, [getInterviewers, mode]);

  return (
    <TableWrapper>
      <ActionsAntTable
        columns={columns}
        data={interviewers}
        scroll={{ x: 'max-content' }}
      />
      <Paginator
        name={t('positions.interviewers.interviewer', {
          count: pagination.total,
        })}
      />
      <DeletionModal
        title={t('positions.interviewers.interviewerDeletion')}
        description={t('positions.interviewers.areYouSureToDelete')}
      />
      <InactivationModal />
    </TableWrapper>
  );
}

export default InterviewersTable;
