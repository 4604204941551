import { EyeOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const EyeOutlinedStyled = styled(EyeOutlined)`
  cursor: pointer;
`;

export const IconsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
