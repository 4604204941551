import styled from 'styled-components';
import { Upload, Button } from 'antd';

export const UploadStyled = styled(Upload)<{ width: string; margin?: string }>`
  display: inline !important;
  ${({ margin }) => (margin ? `margin: ${margin};` : '')};
  border-radius: ${({ theme }) => theme.shape.generalBorderRadius};
  .ant-upload.ant-upload-select {
    width: ${({ width }) => width} !important;
  }
  .ant-btn span:not(.anticon) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 97%;
  }
  .ant-btn .anticon {
    transform: translate(0, -6px);
  }
`;

export const ButtonStyled = styled(Button)<{
  width: string;
  height: string;
  disabled: boolean;
}>`
  width: ${({ width }) => width} !important;
  height: ${({ height }) => height} !important;
  margin: 0 !important;
  ${({ disabled, theme }) =>
    disabled &&
    `background-color:${theme.colors.backgroundDisabled}; color:${theme.colors.textDisabled};`};
`;
