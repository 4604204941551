import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class ProjectActivityService {
  GetAllByProjectId = (projectId: number): Promise<DTO.ProjectActivities> => {
    return apiService.request({
      url: `${Endpoints.GetAllActivitiesByProjectId}/${projectId}`,
      options: {
        method: 'GET',
      },
    });
  };
}

export const projectActivityService = new ProjectActivityService();
