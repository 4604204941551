import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Button';
import { FormInstance } from 'components/Form';
import { positionsService } from 'shared/services/positions.service';
import { getErrorInfo } from 'shared/utils/error.utils';
import { FormModes } from 'shared/constants/common.const';
import ConfirmationModal from 'components/ConfirmationModal';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { selectUser } from 'store/auth/auth.selectors';
import CommentsSection from 'components/Comments/CommentsSection';
import { showNotification } from 'shared/utils/common.utils';
import {
  SkillTitleSection,
  ButtonsWrapper,
  TitleStyled,
  CommentsWrapper,
} from './styles';
import Skill from './Skill';
import InfoIcon from './Skill/InfoIcon';
import AddPositionsContext from '../../addPositionsContext';
import { UPDATE_REASON_MIN_LENGTH } from './helpers';

interface Props {
  setTab: (tab: number) => void;
  positionForm: FormInstance;
  forMode: FormModes;
  onChangeSelectedSkills: (skills: DTO.PositionSkills[]) => void;
  skills: DTO.PositionSkills[];
  submitting: boolean;
  pathToRedirectOnCancel: string;
}
const Skills = (props: Props) => {
  const {
    submitting,
    setTab,
    positionForm,
    forMode: formMode,
    onChangeSelectedSkills,
    skills,
    pathToRedirectOnCancel,
  } = props;
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [skillCategories, setSkillCategories] = useState<
    DTO.PositionSkillCategory[]
  >([]);
  const [possibleSkills, setPossibleSkills] = useState<
    DTO.PositionPossibleSkills[]
  >([]);
  const { currentPosition, hasChanged, setHasChanged } =
    useContext(AddPositionsContext);
  const [updateReason, setUpdateReason] = useState<string>('');

  const addComment = useCallback(
    () =>
      JSON.stringify(
        (
          JSON.parse(currentPosition?.comments || '[]') as DTO.updateReason[]
        ).concat({
          author: `${user?.firstName || ''} ${user?.lastName || ''}`,
          content: updateReason,
          dateTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
        })
      ),
    [currentPosition?.comments, updateReason, user?.firstName, user?.lastName]
  );

  const getPossibleSkills = useCallback(async () => {
    try {
      const { results } =
        await positionsService.getPlacementPositionSkillsByName();
      setPossibleSkills(results);
    } catch (e) {
      setPossibleSkills([]);
    }
  }, []);

  const getSkillLevels = useCallback(async (skillCategoryId: number) => {
    try {
      const data = await positionsService.getPositionSkillLevel(
        skillCategoryId
      );
      return data.result;
    } catch (e) {
      return [];
    }
  }, []);

  const getSkills = useCallback(async () => {
    try {
      const skillCategoriesResult =
        await positionsService.getPositionsSkillCategories();
      const levelsResult = await Promise.all(
        skillCategoriesResult.results.map((skill) => getSkillLevels(skill.id))
      );
      const skillCategoriesAux = skillCategoriesResult.results.map(
        (category) => ({
          ...category,
          skillLevels:
            levelsResult.find((level) =>
              level.find((level2) => level2.skillTypeId === category.id)
            ) || [],
        })
      );
      setSkillCategories(skillCategoriesAux);
    } catch (e) {
      getErrorInfo(e as Promise<unknown>, t);
      setSkillCategories([]);
    }
  }, [getSkillLevels, t]);

  const getInitData = useCallback(async () => {
    const fetchSkills = getSkills();
    const fetchPossibleSkills = getPossibleSkills();
    await Promise.allSettled([fetchSkills, fetchPossibleSkills]);
  }, [getPossibleSkills, getSkills]);

  useEffect(() => {
    getInitData();
  }, [getInitData]);

  const disabled = useMemo(() => {
    let minSkills = 0;
    skillCategories
      .filter(
        (category) => category.name !== 'positions.skillCategories.niceToHave'
      )
      .forEach((category) => {
        minSkills += skills.find((skill) => skill.skillTypeId === category.id)
          ? 1
          : 0;
      });
    return (
      minSkills !== skillCategories.length - 1 ||
      !hasChanged ||
      (!!updateReason && updateReason.length < UPDATE_REASON_MIN_LENGTH)
    );
  }, [hasChanged, skillCategories, skills, updateReason]);

  const onTypeComment = useCallback((comment: string) => {
    setUpdateReason(comment);
  }, []);

  const onChange = useCallback(() => {
    setHasChanged(true);
  }, [setHasChanged]);

  const onSubmit = useCallback(async () => {
    if (formMode === FormModes.edit && !updateReason) {
      showNotification(t, {
        type: 'warning',
        description: 'common.addUpdateReason',
      });
      return;
    }

    positionForm.submit();

    if (formMode === FormModes.edit) {
      try {
        if (!currentPosition?.id) return;
        const data = {
          openPositionId: currentPosition.id,
          comments: addComment(),
        };
        await positionsService.updatePositionComments(data);
      } catch (e) {
        getErrorInfo(e as Promise<string>, t);
      }
    }
  }, [
    addComment,
    currentPosition?.id,
    formMode,
    positionForm,
    t,
    updateReason,
  ]);

  return (
    <>
      <SkillTitleSection>{t('positions.skills')}</SkillTitleSection>
      {skillCategories.map((skillCategory) => {
        return (
          <div key={skillCategory.id}>
            <TitleStyled>
              <InfoIcon
                key={skillCategory.name}
                skillType={skillCategory.name}
                skillTypeId={skillCategory.id}
                ratingNames={skillCategory.skillLevels}
              />
            </TitleStyled>
            <Skill
              possibleSkills={possibleSkills}
              skillLevels={skillCategory.skillLevels}
              formMode={formMode}
              skillType={skillCategory.id}
              skillCategory={skillCategory}
              skills={skills}
              setSkills={onChangeSelectedSkills}
              onChange={onChange}
            />
          </div>
        );
      })}
      {formMode === FormModes.edit && (
        <CommentsWrapper>
          <div>{t('common.updateReason')}</div>
          <CommentsSection
            resetState={false}
            commentsData={currentPosition?.comments || '[]'}
            loading={false}
            formComment
            customPlaceholder="common.typeUpdateReason"
            onChangeTypeComment={onTypeComment}
          />
        </CommentsWrapper>
      )}
      <ButtonsWrapper>
        <ConfirmationModal
          path={pathToRedirectOnCancel}
          message="timetracker.projectDetails.cancelConfirmMessage"
          showModal={hasChanged}
        />
        <Button type="default" onClick={() => setTab(1)}>
          {t('common.previous')}
        </Button>
        <Button
          loading={submitting}
          disabled={disabled}
          type="primary"
          htmlType="submit"
          onClick={onSubmit}
        >
          {t('common.save')}
        </Button>
      </ButtonsWrapper>
    </>
  );
};

export default memo(Skills);
