import { positionsService } from 'shared/services/positions.service';
import { clientService } from 'shared/services';
import { Options, getOptions } from 'components/Form/helpers';
import { getErrorInfo } from 'shared/utils/error.utils';

export const enum CandidatesColumn {
  name = 'fullName',
  email = 'personalEmailAddress',
  country = 'countryName',
  role = 'roleName',
  status = 'candidateStatusId',
  actions = 'actions',
  statusName = 'candidateStatusName',
}

export enum CandidatesColumnTitle {
  name = 'common.name',
  email = 'humanCapital.personalInformation.personalEmail',
  country = 'positions.candidate.residentCountry',
  role = 'humanCapital.employmentData.role',
  status = 'common.status',
  actions = 'common.actions',
}

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;

const getPossibleCountries = async (): Promise<DTO.Country[]> => {
  try {
    return await clientService.fetchCountries();
  } catch (e) {
    return [];
  }
};

export const getPositionsHelper = async (
  params: DTO.GetCandidatesRequest
): Promise<DTO.GetCandidatesResponse> => {
  try {
    const countries = await getPossibleCountries();
    const data = await positionsService.getCandidatesList(params);
    return {
      ...data,
      results: data.results.map((position) => ({
        key: position.id,
        ...position,
        countryName:
          countries.find((country) => country.countryId === position.countryId)
            ?.name || '',
      })),
    };
  } catch (e) {
    return {
      valueId: '',
      page: 0,
      pageSize: 0,
      searchTerm: null,
      sortColumn: null,
      sortDirection: '',
      total: 0,
      isSuccessful: false,
      message: '',
      filter: null,
      results: [],
    };
  }
};

export const getStatusList = async (t: (key: string) => string) => {
  try {
    const response = await positionsService.getCandidateStatusList();
    const responseOptions = response.results.map((status) => ({
      id: status.id,
      name: status.reason,
    }));

    return getOptions(responseOptions, false);
  } catch (error) {
    getErrorInfo(error as Promise<string>, t);
  }

  return [];
};

export interface CandidatesContext {
  statusList: Options<string | number>[];
}
