import React, { FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { apiService, i18Service } from 'shared/services';
import UserRoutes from 'features/Routes';
import { theme } from 'theme';
import { store } from 'store/store';
import { getFromLocalStorage } from 'shared/utils/storage.utils';
import {
  CURRENT_LOCALE,
  DEFAULT_LOCALE,
  OS,
} from 'shared/constants/common.const';
import { useMsal } from '@azure/msal-react';
import { smartFetch } from 'shared/utils/async.utils';
import { UAParser } from 'ua-parser-js';

const App: FC = () => {
  useEffect(() => {
    const parse = new UAParser().getResult();
    const url = window.location.href.replace(window.location.origin, '');
    if (parse.os.name === OS.ios || parse.os.name === OS.android) {
      window.location.href = `${window.APP_CONFIG.MOBILE_REDIRECT_URL}:/${url}`;
    }
  }, []);
  const initialLanguage =
    getFromLocalStorage(CURRENT_LOCALE) || DEFAULT_LOCALE.abbr;

  const { accounts, instance } = useMsal();
  smartFetch.interceptor = async (response: Response) => {
    const { status } = response;
    if (accounts[0] && status === 401) {
      const accessTokenRequest = {
        scopes: ['user.read'],
        account: accounts[0],
      };
      const msalResponse = await instance.acquireTokenSilent(
        accessTokenRequest
      );
      apiService.setToken(msalResponse.accessToken);
    }
  };

  return (
    <I18nextProvider i18n={i18Service.init(initialLanguage)}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <UserRoutes />
          </Provider>
        </ThemeProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
};

export default App;
