import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class CityService {
  getCitiesByState = (
    countryId: string,
    stateId: string
  ): Promise<DTO.Cities> => {
    return apiService.request({
      url: `${Endpoints.getCitiesByState}${countryId}/${stateId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getColombiaCities = (): Promise<DTO.Cities> => {
    return apiService.request({
      url: `${Endpoints.getColombiaCities}`,
      options: {
        method: 'GET',
      },
    });
  };
}

export const cityService = new CityService();
