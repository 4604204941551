import styled from 'styled-components';

export const SkillTypeContainer = styled.div`
  margin-bottom: 25px;
  margin-right: 25px;
`;

export const PillsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PopoverStyled = styled.div`
  .ant-popover-inner {
    background: #fbfbfb;
    border-radius: 15px;
  }
  .ant-popover-arrow-content {
    --antd-arrow-background-color: #fbfbfb;
  }
`;
