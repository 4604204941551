import addANewAssignment01 from '../../shared/manual/Assignments to projects/01 Add a new Assignment.pdf';
import assignAPersonToAProject02 from '../../shared/manual/Assignments to projects/02 Assign a person to a Project.pdf';
import deleteAnAssignment03 from '../../shared/manual/Assignments to projects/03 Delete an Assignment.pdf';
import editAnAssignment04 from '../../shared/manual/Assignments to projects/04 Edit an Assignment.pdf';
import viewAnAssignment05 from '../../shared/manual/Assignments to projects/05 View an Assigment.pdf';
import howToAddAClient01 from '../../shared/manual/Manage Clients/01 How to add a Client.pdf';
import viewAPersonsAssignment01 from '../../shared/manual/Manage Persons/01 View a Person assignment.pdf';
import howToSeeAllPeople02 from '../../shared/manual/Manage Persons/02 How to see all People.pdf';
import editAPersonsAssignment03 from '../../shared/manual/Manage Persons/03 Edit a Person assignment.pdf';
import howToAddPeople04 from '../../shared/manual/Manage Persons/04 How to add People.pdf';
import deleteAPersonsAssignment05 from '../../shared/manual/Manage Persons/05 Delete a Person Assignment.pdf';
import viewPeoplesInformation06 from '../../shared/manual/Manage Persons/06 View people Information.pdf';
import addAssignmentToAPerson07 from '../../shared/manual/Manage Persons/07 Add Assignment to a person.pdf';
import editPeoplesInformation08 from '../../shared/manual/Manage Persons/08 Edit People Information.pdf';
import howToDeleteAPerson09 from '../../shared/manual/Manage Persons/09 How to delete a Person.pdf';
import addANewProject01 from '../../shared/manual/Manage Projects/01 Add a new project.pdf';
import howToUseTheProjectTable02 from '../../shared/manual/Manage Projects/02 How to use the Projects table.pdf';
import viewProjectTeam03 from '../../shared/manual/Manage Projects/03 View the Project team.pdf';
import viewProjectInfo04 from '../../shared/manual/Manage Projects/04 View the Project info.pdf';
import editProject05 from '../../shared/manual/Manage Projects/05 Edit a Project.pdf';
import howToDeleteAProject06 from '../../shared/manual/Manage Projects/06 How to delete a Project.pdf';
import howToCreateTimeReport01 from '../../shared/manual/Reports/01 How to create a Time Report.pdf';
import rejectTimesheet01 from '../../shared/manual/Review Timesheets/01 Reject a Timesheet.pdf';
import resendTimesheet02 from '../../shared/manual/Review Timesheets/02 Resend Timesheets.pdf';
import approveTimesheet03 from '../../shared/manual/Review Timesheets/03 Approve Timesheets.pdf';
import saveHoursInATimesheet01 from '../../shared/manual/Timetracker/01 Save your hours in a Timesheet.pdf';
import submitATimesheet02 from '../../shared/manual/Timetracker/02 Submit a Timesheet.pdf';
import addCommentsToATimesheet03 from '../../shared/manual/Timetracker/03 Add comments to a Timesheet.pdf';
import seeAllTimesheets04 from '../../shared/manual/Timetracker/04 See all Timesheets.pdf';
import seeATimesheets05 from '../../shared/manual/Timetracker/05 See a Timesheet.pdf';
import editAPendingTimesheet06 from '../../shared/manual/Timetracker/06 Edit a Pending Timesheet.pdf';
import editARejectedTimesheet07 from '../../shared/manual/Timetracker/07 Edit a Rejected Timesheet.pdf';
import submitAnEditedTimesheetForApproval08 from '../../shared/manual/Timetracker/08 Submit an edited Timesheet for Approval.pdf';
import seeTheCommentsOfATimeEntryOfTheSelectedTimesheet09 from '../../shared/manual/Timetracker/09  See the comments of a Time Entry of the selected Timesheet.pdf';
import addACommentToATimeEntryOfTheSelectedTimesheet10 from '../../shared/manual/Timetracker/10 Add a comment to a Time Entry of the selected Timesheet.pdf';

export interface manualObject {
  manualLink: string;
  caption: string;
  category:
    | 'timetracker'
    | 'clients'
    | 'people'
    | 'projects'
    | 'assignProjects'
    | 'reports'
    | 'reviewTimesheets';
}

export const manualCategories = [
  'assignProjects',
  'clients',
  'people',
  'projects',
  'reports',
  'reviewTimesheets',
  'timetracker',
];

export const manuals: manualObject[] = [
  {
    manualLink: addANewAssignment01,
    caption: '01 Add a new assignment',
    category: 'assignProjects',
  },
  {
    manualLink: assignAPersonToAProject02,
    caption: '02 Assign a person to a project',
    category: 'assignProjects',
  },
  {
    manualLink: deleteAnAssignment03,
    caption: '03 Delete an assignment',
    category: 'assignProjects',
  },
  {
    manualLink: editAnAssignment04,
    caption: '04 Edit an assignment',
    category: 'assignProjects',
  },
  {
    manualLink: viewAnAssignment05,
    caption: '05 View an assignment',
    category: 'assignProjects',
  },
  {
    manualLink: howToAddAClient01,
    caption: '01 How to add a client',
    category: 'clients',
  },
  {
    manualLink: viewAPersonsAssignment01,
    caption: "01 View a person's assignment",
    category: 'people',
  },
  {
    manualLink: howToSeeAllPeople02,
    caption: '02 How to see all people',
    category: 'people',
  },
  {
    manualLink: editAPersonsAssignment03,
    caption: "03 Edit a person's assignment",
    category: 'people',
  },
  {
    manualLink: howToAddPeople04,
    caption: '04 How to add people',
    category: 'people',
  },
  {
    manualLink: deleteAPersonsAssignment05,
    caption: "05 Delete a person's assignment",
    category: 'people',
  },
  {
    manualLink: viewPeoplesInformation06,
    caption: "06 View people's information",
    category: 'people',
  },
  {
    manualLink: addAssignmentToAPerson07,
    caption: '07 Add assignment to a person',
    category: 'people',
  },
  {
    manualLink: editPeoplesInformation08,
    caption: "08 Edit people's information",
    category: 'people',
  },
  {
    manualLink: howToDeleteAPerson09,
    caption: '09 How to delete a person',
    category: 'people',
  },
  {
    manualLink: addANewProject01,
    caption: '01 Add a new project',
    category: 'projects',
  },
  {
    manualLink: howToUseTheProjectTable02,
    caption: '02 How to use the projects table',
    category: 'projects',
  },
  {
    manualLink: viewProjectTeam03,
    caption: '03 View the project team',
    category: 'projects',
  },
  {
    manualLink: viewProjectInfo04,
    caption: '04 View the project info',
    category: 'projects',
  },
  {
    manualLink: editProject05,
    caption: '05 Edit a project',
    category: 'projects',
  },
  {
    manualLink: howToDeleteAProject06,
    caption: '06 How to delete a project',
    category: 'projects',
  },
  {
    manualLink: howToCreateTimeReport01,
    caption: '01 How to create a Time Report',
    category: 'reports',
  },
  {
    manualLink: rejectTimesheet01,
    caption: '01 Reject a Timesheet',
    category: 'reviewTimesheets',
  },
  {
    manualLink: resendTimesheet02,
    caption: '02 Resend Timesheets',
    category: 'reviewTimesheets',
  },
  {
    manualLink: approveTimesheet03,
    caption: '03 Approve Timesheets',
    category: 'reviewTimesheets',
  },
  {
    manualLink: saveHoursInATimesheet01,
    caption: '01 Save your hours in a Timesheet',
    category: 'timetracker',
  },
  {
    manualLink: submitATimesheet02,
    caption: '02 Submit a Timesheet',
    category: 'timetracker',
  },
  {
    manualLink: addCommentsToATimesheet03,
    caption: '03 Add comments to a Timesheet',
    category: 'timetracker',
  },
  {
    manualLink: seeAllTimesheets04,
    caption: '04 See all Timesheets',
    category: 'timetracker',
  },
  {
    manualLink: seeATimesheets05,
    caption: '05 See a Timesheet',
    category: 'timetracker',
  },
  {
    manualLink: editAPendingTimesheet06,
    caption: '06 Edit a pending Timesheet',
    category: 'timetracker',
  },
  {
    manualLink: editARejectedTimesheet07,
    caption: '07 Edit a rejected Timesheet',
    category: 'timetracker',
  },
  {
    manualLink: submitAnEditedTimesheetForApproval08,
    caption: '08 Submit an edited Timesheet for approval',
    category: 'timetracker',
  },
  {
    manualLink: seeTheCommentsOfATimeEntryOfTheSelectedTimesheet09,
    caption: '09 See the comments of a Time Entry of the selected Timesheet',
    category: 'timetracker',
  },
  {
    manualLink: addACommentToATimeEntryOfTheSelectedTimesheet10,
    caption: '10 Add a comment to a Time Entry of the selected Timesheet',
    category: 'timetracker',
  },
];
