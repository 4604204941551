import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class PhotoService {
  getPhotos = (
    page: number,
    pageSize: number,
    searchTerm: string,
    filter: {}
  ): Promise<any> => {
    return apiService.request({
      url: Endpoints.getPhotos,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: '',
          page,
          pageSize,
          searchTerm,
          sortColumn: 'Name',
          sortDirection: 'asc',
          total: 0,
          message: '',
          isSuccessful: true,
          filter: filter || {},
        }),
      },
    });
  };

  uploadPhoto = (
    personId: number,
    data: string,
    fileName: string
  ): Promise<any> => {
    return apiService.request({
      url: Endpoints.uploadPhoto,
      options: {
        method: 'POST',
        body: JSON.stringify({
          personId,
          data,
          fileName,
        }),
      },
    });
  };

  uploadApprovedPhoto = (
    personId: number,
    data: string,
    fileName: string
  ): Promise<any> => {
    return apiService.request({
      url: Endpoints.uploadApprovedPhoto,
      options: {
        method: 'POST',
        body: JSON.stringify({
          personId,
          data,
          fileName,
        }),
      },
    });
  };

  getMyOwnPhoto = () => {
    return apiService.request<{ result: DTO.PhotoResponse }>({
      url: Endpoints.getPhoto,
      options: {
        method: 'GET',
      },
    });
  };

  getPersonPhoto = (personId: number) => {
    return apiService.request<{ result: DTO.PhotoResponse }>({
      url: `${Endpoints.getPersonPhoto}/${personId}`,
      options: {
        method: 'GET',
      },
    });
  };

  acceptPhoto = (recordId: number) => {
    return apiService.request<DTO.ApprovePhoto>({
      url: `${Endpoints.acceptPhoto}/${recordId}`,
      options: {
        method: 'POST',
      },
    });
  };

  amendPhoto = (recordId: number) => {
    return apiService.request<DTO.ApprovePhoto>({
      url: `${Endpoints.amendPhoto}/${recordId}`,
      options: {
        method: 'POST',
      },
    });
  };

  uploadMyOwnPhoto = ({
    data,
    personId,
    fileName,
  }: {
    data: string;
    personId: number;
    fileName: string;
  }): Promise<any> => {
    return apiService.request({
      url: Endpoints.uploadPhoto,
      options: {
        method: 'POST',
        body: JSON.stringify({
          personId,
          data,
          fileName,
        }),
      },
    });
  };
}

export const photoService = new PhotoService();
