import { InfoCircleOutlined } from '@ant-design/icons';
import Tooltip from 'components/Tooltip';
import styled from 'styled-components';

export const InfoCircleStyled = styled(InfoCircleOutlined)`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 15px;
  margin-left: 7px;
`;

export const RatingName = styled.span`
  font-weight: bold;
  text-align: left;
`;

export const RatingComponent = styled.div`
  display: flex;
  margin: 0 35px 35px 15px;
  flex-direction: column-reverse;
  white-space: nowrap;
`;

export const LevelRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: flex-start;
`;
export const CancelFooter = styled.div`
  display: flex !important;
  justify-content: flex-end;
  flex-grow: 1;
`;

export const RequiredText = styled.span`
  color: #ff4d4f;
  font-size: 14px;
  margin-right: 5px;
`;

export const Description = styled.span`
  margin-right: 15px;
`;

export const TooltipStyled = styled(Tooltip)``;
