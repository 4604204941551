import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class PeopleService {
  getPeople = (
    page: number,
    pageSize: number,
    searchTerm: string,
    filter: {}
  ): Promise<DTO.People> => {
    return apiService.request({
      url: Endpoints.getPeople,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: '',
          page,
          pageSize,
          searchTerm,
          sortColumn: 'Name',
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: filter || {},
        }),
      },
    });
  };

  getAllPeopleAsReviewer = (
    searchTerms?: string
  ): Promise<DTO.PersonResponseReviewer> => {
    return apiService.request({
      url: `${Endpoints.getAllPeopleAsReviewer}${
        searchTerms ? `?searchTerms=${searchTerms}` : ''
      }`,
      options: {
        method: 'POST',
      },
    });
  };

  getPersonByEmail = (emailAddress: string): Promise<DTO.PersonResponse> => {
    return apiService.request({
      url: `${Endpoints.getPersonByEmail}`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          emailAddress,
        }),
      },
    });
  };

  validateEmail = (emailAddress: string): Promise<DTO.PersonResponse> => {
    return apiService.request({
      url: `${Endpoints.validateEmail}`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          emailAddress,
        }),
      },
    });
  };

  getPersonByUserId = (userId: string): Promise<DTO.PersonResponse> => {
    return apiService.request({
      url: `${Endpoints.getPersonByUserId}/${userId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getPersonByOwner = (userId: string): Promise<DTO.PersonResponse> => {
    return apiService.request({
      url: `${Endpoints.getPersonByOwner}/${userId}`,
      options: {
        method: 'GET',
      },
    });
  };

  transferPerson = (personId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.transferPerson}/${personId}`,
      options: {
        method: 'POST',
      },
    });
  };

  getAllPeopleNotInProject = (
    projectId: string,
    companyId: string
  ): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getAllPeopleNotInProject}${projectId}/${companyId}`,
      options: {
        method: 'GET',
      },
    });
  };

  fetchGenders = (): Promise<DTO.GenderResponse> => {
    return apiService.request({
      url: Endpoints.getGenders,
      options: {
        method: 'GET',
      },
    });
  };

  fetchJobPositions = (): Promise<any> => {
    return apiService.request({
      url: Endpoints.getJobPositions,
      options: {
        method: 'GET',
      },
    });
  };

  fetchIdentificationTypes = (): Promise<any> => {
    return apiService.request({
      url: Endpoints.getIdentificationTypes,
      options: {
        method: 'GET',
      },
    });
  };

  fetchSeniorities = (): Promise<any> => {
    return apiService.request({
      url: Endpoints.getSeniorities,
      options: {
        method: 'GET',
      },
    });
  };

  fetchContractTypes = (): Promise<any> => {
    return apiService.request({
      url: Endpoints.getContractTypes,
      options: {
        method: 'GET',
      },
    });
  };

  savePerson = (person: DTO.Person): Promise<DTO.PersonResponse> => {
    return apiService.request({
      url: Endpoints.savePerson,
      options: {
        method: 'POST',
        body: JSON.stringify(person),
      },
    });
  };

  editPerson = (person: DTO.Person): Promise<DTO.PersonResponse> => {
    return apiService.request({
      url: `${Endpoints.editPerson}${person.personId}`,
      options: {
        method: 'PUT',
        body: JSON.stringify(person),
      },
    });
  };

  editPersonByUser = (person: DTO.Person): Promise<DTO.PersonResponse> => {
    return apiService.request({
      url: Endpoints.editPersonByUser,
      options: {
        method: 'PUT',
        body: JSON.stringify(person),
      },
    });
  };

  deletePerson = (personId: number): Promise<DTO.PersonResponse> => {
    return apiService.request({
      url: `${Endpoints.deletePerson}${personId}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  getPerson = (personId: number): Promise<DTO.PersonResponse> => {
    return apiService.request({
      url: `${Endpoints.getPerson}${personId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getResumeByPersonId = (
    personId: number,
    clientRole?: string
  ): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getResumeByUserId}/${personId}?clientRole=${
        clientRole || ''
      }`,
      options: {
        method: 'GET',
      },
    });
  };

  getResume = (role: string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getResume}?clientRole=${role}`,
      options: {
        method: 'GET',
      },
    });
  };

  getAllPeopleFilteredNotInProject = (
    projectId: number,
    searchTerm: string
  ): Promise<DTO.PeopleNotInProjectResponse> => {
    return apiService.request({
      url: `${Endpoints.getAllPeopleFilteredNotInProject}`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          projectId,
          searchTerm,
        }),
      },
    });
  };

  getAssignmentDatesRange = (
    personId: number,
    ProjectId: number
  ): Promise<DTO.GetAssignmentDatesRangeResponse> => {
    return apiService.request({
      url: `${Endpoints.getPersonLastAssignment}/${ProjectId}/${personId}`,
      options: {
        method: 'GET',
      },
    });
  };

  activatePerson = (personId: number): Promise<DTO.PersonResponse> => {
    return apiService.request({
      url: `${Endpoints.activatePerson}/${personId}`,
      options: {
        method: 'PATCH',
      },
    });
  };

  inactivatePerson = (personId: number): Promise<DTO.PersonResponse> => {
    return apiService.request({
      url: `${Endpoints.inactivatePerson}/${personId}`,
      options: {
        method: 'PATCH',
      },
    });
  };

  getActivePersonsInACompanyGroup = (
    personName: string
  ): Promise<DTO.PersonResponseReviewer> => {
    return apiService.request({
      url: `${Endpoints.getPeopleInCompanyGroup}?searchTerms=${personName}`,
      options: {
        method: 'GET',
      },
    });
  };

  getAvailableApprovers = (
    projectId: number,
    personName: string
  ): Promise<DTO.PersonResponseReviewer> => {
    return apiService.request({
      url: Endpoints.getAvailableApprovers,
      options: {
        method: 'POST',
        body: JSON.stringify({
          projectId,
          searchTerm: personName,
        }),
      },
    });
  };
}

export const peopleService = new PeopleService();
