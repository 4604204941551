import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class BillableRolesService {
  getBillableRoles = (clientId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getBillableRoles}${clientId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getBillableRolesFiltered = (
    page: number,
    pageSize: number,
    valueId: string,
    filter: {}
  ): Promise<DTO.BillableRolesFiltered> => {
    return apiService.request({
      url: Endpoints.getAllBillableRolesFiltered,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId,
          page,
          pageSize,
          sortColumn: 'Name',
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: filter || {},
        }),
      },
    });
  };

  updateStatus = (
    id: number,
    isActive: boolean
  ): Promise<DTO.BillableRolesResponse> => {
    return apiService.request({
      url: Endpoints.updateBillableRoleStatus,
      options: {
        method: 'PUT',
        body: JSON.stringify({
          id,
          isActive,
        }),
      },
    });
  };

  updateBillableRole = (
    id: number,
    name: string,
    clientId: number
  ): Promise<DTO.BillableRolesResponse> => {
    return apiService.request({
      url: Endpoints.updateBillableRole,
      options: {
        method: 'PUT',
        body: JSON.stringify({
          id,
          name,
          clientId,
        }),
      },
    });
  };

  saveBillableRole = (
    clientId: number,
    newRole: string
  ): Promise<DTO.BillableRolesResponse> => {
    return apiService.request({
      url: Endpoints.saveBillableRole,
      options: {
        method: 'POST',
        body: JSON.stringify({
          name: newRole,
          clientId,
        }),
      },
    });
  };
}

export const billableRolesService = new BillableRolesService();
