import React, { FC, memo, useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { positionsService } from 'shared/services/positions.service';
import { useParams } from 'react-router';
import PositionView from './PositionView';

type Param = {
  positionId: string;
};

const PositionItem: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { positionId } = useParams<Param>();
  const [position, setPosition] = useState<DTO.Position | undefined>();
  const getPositionsById = positionsService.getPositionById;

  useEffect(() => {
    getPositionsById(positionId).then((data: any) => {
      setPosition(data.result);
      setIsLoading(false);
    });
  }, [getPositionsById, positionId]);

  return (
    <>
      {isLoading || position === undefined ? (
        <LoadingOutlined />
      ) : (
        <PositionView position={position} />
      )}
    </>
  );
};

export default memo(PositionItem);
