export interface SkillDataInterface {
  id: number | string;
  skillTypeId: number;
  skillName: string;
  skillLevel: string;
  skillLevelId: number;
  isValidated: boolean;
}

export interface SkillDataInterfaceGrouped {
  skillType: string;
  skills: SkillDataInterface[] | [];
}

export const skillTypeOrder: { [key: string]: string | number } = {
  'humanCapital.skillTypes.professionalSkills': 1,
  'humanCapital.skillTypes.softSkills': 2,
  'humanCapital.skillTypes.languages': 3,
};

export const skillTypes = [
  'humanCapital.skillTypes.professionalSkills',
  'humanCapital.skillTypes.softSkills',
  'humanCapital.skillTypes.languages',
];

export const UPDATE_REASON_MIN_LENGTH = 10;
