import { ActionsConfig } from 'components/ActionsAntTable/helpers';
import moment from 'moment';

export const FILTER_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT = 'MM/DD/YYYY';
export interface FilterOptions {
  id?: string;
  positionName?: string;
  seniorityName?: string;
  projectName?: string;
  allocation?: string;
  positionStartDate?: string;
  roleName?: string;
  status?: string;
}

const positionsActionsConstructor = (deletePosition?: any): ActionsConfig => {
  return {
    deleteConfig: {
      modalTitle: 'positions.list.modal.delete.title',
      deleteMessage: 'positions.list.modal.delete.message',
      deleteHandler: deletePosition,
    },
  };
};

export { positionsActionsConstructor };

export const buildPlaceholder = (
  index: keyof FilterOptions,
  t: Function
): string => {
  const placeholder = `${t('common.search')} `;
  switch (index) {
    case 'id':
      return `${placeholder}${t('positions.id').toUpperCase()}`;
    case 'positionName':
      return `${placeholder}${t('positions.positionName')}`;
    case 'seniorityName':
      return `${placeholder}${t('positions.seniority')}`;
    case 'projectName':
      return `${placeholder}${t('positions.projectName')}`;
    case 'allocation':
      return `${placeholder}${t('positions.allocation')}`;
    case 'positionStartDate':
      return `${placeholder}${t('positions.startDate')}`;
    case 'roleName':
      return `${placeholder}${t('positions.roleName')}`;
    case 'status':
      return `${placeholder}${t('positions.status')}`;

    default:
      return placeholder;
  }
};

type Semaphore = {
  color: string;
  label: string;
};

export const semaphoreColor = (strStartDate: string): Semaphore => {
  const startDate = moment(strStartDate, DATE_FORMAT);
  const today = moment();
  const weeksTwo = moment().add(2, 'weeks');

  const red = '#eb2f96';
  const yellow = '#ffcc00';
  const green = '#329999';

  let semaphore: Semaphore = {
    color: yellow,
    label: 'positions.semaphore.aboutToExpire',
  };

  if (startDate.diff(weeksTwo) > 0) {
    semaphore = {
      color: green,
      label: 'positions.semaphore.onTime',
    };
  }

  if (startDate.diff(today) < 0 || strStartDate.length === 0) {
    semaphore = {
      color: red,
      label: 'positions.semaphore.expired',
    };
  }

  return semaphore;
};

export const INITIAL_PAGE = 1;

export const INITIAL_PAGE_SIZE = 10;
