import styled from 'styled-components';
import { Menu } from 'antd';
import { Theme } from 'theme';

export const MenuStyled = styled(Menu)`
  margin: 0;
  background: ${({ theme }) => theme.colors.sidebar};
  border-right: 0 !important;
  color: #ffffff66;
  overflow: hidden;

  a {
    color: #ffffff66;
  }

  .ant-menu-item-selected a,
  a:hover {
    color: white;
  }

  .ant-menu-title-content {
    line-height: normal;
  }

  li.ant-menu-item.ant-menu-item-selected {
    background-color: ${({ theme }) => theme.colors.modalHeaderBackground};
    border: 0px !important;
    color: white;
  }

  li.ant-menu-item.ant-menu-item-active {
    color: white;
  }
`;

export const MenuContainer = styled.div`
  ul,
  li {
    overflow: visible !important;
    margin-bottom: 0;
  }
  ul {
    margin-bottom: 0;
  }
`;

export const ChildrenContainer = styled.div`
  position: absolute;
  bottom: -94%;
  left: 0;
  min-width: 100%;
  a {
    box-sizing: border-box;
    padding-left: 12px;
  }
  li {
    height: unset !important;
    margin: 0 !important;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }

  .ant-menu-item-selected {
    background-color: ${({ theme }: { theme: Theme }) =>
      theme.colors.lightBlue700} !important;
  }
`;
