import React, { FC, useCallback, useEffect, useState } from 'react';
import ActionsAntTable from 'components/ActionsAntTable';
import { useForm } from 'components/Form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { TooltipStyled } from 'components/Tooltip/styles';
import { IconWrapper } from 'components/AntTable/styles';
import { ColumnsType } from 'antd/lib/table';
import { positionsService } from 'shared/services/positions.service';
import Modal from 'components/Modal';
import { message, notification, Radio, Space, Table, Tooltip } from 'antd';
import {
  fetchPositionData,
  fetchPositionStatus,
} from 'features/Routes/Positions/helpers';
import { PositionsInterface } from 'features/Modules/OpenPositions/Positions/AddPositions/helpers';
import { hasPermission, showNotification } from 'shared/utils/common.utils';
import { PLACEMENT_PATH } from 'features/Routes/helpers';

import { Button } from 'components/Button';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import DatePicker from 'components/DatePicker';
import moment from 'moment';
import Pagination from 'components/Pagination';
import Switch from 'components/Switch';
import { getErrorInfo } from 'shared/utils/error.utils';
import { RadioButtonGroupStyled } from 'features/Modules/Timetracker/Customers/styles';
import {
  DatePickerStyled,
  DivFilter,
  InputStyled,
  PaginationDiv,
  SearchOutlinedStyled,
  TableWrapper,
  EditOutlinedDisable,
} from './style';
import {
  buildPlaceholder,
  FILTER_DATE_FORMAT,
  FilterOptions,
  INITIAL_PAGE,
  INITIAL_PAGE_SIZE,
  positionsActionsConstructor,
  semaphoreColor,
} from './helpers';

interface ModalProps {
  visible: boolean;
  title: string;
  content?: string;
  itemId?: number;
}
interface StatusType {
  key: React.Key;
  reason: string;
}

type Key = string | number;

const MAX_CHARACTERS = 20;

export const FILTER_ICON_ACTIVE = 'active';
const PositionTable: FC = (): JSX.Element => {
  const { t } = useTranslation();

  const initialModalValues: ModalProps = {
    visible: false,
    title: t('positions.modal.disableTitle'),
    content: t('positions.modal.disableMessage'),
  };

  const initialStatusModalValues: ModalProps = {
    visible: false,
    title: t('positions.modalStatus.title'),
  };

  const [modal, setModal] = useState<ModalProps>(initialModalValues);
  const [cancelModal, setCancelModal] = useState(false);
  const [modalStatus, setModalStatus] = useState<ModalProps>(
    initialStatusModalValues
  );
  const [dataStatusModal, setDataStatusModal] = useState<StatusType[]>([]);
  const [selectStatus, setSelectStatus] = useState<StatusType>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [form] = useForm();
  const history = useHistory();
  const [positionsList, setPositionsList] =
    useState<DTO.PositionPaginationTable>({
      page: 1,
      pageSize: 10,
      total: 0,
      data: [],
    });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<FilterOptions>({});
  const [updater, setUpdater] = useState(0);
  const [pagination, setPagination] = useState({
    page: INITIAL_PAGE,
    size: INITIAL_PAGE_SIZE,
  });

  const columnsStatusModal: ColumnsType<StatusType> = [
    {
      title: t('positions.modalStatus.title'),
      dataIndex: 'reason',
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (_selectedRowKeys: React.Key[], selectedRows: StatusType[]) => {
      setSelectedRowKeys(_selectedRowKeys);
      setSelectStatus(selectedRows[0]);
    },
  };

  const cancelHandler = useCallback(() => {
    setModalStatus({ ...modalStatus, visible: false });
    setCancelModal(false);
    setSelectedRowKeys([]);
    setSelectStatus(undefined);
  }, [modalStatus]);

  const updateTable = useCallback(
    async (currentPage: number, currentSize: number) => {
      try {
        setIsLoading(true);
        const result = await fetchPositionData(
          filter,
          currentPage,
          currentSize
        );
        setPositionsList(result);
      } catch (e: any) {
        getErrorInfo(e, t);
      } finally {
        setIsLoading(false);
      }
    },
    [filter, t]
  );

  useEffect(() => {
    (async () => {
      await updateTable(pagination.page, pagination.size);
    })();
  }, [pagination.page, pagination.size, updateTable]);

  useEffect(() => {
    fetchPositionStatus().then((statuses: StatusType[]) => {
      setDataStatusModal(statuses);
    });
  }, []);

  const cancelDisable = () => {
    setModal({ ...modal, visible: false });
  };

  const cancelDisableStatus = () => {
    if (selectStatus === undefined) {
      setModalStatus({ ...modalStatus, visible: false });
    } else {
      setCancelModal(true);
    }
  };

  const handleIsActiveChange = useCallback(
    (status: boolean, id?: number) => {
      if (!status) {
        setModalStatus({ ...modalStatus, visible: true, itemId: id });
      }
    },
    [modalStatus]
  );

  const handlePagination = useCallback((page: number, size: number) => {
    setPagination((prevState) => {
      return { ...prevState, page, size };
    });
  }, []);

  const modalStatusClick = () => {
    setModalStatus({ ...modalStatus, visible: false });

    if (selectStatus?.key !== undefined && modalStatus.itemId !== undefined) {
      positionsService
        .reasonDisabled(modalStatus.itemId, selectStatus.key)
        .then(() => {
          setUpdater(updater + 1);
          setIsLoading(false);
          showNotification(t, {
            type: 'success',
            description: t('common.editSuccessful'),
          });
        })
        .catch(async (error) => {
          getErrorInfo(error, t);
          setIsLoading(false);
        })
        .finally(async () => {
          await updateTable(pagination.page, pagination.size);
          setSelectedRowKeys([]);
          setSelectStatus(undefined);
        });
    }
  };

  const handleDisable = () => {
    positionsService
      .deletePosition(modal.itemId)
      .then(() => {
        setUpdater(updater + 1);
        setModal({ ...modal, visible: false });
        notification.success({
          message: t('positions.successfullyDisabled'),
        });
      })
      .catch(async () => {
        await message.error(t('positions.modal.disableError'));
        setModal({ ...modal, visible: false });
      });
  };
  const handleReset = useCallback(
    (clearFilters: () => void, index: keyof FilterOptions) => {
      clearFilters();
      setFilter((prevFilter) => {
        const newFilter = { ...prevFilter };
        delete newFilter[index];
        return newFilter;
      });
    },
    [setFilter]
  );

  const handleSearch = useCallback(
    (
      selectedKeys: string,
      confirm: (param?: any) => void,
      dataIndex: keyof FilterOptions
    ) => {
      setFilter((prevFilter) => {
        const newFilter = { ...prevFilter };
        newFilter[dataIndex] = selectedKeys;
        return newFilter;
      });
      setPagination((prevState) => {
        return { ...prevState, page: 1 };
      });
      confirm();
    },
    [setFilter]
  );

  const getColumnStatusSearchProps = (dataIndex: keyof FilterOptions): any => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => {
        const selectedKey = selectedKeys[0] ? selectedKeys[0].toString() : '';
        return (
          <DivFilter>
            <RadioButtonGroupStyled>
              <Radio.Group
                onChange={(event) => {
                  setSelectedKeys([event.target.value]);
                  confirm({ closeDropdown: false });
                }}
                value={selectedKey}
                buttonStyle="solid"
              >
                <Radio value="true">{t(`common.statusOpen`)}</Radio>
                <Radio value="false">{t(`common.close`)}</Radio>
              </Radio.Group>
            </RadioButtonGroupStyled>
            <Space>
              <Button
                onClick={() => {
                  handleSearch(selectedKey, confirm, dataIndex);
                  confirm({ closeDropdown: true });
                }}
                icon={<SearchOutlinedStyled />}
                type="primary"
              >
                {t('common.search')}
              </Button>
              <Button
                type="link"
                onClick={() => {
                  if (clearFilters) handleReset(clearFilters, dataIndex);
                  confirm({ closeDropdown: true });
                }}
              >
                {t('common.reset')}
              </Button>
            </Space>
          </DivFilter>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlinedStyled filtered={filtered ? FILTER_ICON_ACTIVE : ''} />
      ),
    };
  };

  const getColumnSearchProps = useCallback(
    (dataIndex: keyof FilterOptions): any => {
      return {
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: FilterDropdownProps) => {
          const selectedKey = selectedKeys[0] ? selectedKeys[0].toString() : '';
          return (
            <DivFilter>
              <InputStyled
                placeholder={buildPlaceholder(dataIndex, t)}
                value={selectedKey}
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                  confirm({ closeDropdown: false });
                }}
                onPressEnter={() => {
                  handleSearch(selectedKey, confirm, dataIndex);
                  confirm();
                }}
              />
              <Space>
                <Button
                  onClick={() => {
                    handleSearch(selectedKey, confirm, dataIndex);
                    confirm({ closeDropdown: true });
                  }}
                  icon={<SearchOutlinedStyled />}
                  type="primary"
                >
                  {t('common.search')}
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    if (clearFilters) handleReset(clearFilters, dataIndex);
                    confirm({ closeDropdown: true });
                  }}
                >
                  {t('common.reset')}
                </Button>
              </Space>
            </DivFilter>
          );
        },
        filterIcon: (filtered: boolean) => (
          <SearchOutlinedStyled
            filtered={
              filtered || filter[dataIndex] === '' ? FILTER_ICON_ACTIVE : ''
            }
          />
        ),
        onFilter: (value: string, record: any) => {
          return record[dataIndex];
        },
        render: (text: string) => {
          return t(text);
        },
      };
    },
    [filter, handleReset, handleSearch, t]
  );
  const getColumnDateSearchProps = useCallback(
    (dataIndex: keyof FilterOptions): any => {
      return {
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: FilterDropdownProps) => {
          const selectedKey = selectedKeys[0] ? selectedKeys[0].toString() : '';

          return (
            <DivFilter>
              <DatePickerStyled>
                <DatePicker
                  placeholder={buildPlaceholder(dataIndex, t)}
                  onChange={(_, dateString: string) => {
                    if (dateString === '' && clearFilters) {
                      handleReset(clearFilters, dataIndex);
                      setSelectedKeys([]);
                      confirm({ closeDropdown: false });
                    } else {
                      const formatedDate =
                        moment(dateString).format(FILTER_DATE_FORMAT);

                      setSelectedKeys([formatedDate]);
                      confirm({ closeDropdown: false });
                    }
                  }}
                  value={selectedKey ? moment(selectedKey) : null}
                  allowClear
                />
              </DatePickerStyled>
              <Space>
                <Button
                  onClick={() => {
                    handleSearch(selectedKey, confirm, dataIndex);
                    confirm({ closeDropdown: true });
                  }}
                  icon={<SearchOutlinedStyled />}
                  type="primary"
                >
                  {t('common.search')}
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    if (clearFilters) handleReset(clearFilters, dataIndex);
                    confirm({ closeDropdown: true });
                  }}
                >
                  {t('common.reset')}
                </Button>
              </Space>
            </DivFilter>
          );
        },
        filterIcon: (filtered: boolean) => (
          <SearchOutlinedStyled
            filtered={
              filtered || filter[dataIndex] === '' ? FILTER_ICON_ACTIVE : ''
            }
          />
        ),
      };
    },
    [filter, handleReset, handleSearch, t]
  );

  const positionColumns: ColumnsType<DTO.PositionTable> = [
    {
      key: 'id',
      title: t('positions.id'),
      className: 'uppercase',
      dataIndex: 'id',
      ...getColumnSearchProps('id'),
    },
    {
      key: 'positionName',
      title: t('positions.positionName'),
      dataIndex: 'positionName',
      ...getColumnSearchProps('positionName'),
    },
    {
      key: 'roleName',
      title: t('positions.roleName'),
      dataIndex: 'roleName',
      ...getColumnSearchProps('roleName'),
    },
    {
      key: 'seniorityName',
      title: t('positions.seniority'),
      dataIndex: 'seniorityName',
      ...getColumnSearchProps('seniorityName'),
    },
    {
      key: 'projectName',
      title: t('positions.projectName'),
      dataIndex: 'projectName',
      ...getColumnSearchProps('projectName'),
    },
    {
      key: 'allocation',
      title: t('positions.allocation'),
      dataIndex: 'allocation',
      ...getColumnSearchProps('allocation'),
      render: (countries: string[]) => {
        const strCountries = countries
          .map((c) => {
            return t(c);
          })
          .join(' , ');

        const result =
          String(strCountries).length > MAX_CHARACTERS ? (
            <Tooltip title={strCountries}>{`${String(strCountries).slice(
              0,
              MAX_CHARACTERS
            )}...`}</Tooltip>
          ) : (
            strCountries
          );

        return <p> {result} </p>;
      },
    },
    {
      key: 'startDate',
      title: t('positions.startDate'),
      dataIndex: 'startDate',
      ...getColumnDateSearchProps('positionStartDate'),
      render: (strStartDate: string, record) => {
        const semaphore = semaphoreColor(strStartDate);
        return (
          <Space>
            <svg
              width="8"
              height="8"
              viewBox="0 0 6 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="3"
                cy="3"
                r="3"
                fill={record.status ? semaphore.color : 'gray'}
              />
            </svg>
            <Tooltip title={record.status ? t(semaphore.label) : ''}>
              {strStartDate}
            </Tooltip>
          </Space>
        );
      },
    },
    {
      key: 'status',
      title: t('common.status'),
      dataIndex: 'status',
      ...getColumnStatusSearchProps('status'),
      render: (stat: string, record) => {
        const active = record.status;
        return (
          <Switch
            checkedChildren={t('common.statusOpen')}
            unCheckedChildren={t('common.statusClose')}
            onChange={(status: boolean) =>
              handleIsActiveChange(status, record.id)
            }
            checked={active}
            disabled={
              !active ||
              !hasPermission(
                'placement.openPositions.openPositions_close',
                PLACEMENT_PATH.POSITIONS
              )
            }
          />
        );
      },
    },
    {
      key: 'actions',
      title: t('common.actions'),
      dataIndex: 'actions',
      fixed: 'right',
      render: (_: any, record) => {
        const pushToView = (routeId: PositionsInterface['id']) => {
          history.push(`/placement/positions/view/${routeId}`);
        };
        const pushToEdit = (routeId: PositionsInterface['id']) => {
          history.push(`/placement/positions/edit/${routeId}`);
        };
        return (
          <>
            {hasPermission(
              'placement.openPositions.openPositions_view',
              PLACEMENT_PATH.POSITIONS
            ) && (
              <IconWrapper>
                <TooltipStyled title={t('positions.actionTitles.viewPosition')}>
                  <EyeOutlined onClick={() => pushToView(record.id)} />
                </TooltipStyled>
              </IconWrapper>
            )}

            {record.status &&
              hasPermission(
                'placement.openPositions.openPositions_edit',
                PLACEMENT_PATH.POSITIONS
              ) && (
                <IconWrapper>
                  <TooltipStyled
                    title={t('positions.actionTitles.editPosition')}
                  >
                    <EditOutlined onClick={() => pushToEdit(record.id)} />
                  </TooltipStyled>
                </IconWrapper>
              )}

            {!record.status &&
              hasPermission(
                'placement.openPositions.openPositions_edit',
                PLACEMENT_PATH.POSITIONS
              ) && (
                <IconWrapper>
                  <TooltipStyled
                    title={t('positions.actionTitles.editPosition')}
                  >
                    <EditOutlinedDisable />
                  </TooltipStyled>
                </IconWrapper>
              )}
          </>
        );
      },
    },
  ];

  const positionActions = positionsActionsConstructor();

  return (
    <>
      <Modal
        open={modal.visible}
        title={modal.title}
        okType="primary"
        okText={t('common.confirm')}
        cancelText={t('common.cancel')}
        centered
        onOk={handleDisable}
        onCancel={cancelDisable}
      >
        <p>{modal.content}</p>
      </Modal>

      <Modal
        open={modalStatus.visible}
        title={`Position: ${
          positionsList?.data.find((p) => {
            return p.id === modalStatus.itemId;
          })?.positionName
        } (${modalStatus.itemId})`}
        okType="primary"
        okText={t('common.save')}
        cancelText={t('common.cancel')}
        centered
        onCancel={cancelDisableStatus}
        onOk={modalStatusClick}
        okButtonProps={{ disabled: selectStatus === undefined }}
      >
        <Table
          scroll={{ y: 400 }}
          pagination={false}
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          columns={columnsStatusModal}
          dataSource={dataStatusModal}
        />
      </Modal>

      {cancelModal && (
        <Modal
          open
          title={t('common.confirm')}
          okType="primary"
          okText={t('common.confirm')}
          cancelText={t('common.cancel')}
          centered
          onOk={cancelHandler}
          onCancel={() => setCancelModal(false)}
        >
          {t('common.cancelConfirmMessage')}
        </Modal>
      )}

      <TableWrapper>
        <ActionsAntTable
          ref={null}
          columns={positionColumns}
          data={positionsList.data}
          actionsConfig={positionActions}
          isLoading={isLoading}
          form={form}
        />
        <PaginationDiv>
          <Pagination
            pageSize={positionsList?.pageSize}
            current={positionsList?.page}
            total={positionsList?.total}
            showSizeChanger
            onChange={handlePagination}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
          />
        </PaginationDiv>
      </TableWrapper>
    </>
  );
};

export default PositionTable;
