import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'components/Button';
import Popover from 'components/Popover';
import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddContent from './AddContent';
import { ButtonWrapperStyled } from './styles';

interface Props {
  setAddData: Function;
  skills: DTO.PositionSkills[];
  skillType: number;
  disabled: boolean;
  skillLevels: DTO.PositionSkillLevel[];
  possibleSkills: DTO.PositionPossibleSkills[];
  skillCategory: DTO.PositionSkillCategory;
}

const AddPopover: FC<Props> = ({
  setAddData,
  skills,
  skillType,
  disabled,
  skillLevels,
  possibleSkills,
  skillCategory,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [openPopover, setOpenPopover] = useState(false);
  const options: Array<{ value: string }> = possibleSkills
    .filter((skill) => skill.skillTypeId === skillType)
    .map((skill) => ({
      value: skill.name,
    }));

  return (
    <Popover
      placement="bottomRight"
      open={openPopover}
      onOpenChange={(isOpen) => setOpenPopover(isOpen)}
      content={
        <AddContent
          skillLevels={skillLevels}
          setAddData={setAddData}
          skills={skills}
          onSaveClick={setOpenPopover}
          skillType={skillType}
          options={options}
          skillCategory={skillCategory}
        />
      }
      trigger="click"
    >
      <ButtonWrapperStyled>
        <Button width="auto" type="link" disabled={disabled}>
          <PlusCircleOutlined />
          {t('humanCapital.skills.addSkill')}
        </Button>
      </ButtonWrapperStyled>
    </Popover>
  );
};

export default memo(AddPopover);
