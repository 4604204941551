import styled from 'styled-components';
import { Rate } from 'antd';

export const RateStyled = styled(Rate)`
  font-size: 16;
`;

export const ButtonPillStars = styled.div`
  margin: 5px;
  background: ${({ theme }) => theme.colors.ligthBlueBackground};
  padding: 8px 25px 8px 12px;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.ligthBlueBackground};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  font-weight: bold;
  height: 80px;
  :hover {
    background: ${({ theme }) => theme.colors.ligthBlueBackground};
    border: 2px solid ${({ theme }) => theme.colors.ligthBlueBackground};
    color: black;
    cursor: pointer;
  }
`;

export const HeaderViewPositionWrapper = styled.section`
  & .head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;

    & .head-description {
      div {
        display: flex;
      }
      & > span {
        margin-right: 10px;
      }
    }

    & h1 {
      margin-bottom: 15px;
      text-transform: uppercase;
    }
  }
`;

const ViewPositionWrapper = styled.section`
  background-color: white;
  -webkit-box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
  padding: 2vh 4vh;
  border-radius: 8px;
  & :is(h1, h2, h3, h4, h5, h6) {
    font-weight: bold;
  }

  & .skill-tag {
    padding: 5px 10px;
    background-color: #deefff;
    width: fit-content;
    border-radius: 5px;
    margin-right: 5px;
    display: inline-block;
  }

  & .position-body {
    display: flex;
    padding: 20px 10px 0 10px;
    justify-content: space-between;
    & section > div {
      margin-bottom: 20px;
    }

    & aside {
      width: fit-content;
      max-width: 150px;

      margin-right: 20px;
      & > div {
        margin-bottom: 20px;
        display: flex;
        & :is(h3, p) {
          margin-left: 10px;
        }
      }
      & h3 {
        margin-bottom: 10px;
      }
    }
  }
`;
export const SectionStyled = styled.section`
  width: 80%;
  p {
    word-break: break-word;
  }
`;

export const Hr = styled.hr`
  color: #f0f0f0;
  border-width: 0.5px;
`;

export const PositionTitle = styled.div`
  color: #252733;
  font-size: 20px !important;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const RightContentWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
`;

export const HeaderDetails = styled.div`
  margin-right: 10px;
`;

export const HeaderText = styled.p`
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin-left: 10px;
  font-weight: bold;
`;

export const PositionItemRightTitle = styled.h2`
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
`;

export const PositionItemSubElementWrapper = styled.div`
  margin-left: 0.5rem;
`;

export const PositionItemTitle = styled.h2`
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  margin-top: 1rem;
`;

export const TextDescription = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
`;
export default ViewPositionWrapper;
