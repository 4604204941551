import React, { FC, Suspense, useCallback, lazy, useEffect } from 'react';
import {
  RouteProps,
  Route,
  Redirect,
  Switch,
  useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { USER_PATHS, ProfileOptions } from 'shared/constants/common.const';
import { showNotification } from 'shared/utils/common.utils';
import { getFromSessionStorage } from 'shared/utils/storage.utils';
import BaseLayout from 'features/Layouts';
import { LayoutConfig } from 'features/Layouts/helpers';
import { getAllLayout, getInitialRoute } from './helpers';
import { APP_PATH, HUMAN_MANAGEMENT_PATH } from '../helpers';
import { Permissions } from '../GuardedRoute/helpers';

const GuardedRoute = lazy(() => import('features/Routes/GuardedRoute'));
const PeopleContainer = lazy(
  () => import('features/Modules/Timetracker/People/people.container')
);
const PhotoPage = lazy(() => import('features/Modules/Timetracker/photo'));

const HumanManagementRoutes: FC<RouteProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const userPaths: Permissions[] = getFromSessionStorage(USER_PATHS);

  const layout: LayoutConfig = getAllLayout(userPaths);

  useEffect(() => {
    if (layout.menuItems.length === 0) {
      showNotification(t, {
        type: 'error',
        description: 'common.notificationUserNotAllow',
      });
      history.push(APP_PATH.HOME);
    }
  }, [layout, history, t]);

  const PeoplePath = useCallback(() => {
    return (
      <GuardedRoute
        pathToCheck={HUMAN_MANAGEMENT_PATH.PEOPLE}
        component={() => <PeopleContainer />}
        option={ProfileOptions.option}
      />
    );
  }, []);

  const PhotoPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={HUMAN_MANAGEMENT_PATH.PHOTO}
        component={() => <PhotoPage />}
        option={ProfileOptions.option}
      />
    ),
    []
  );

  return (
    <BaseLayout config={layout}>
      <Suspense fallback={<Spin />}>
        <Switch>
          <Route
            exact
            path={HUMAN_MANAGEMENT_PATH.ROOT}
            render={useCallback(
              () => (
                <Redirect to={getInitialRoute(userPaths)} />
              ),
              [userPaths]
            )}
          />
          <Route path={HUMAN_MANAGEMENT_PATH.PEOPLE} render={PeoplePath} />

          <Route path={HUMAN_MANAGEMENT_PATH.PHOTO} render={PhotoPath} />
        </Switch>
      </Suspense>
    </BaseLayout>
  );
};

export default HumanManagementRoutes;
