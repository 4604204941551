import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectCurrentCompanyName,
  selectUser,
} from 'store/auth/auth.selectors';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { APP_PATH } from 'features/Routes/helpers';
import { HomeOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { getMatchingPaths, BREADCRUMB_INITIAL_STATE } from './helpers';
import { BreadcrumbItemStyled, BreadcrumbStyled, LinkStyled } from './styles';

// TODO: Make menuItems mandatory when colapsible Menu is implemented
interface Props extends RouteComponentProps {
  menuItems?: any[];
}

const Breadcrumb = ({ history, menuItems }: Props): JSX.Element => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const currentCompanyName = useSelector(selectCurrentCompanyName);
  const [items, setItems] = useState(BREADCRUMB_INITIAL_STATE);

  useEffect(() => {
    const initialItems = getMatchingPaths(history.location.pathname, menuItems);
    setItems(initialItems);
  }, [history, menuItems]);

  useEffect(() => {
    const unregisterListener = history.listen((location) => {
      if (menuItems && menuItems.length > 0) {
        const matchedPaths = getMatchingPaths(location.pathname, menuItems);
        setItems(matchedPaths);
      }
    });

    return unregisterListener;
  }, [history, menuItems]);

  return (
    <BreadcrumbStyled>
      <BreadcrumbItemStyled>
        <HomeOutlined />
        {currentCompanyName}
      </BreadcrumbItemStyled>
      {user && items.length !== 0 ? (
        <BreadcrumbItemStyled>
          <LinkStyled to={APP_PATH.HOME}>{t('common.home')}</LinkStyled>
        </BreadcrumbItemStyled>
      ) : (
        <BreadcrumbStyled.Item>
          <Link to={APP_PATH.HOME}>
            <Typography.Text strong>{t('common.home')}</Typography.Text>
          </Link>
        </BreadcrumbStyled.Item>
      )}
      {items
        .filter((item) => item.name)
        .map((item, index) => (
          <React.Fragment key={item.key}>
            {index === items.length - 1 ? (
              <BreadcrumbStyled.Item key={item.key}>
                <Typography.Text strong>{t(item.name)}</Typography.Text>
              </BreadcrumbStyled.Item>
            ) : (
              <BreadcrumbItemStyled key={item.key}>
                <LinkStyled to={item.path}>{t(item.name)}</LinkStyled>
              </BreadcrumbItemStyled>
            )}
          </React.Fragment>
        ))}
    </BreadcrumbStyled>
  );
};

export default withRouter(Breadcrumb);

Breadcrumb.defaultProps = {
  menuItems: undefined,
};
