import { FormInstance } from 'components/Form';
import { FieldData, FormItemConfig } from 'components/Form/helpers';
import { FormMode } from 'shared/constants/common.const';
import { EditorState } from 'react-draft-wysiwyg';

export interface TabFormConfig {
  formMode: FormMode;
  formInstance: FormInstance;
  formFieldsConfig: FormItemConfig[];
  fieldsData: FieldData[] | undefined;
  spanCol: number;
}

export interface ActionsConfig {
  onFinish: (values: any) => Promise<any> | void;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  onEdit: (e?: any) => Promise<any>;
  pathToRedirect: string;
  pathToRedirectOnCancel?: string;
}

export const EMPLOYEE_CODE_FIELDNAME = 'employeeCode';

export const tabFormConfigConstructor = (
  formMode: FormMode,
  formInstance: FormInstance,
  formFieldsConfig: FormItemConfig[],
  fieldsData: FieldData[] | undefined,
  spanCol: number
): TabFormConfig => {
  return {
    formMode,
    formInstance,
    formFieldsConfig,
    fieldsData,
    spanCol,
  };
};

export const actionsConfigConstructor = (
  onFinish: (values: DTO.PositionDraft<EditorState>) => Promise<any> | void,
  onEdit: (e: any) => Promise<any>,
  pathToRedirect: string,
  pathToRedirectOnCancel?: string
): ActionsConfig => {
  return {
    onFinish,
    onEdit,
    pathToRedirect,
    pathToRedirectOnCancel,
  };
};
