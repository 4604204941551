import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class ProfileService {
  getAllSections = (): Promise<any> => {
    return apiService.request({
      url: Endpoints.getAllSections,
      options: {
        method: 'GET',
      },
    });
  };

  getProfileById = (profileId: string | number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getProfileById}?profileId=${profileId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getProfiles = (
    page: number,
    pageSize: number,
    searchTerm: string,
    filter: {}
  ): Promise<any> => {
    return apiService.request({
      url: Endpoints.getAllProfilesFilter,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: '',
          page,
          pageSize,
          searchTerm,
          sortColumn: 'Name',
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: filter || {},
        }),
      },
    });
  };

  addProfile = (profile: any): Promise<any> => {
    return apiService.request({
      url: Endpoints.addProfile,
      options: {
        method: 'POST',
        body: JSON.stringify(profile),
      },
    });
  };

  updateProfile = (profile: any): Promise<any> => {
    return apiService.request({
      url: Endpoints.editProfile,
      options: {
        method: 'PUT',
        body: JSON.stringify(profile),
      },
    });
  };

  updateProfileIsActive = (
    profileId: string,
    isActive: boolean
  ): Promise<any> => {
    return apiService.request({
      url: `${
        isActive ? Endpoints.activeProfile : Endpoints.inactiveProfile
      }?profileId=${profileId}`,
      options: {
        method: 'POST',
      },
    });
  };

  deleteProfile = (profileId: string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.deleteProfile}/${profileId}`,
      options: {
        method: 'DELETE',
      },
    });
  };
}

export const profileService = new ProfileService();
