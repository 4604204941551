import { Reducer } from 'redux';

import {
  AuthState,
  SET_USER,
  AuthActionTypes,
  SetUserAction,
} from './auth.types';

const initialState: AuthState = {
  user: null,
};

const authReducer: Reducer<AuthState, AuthActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_USER: {
      const user = (action as SetUserAction).payload;

      return {
        ...state,
        user,
      };
    }

    default:
      return state;
  }
};

export default authReducer;
