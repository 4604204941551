import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Form from 'components/Form';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'components/ConfirmationModal';
import { FormModes } from 'shared/constants/common.const';
import { Button } from 'components/Button';
import { Col, Row } from 'antd';
import FormInput from 'components/Form/FormInput';
import {
  FieldData,
  FormItemConfig,
  FormItemType,
} from 'components/Form/helpers';
import { APP_PATH } from 'features/Routes/helpers';
import { MAX_LENGTH, MIN_LENGTH } from 'components/Form/FormInput/helpers';
import { ButtonType } from 'antd/es/button';
import {
  ActionsConfig,
  EMPLOYEE_CODE_FIELDNAME,
  TabFormConfig,
} from './helpers';
import {
  ButtonWrapper,
  FormWrapper,
  RequiredMessage,
  SpanStyled,
} from './styles';

interface Props {
  tabFormConfig: TabFormConfig;
  actionsConfig: ActionsConfig;
  automaticMode?: boolean;
  isSaved?: boolean;
  noEmploymentData?: boolean;
  requiredFields?: string[];
  showRequiredFieldText?: boolean;
  saveButtonLabel?: string;
  disableCallback?: (value: boolean) => void;
  confirmationMessage?: string;
  defaultModify?: boolean;
  askForConfirmationOnCancel?: boolean;
  onFieldsChange?: (changedFields: FieldData[], allFields: FieldData[]) => void;
  disableSaveButton?: boolean;
  viewingTypeButton?: ButtonType;
  viewingLabelButton?: string;
}

const TabForm: FC<Props> = ({
  tabFormConfig,
  actionsConfig,
  automaticMode,
  isSaved,
  noEmploymentData,
  requiredFields,
  showRequiredFieldText,
  saveButtonLabel = 'common.save',
  disableCallback,
  defaultModify = false,
  confirmationMessage = 'timetracker.people.confirmationMessage',
  askForConfirmationOnCancel: showModalOnCancel,
  onFieldsChange,
  disableSaveButton,
  viewingTypeButton,
  viewingLabelButton = 'common.exit',
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { formMode, formInstance, formFieldsConfig, fieldsData, spanCol } =
    tabFormConfig;
  const { onSubmit, onFinish, onEdit, pathToRedirect, pathToRedirectOnCancel } =
    actionsConfig;
  const [fieldsModified, setFieldsModified] = useState(defaultModify);
  const [loadingSave, setLoadingSave] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (isSaved === true) {
      setLoadingSave(false);
    }
  }, [isSaved]);

  const formFieldsFiltered = useCallback(() => {
    let hideEmployeeCode = false;

    if (automaticMode === true) {
      if (
        (formMode === FormModes.created && !isSaved) ||
        (formMode === FormModes.editing && noEmploymentData)
      ) {
        hideEmployeeCode = true;
      }
    }

    if (hideEmployeeCode) {
      return formFieldsConfig.filter(
        (field) => field.name !== EMPLOYEE_CODE_FIELDNAME
      );
    }

    return formFieldsConfig;
  }, [automaticMode, formFieldsConfig, formMode, isSaved, noEmploymentData]);

  const onFormValuesChange = useCallback(() => {
    setFieldsModified(true);
  }, []);

  const onExit = useCallback(() => {
    history.push(pathToRedirect);
  }, [history, pathToRedirect]);

  const onChangeDisabledCallback = useCallback(
    (value: boolean) => {
      if (disableCallback) {
        disableCallback(value);
      }
    },
    [disableCallback]
  );

  const disable = useCallback(() => {
    if (disableSaveButton) return true;

    const employmentTab =
      formFieldsConfig.filter((field) => field.name === EMPLOYEE_CODE_FIELDNAME)
        .length > 0;

    let emptyRequiredFields = false;
    if (requiredFields) {
      for (let index = 0; index < requiredFields.length; index += 1) {
        emptyRequiredFields = [null, undefined].includes(
          formInstance.getFieldValue(requiredFields[index])
        );
        if (emptyRequiredFields) {
          break;
        }
      }
    }
    if (emptyRequiredFields) {
      onChangeDisabledCallback(true);
      return true;
    }

    let isValidMaxLength = true;
    let isValidMinLength = true;
    const hasEditor = formFieldsConfig.filter(
      (item) => item.type === FormItemType.editor
    );
    if (hasEditor.length > 0) {
      hasEditor.forEach((editor) => {
        const length = editor.maxLength || MAX_LENGTH;
        const minLength = editor.minLength || MIN_LENGTH;
        const text: string =
          formInstance
            .getFieldValue(editor.name)
            ?.getCurrentContent()
            ?.getPlainText() || '';
        if (length < text.length) {
          isValidMaxLength = false;
        }
        if (minLength > text.length) {
          isValidMinLength = false;
        }
      });
    }
    const editorValidation = hasEditor.find(
      (field) =>
        !formInstance
          .getFieldValue(field.name)
          ?.getCurrentContent()
          ?.getPlainText()
    );
    if (
      formMode === FormModes.creating ||
      (formMode === FormModes.created && employmentTab)
    ) {
      const value =
        (hasEditor.length > 0 ? editorValidation !== undefined : false) ||
        !isValidMaxLength ||
        !isValidMinLength ||
        !formInstance.isFieldsTouched(requiredFields, true) ||
        !!formInstance.getFieldsError().filter(({ errors }) => errors.length)
          .length;
      onChangeDisabledCallback(value);
      return value;
    }

    if (fieldsModified || formMode === FormModes.edit) {
      const value =
        !isValidMaxLength ||
        !isValidMinLength ||
        (hasEditor.length > 0 ? editorValidation !== undefined : false) ||
        !!formInstance.getFieldsError().filter(({ errors }) => errors.length)
          .length;
      onChangeDisabledCallback(value);
      return value;
    }

    onChangeDisabledCallback(true);
    return true;
  }, [
    disableSaveButton,
    fieldsModified,
    formFieldsConfig,
    formInstance,
    formMode,
    onChangeDisabledCallback,
    requiredFields,
  ]);

  const getPrimaryButton = (): JSX.Element => {
    switch (formMode) {
      case FormModes.viewing:
        return (
          <Button type={viewingTypeButton} onClick={onExit}>
            {t(viewingLabelButton)}
          </Button>
        );

      case FormModes.editing:
      case FormModes.created:
        return (
          <ConfirmationModal
            buttonType="primary"
            message="timetracker.people.editConfirmationMessage"
            buttonText="common.save"
            showModal={fieldsModified}
            onConfirmAsync={() => {
              setFieldsModified(false);
              const promise = onEdit();
              promise.then(() => setFieldsModified(false));
              return promise;
            }}
            disabled={disable()}
          />
        );
      default:
        return (
          <Button
            type="primary"
            htmlType="submit"
            disabled={disable()}
            loading={loadingSave}
          >
            {t(saveButtonLabel)}
          </Button>
        );
    }
  };

  function isManageAccountField(field: FormItemConfig['name']): boolean {
    const availableFields: FormItemConfig['name'][] = [
      'phoneNumber',
      'stateId',
      'cityId',
      'zipCode',
      'address',
      'personalEmailAddress',
      'personalPhoneNumber',
      'genderId',
      'placeOfIssuance',
    ];

    return (
      !window.location.pathname.includes(APP_PATH.MANAGE_ACCOUNT) ||
      availableFields.includes(field)
    );
  }

  const onFinishHandler = (values: any) => {
    setFieldsModified(false);
    setLoadingSave(true);
    const promise: Promise<any> | void = onFinish(values);
    if (promise) {
      promise.finally(() => {
        setSubmitting(false);
        setLoadingSave(false);
      });
    }
  };

  const onSubmitHandler: React.FormEventHandler<HTMLFormElement> = (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }
    onSubmit?.(e);
    setSubmitting(true);
  };

  return (
    <>
      <Form
        form={formInstance}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 23 }}
        onSubmitCapture={onSubmitHandler}
        onFinish={onFinishHandler}
        autoComplete="off"
        fields={fieldsData}
        onFieldsChange={onFieldsChange}
        onValuesChange={onFormValuesChange}
      >
        {formMode !== FormModes.viewing && showRequiredFieldText && (
          <RequiredMessage>
            <SpanStyled>* </SpanStyled>
            {t('common.fieldRequired')}
          </RequiredMessage>
        )}
        <FormWrapper>
          <Row>
            {formFieldsFiltered().map((field: FormItemConfig) => (
              <Col key={field.name} span={field.isSection ? 24 : spanCol}>
                <FormInput
                  formItemConfig={{
                    ...field,
                    disabled:
                      formMode === FormModes.viewing ||
                      field.disabled ||
                      (automaticMode === true &&
                        field.name === EMPLOYEE_CODE_FIELDNAME) ||
                      !isManageAccountField(field.name),

                    placeholder:
                      formMode === FormModes.viewing ? '' : field.placeholder,

                    onChange: field.onChange,
                  }}
                />
              </Col>
            ))}
          </Row>
        </FormWrapper>
        <Form.Item shouldUpdate>
          {() => (
            <ButtonWrapper>
              {formMode !== FormModes.viewing && !fieldsModified && (
                <Link to={pathToRedirectOnCancel || pathToRedirect}>
                  <Button type="default">{t('common.cancel')}</Button>
                </Link>
              )}
              {formMode !== FormModes.viewing && fieldsModified && (
                <ConfirmationModal
                  path={pathToRedirectOnCancel ?? pathToRedirect}
                  message={confirmationMessage}
                  showModal={showModalOnCancel ?? fieldsModified}
                />
              )}
              {getPrimaryButton()}
            </ButtonWrapper>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default memo(TabForm);

TabForm.defaultProps = {
  automaticMode: true,
  showRequiredFieldText: true,
  isSaved: false,
  noEmploymentData: false,
  requiredFields: [],
  saveButtonLabel: 'common.save',
  disableCallback: undefined,
  defaultModify: false,
  confirmationMessage: 'timetracker.people.confirmationMessage',
  askForConfirmationOnCancel: undefined,
  onFieldsChange: undefined,
  disableSaveButton: false,
  viewingTypeButton: 'primary',
  viewingLabelButton: 'common.exit',
};
