import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class ProjectRolesService {
  getProjectRoles = (): Promise<DTO.ProjectRoles> => {
    return apiService.request({
      url: Endpoints.getProjectRoles,
      options: {
        method: 'GET',
      },
    });
  };
}

export const projectRolesService = new ProjectRolesService();
