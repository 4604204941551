import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import AppSettingsService from 'shared/services/appsettings.service';
import { MsalProvider } from '@azure/msal-react';
import { msalApplication } from 'shared/services/auth-config.service';

import App from './App';
import reportWebVitals from './reportWebVitals';

// The strict react mode conflicts with the antd layout library.
const container = document.getElementById('root');
if (!container) throw Error('Root element not found!');

// load configuration from json config files
const appSettings = new AppSettingsService();
const appSettingsPromise = appSettings.loadResources();

Promise.all([appSettingsPromise]).then(() => {
  const msalPromise = msalApplication();
  Promise.all([msalPromise]).then((msalInstanceResolved) => {
    const msalInstance = msalInstanceResolved[0];
    ReactDOM.render(
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>,
      container
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  });
});
