import React, { FC, memo, useState, useCallback } from 'react';
import Modal from 'components/Modal';
import { Button } from 'components/Button';
import { useTranslation } from 'react-i18next';
import cookies from 'shared/images/cookies.svg';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from 'shared/utils/storage.utils';
import {
  COOKIES_ACCEPTANCE,
  COOKIES_POLICY_PAGE,
} from 'shared/constants/common.const';
import { DivWrapper, DivContainer } from './styles';

const CookieNotice: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const cookiesAcceptance = !!getFromLocalStorage(COOKIES_ACCEPTANCE);
  const [showModal, setShowModal] = useState(!cookiesAcceptance);

  const handleAcceptedCookies = useCallback(() => {
    setShowModal(false);
    setToLocalStorage(COOKIES_ACCEPTANCE, 'accepted');
  }, []);

  const handleRedirection = useCallback(() => {
    window.open(COOKIES_POLICY_PAGE);
  }, []);

  return (
    <Modal
      title={t('common.cookiesPolicy')}
      open={showModal}
      okText={t('common.accept')}
      closable={false}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={handleAcceptedCookies}
    >
      <DivContainer>
        <img src={cookies} alt="cookies" />
        <DivWrapper>
          {t('common.cookiesPolicyMessage')}
          <Button type="link" onClick={handleRedirection}>
            {t('common.readCookiePolicy')}
          </Button>
        </DivWrapper>
      </DivContainer>
    </Modal>
  );
};

export default memo(CookieNotice);
