export const FIRST_ELEMENT = 0;
export const SESSION_USER = 'sessionUser';
export const USER_PATHS = 'userPaths';
export const CURRENT_LOCALE = 'currentLocale';
export const COMPANY_ID = 'companyId';
export const COMPANY_NAME = 'companyName';
export const ALLOW_EXTERNAL_REPORTING = 'ExternalReport';
export const LANGUAGE_ID = 'languageId';
export const COOKIES_ACCEPTANCE = 'cookiesAcceptance';
export const COOKIES_POLICY_PAGE =
  'https://arroyoconsulting.net/privacy-policy';

export const EN_LOCALE_ID = 1;
export const ES_LOCALE_ID = 2;
export const SHORT_WAIT_TIME = 100; // milliseconds
export const WAIT_TIME = 500; // milliseconds
export const LONG_WAIT_TIME = 2000; // milliseconds
export const SECRET = '****   ';

export const DEFAULT_LOCALE: DTO.Locale = {
  id: EN_LOCALE_ID,
  name: 'English',
  flagUri: '../flag_en.png',
  abbr: 'en',
  uiCulture: 'en-US',
};

export type Status =
  | 'approved'
  | 'notApproved'
  | 'pending'
  | 'sent'
  | 'rejected'
  | 'amending';

export type ActiveStatus = 'active' | 'inactive' | 'pending';
export type ProjectType = 'billable' | 'notBillable';
export type UserRol =
  | 'Admin'
  | 'AdminCompany'
  | 'Leader'
  | 'Collaborator'
  | 'TimeTracker';

export interface UserRolType {
  roleId: string;
  roleName: string;
  level?: number;
  isActive?: boolean;
  isGlobal?: boolean;
  companyId?: string | number;
  companyName?: string;
}
export type FormMode = 'Creating' | 'Created' | 'Editing' | 'Viewing' | 'Edit';

export const enum ModalMode {
  closed = '',
  add = 'add',
  view = 'view',
  edit = 'edit',
}

export enum FormModes {
  creating = 'Creating',
  created = 'Created',
  editing = 'Editing',
  edit = 'Edit',
  viewing = 'Viewing',
}

export enum ProfileOptions {
  section = 'Section',
  option = 'Option',
  permission = 'Permission',
}

export enum Settings {
  validPersonAge = 'ValidPersonAge',
  maxDailyWorkHours = 'MaxDailyWorkHours',
  totalWeekWorkHours = 'TotalWeekWorkHours',
}

export const TIME_MEASURE = 'hrs';

export enum Statuses {
  accepted = 'accepted',
  amending = 'amending',
  pending = 'pending',
  sent = 'sent',
  canceled = 'canceled',
  voided = 'voided',
}

export enum StatusesId {
  accepted = 1,
  amending = 2,
  pending = 3,
  sent = 4,
  canceled = 5,
  voided = 6,
}

export enum OS {
  android = 'Android',
  ios = 'iOS',
}
