class AppSettingsService {
  private config: DTO.GlobalConfig = {};

  loadResources = async () => {
    let response: Response;
    if (process.env.NODE_ENV === 'development') {
      response = await fetch('/configs/config.dev.json');
    } else {
      response = await fetch('/configs/config.json');
    }
    if (response.ok) {
      window.APP_CONFIG = (await response.json()) as DTO.GlobalConfig;
    }
  };

  GetWebApiBaseUri = (): string => {
    return String(window.APP_CONFIG.API_BASE_URI);
  };

  GetMsalClientId = (): string => {
    return String(window.APP_CONFIG.MSAL_CLIENT_ID);
  };

  GetMsalClientScope = (): string => {
    return String(window.APP_CONFIG.MSAL_CLIENT_SCOPE);
  };

  GetMsalTenantAuthorityUri = (): string => {
    return String(window.APP_CONFIG.MSAL_TENANT_AUTHORITY_URI);
  };

  GetMsalCacheLocation = (): string => {
    return String(window.APP_CONFIG.MSAL_CACHE_LOCATION);
  };

  GetMsalStoreAuthInCookie = (): boolean => {
    const stringValue = String(window.APP_CONFIG.MSAL_AUTH_STATE_IN_COOKIE);
    if (stringValue.toLowerCase() === 'true') {
      return true;
    }
    if (stringValue.toLowerCase() === 'false') {
      return false;
    }
    throw new Error(
      'MSAL_AUTH_STATE_IN_COOKIE setting is not a valid boolean.'
    );
  };

  GetLoginRedirectUri = (): string => {
    return String(window.APP_CONFIG.MSAL_LOGIN_REDIRECT_URI);
  };
}

export default AppSettingsService;
