import {
  AddSkillBody,
  SkillDataInterface,
} from 'features/Modules/Timetracker/People/Person/Skills/helpers';
import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class SkillService {
  getById = (userId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getSkillsById}/${userId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getByUserid = () =>
    apiService.request({
      url: `/${Endpoints.getSkillsByUserId}`,
      options: {
        method: 'GET',
      },
    });

  searchSkills = (skillTypeId: number): Promise<DTO.SkillOptionsResponse> => {
    return apiService.request({
      url: `${Endpoints.getSkillsByType}?skillTypeId=${skillTypeId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getByType = (skillType: string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getSkillsByType}?skilltype=${skillType}`,
      options: {
        method: 'GET',
      },
    });
  };

  addSkills = (skills: SkillDataInterface[], userId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.addSkills}?personId=${userId}`,
      options: {
        method: 'POST',
        body: JSON.stringify(skills),
      },
    });
  };

  addSkillsByUserId = (skills: AddSkillBody): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.addSkillsByUserId}`,
      options: {
        method: 'POST',
        body: JSON.stringify(skills),
      },
    });
  };

  getSkillTypes = (): Promise<DTO.SkillTypeResponse> => {
    return apiService.request({
      url: `${Endpoints.getSkillTypes}`,
      options: {
        method: 'GET',
      },
    });
  };

  getSkillsLevel = (skillTypeId: number): Promise<DTO.SkillLevelResponse> => {
    return apiService.request({
      url: `${Endpoints.getSkillsLevel}?skillTypeId=${skillTypeId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getAllSkillsLevel = (): Promise<DTO.SkillLevelResponse> => {
    return apiService.request({
      url: `${Endpoints.getSkillsLevel}`,
      options: {
        method: 'GET',
      },
    });
  };
}

export const skillService = new SkillService();
