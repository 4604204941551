import {
  formatDayString,
  formatMonthString,
} from 'components/Timetracker/TimeEntrySingleWeekRow/helpers';
import _ from 'lodash';

export interface NoticeListProps {
  style?: React.CSSProperties;
  data?: DTO.Notification[];
  onClick?: (item: DTO.Notification) => void;
  onClear?: () => void;
  emptyText?: string;
  clearText?: string;
  viewMoreText?: string;
  onViewMore?: (e: any) => void;
  onCancelNotification?: (item: number) => void;
}

export interface NoticeIconData {
  avatar?: string | React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
  datetime?: React.ReactNode;
  extra?: React.ReactNode;
  style?: React.CSSProperties;
  key?: string | number;
  read?: boolean;
}

export interface NoticeIconProps {
  count?: number;
  bell?: React.ReactNode;
  className?: string;
  loading?: boolean;
  onClear?: () => void;
  onItemClick?: (item: DTO.Notification) => void;
  onTabChange?: (tabTile: string) => void;
  style?: React.CSSProperties;
  onPopupVisibleChange?: (visible: boolean) => void;
  popupVisible?: boolean;
  clearText?: string;
  viewMoreText?: string;
  clearClose?: boolean;
  onCancelNotification?: (id: number) => void;
  emptyImage?: string;
  list: DTO.Notification[];
}

export interface NotificationOrderDate {
  date: string;
  notifications: DTO.Notification[];
}

export function getFirstLetters(str: string): string {
  const firstLetters = str
    .split(' ')
    .map((word) => word[0])
    .join('');

  return firstLetters;
}

export const getNotificationDate = (notiArray: DTO.Notification[]) => {
  const correctDateArray = notiArray.map((notification) => {
    return {
      id: notification.id,
      authorFullName: notification.authorFullName,
      description: notification.description,
      statusId: notification.statusId,
      statusName: notification.statusName,
      personId: notification.personId,
      url: notification.url,
      createdAt: notification.createdAt.slice(0, 10),
    };
  });
  return correctDateArray;
};

export function newOrderDateArray(
  data: DTO.Notification[]
): NotificationOrderDate[] {
  return _.chain(getNotificationDate(data))
    .groupBy('createdAt')
    .sortBy((item) => data.indexOf(item[0]))
    .map((value) => ({ date: value[0].createdAt, notifications: value }))
    .value();
}

export function formatDatetoTitle(date: Date, t: Function) {
  const dayString = t(formatDayString(date.getDay()));
  const month = `${t(formatMonthString(date.getMonth())).slice(0, 3)}.`;
  const dayNumber = date.getDate();
  const year = date.getFullYear().toString();
  return `${dayString} ${month} ${dayNumber} ${year}`;
}
