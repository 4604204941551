import { TagItem } from 'components/TagCollection/helper';

export const enum Constants {
  EXPERTISE_MAX_FIELD_LENGTH = 50,
}

export const enum InterviewerFields {
  INTERVIEWER = 'personId',
  EXPERTISE = 'expertise',
}

export interface IInterviewer {
  [InterviewerFields.INTERVIEWER]: number;
  [InterviewerFields.EXPERTISE]: TagItem[];
}
