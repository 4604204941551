import { CloseCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: fit-content;
  min-height: calc(0.8rem + 30px);
  position: relative;
`;

export const CloseIcon = styled(CloseCircleFilled)`
  cursor: pointer;
  position: absolute;
  right: -0.5rem;
  top: -0.75rem;
  svg {
    width: 22px !important;
    height: 22px !important;
    color: #f9faff;
    background: radial-gradient(black, transparent);
    border-radius: 50%;
    &:hover {
      filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.16));
    }
  }
`;

export const AddWrapper = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue};
  align-items: center;
  display: flex;
  gap: 3px;
  margin-bottom: auto;
`;

export const ListWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  flex: 1;
`;

export const MainWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
